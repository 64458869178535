import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Flip, Flipr } from '../../../components/Flip';
import {
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from '../../../service/casino';

import './css/race.css';
import CardDrawer from '../../../components/CardDrawer/CardDrawer';
import { ASSETS_URL } from '../../../utils/constants';
import { useMemo } from 'react';
import Iframe from 'react-iframe';
import IframeComponent from '../../../components/Iframe';
import CasinoVideoRules from '../../../components/CasinoVideoRules';
import LastResults from '../../../components/LastResults';
import Popover from '../../../components/Popover';
import { setGameKey } from '../../../redux/features/gameSlice';
import { getService } from '../../../utils/commonFunctions';
import { useLocation } from 'react-router-dom';
export default function Race2020() {
  const location = useLocation();
  const odds_ = useSelector((state) => state.gameData.race20Odds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const authDetails = useSelector((state) => state.account.authDetails);
  const toast = useToast();
  const dispatch = useDispatch();
  const [plExposure, setPlExposure] = useState(null);
  const gameName = 'RACE20';
  const tigerCard = [
    {
      id: 'Card 1',
      img: require('../../../assets/img/casino/KSS.png'),
    },
    {
      id: 'Card 1',
      img: require('../../../assets/img/casino/KHH.png'),
    },
    {
      id: 'Card 1',
      img: require('../../../assets/img/casino/KCC.png'),
    },
    {
      id: 'Card 1',
      img: require('../../../assets/img/casino/KDD.png'),
    },
  ];
  const CustomButton = ({ text }) => (
    <Button size='sm' my='2' className='casinoButton'>
      {isLocked ? <FaLock /> : text}
    </Button>
  );
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  useEffect(() => {
    dispatch(setGameKey('race20'));
  }, [dispatch]);

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id, isBack = true) => {
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = isBack
      ? selectedEventForBet.back1
      : selectedEventForBet.lay1;
    payload.selectedSize = isBack
      ? selectedEventForBet.backSize1
      : selectedEventForBet.laySize1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = isBack ? 'back' : 'lay';

    dispatch({
      type: 'gameData/setSelectedEventForBet',
      payload,
    });
  };
  useEffect(() => {
    if (odds?.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);
  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id,
      authDetails?.uid
    );
    let transformedData = [];

    if (res && res?.code == 200) {
      res.response.map((item) =>
        item.casinoPLExposure.map((item) => transformedData.push(item))
      );
      setPlExposure(transformedData);
    }
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data = plExposure?.find(
        (exposure) => exposure.selectionName == selectionName
      );
      return data ? data : null;
    }
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);
  return (
    <Box
      display='flex'
      flexDirection={{ base: 'column', sm: 'column', lg: 'row' }}
    >
      <Box mt='1' mr='1' w={{ base: '100%', sm: '100%', lg: '70%' }}>
        <Box
          h='60vh'
          overflow='hidden'
          mb='2'
          pos='relative'
          className='casino-video'
        >
          {odds?.cards && (
            <CardDrawer
              custom={
                odds?.cards.length > 0 && (
                  <Box>
                    <Flex alignItems='center'>
                      <Image w='25px' src={ASSETS_URL + 'spade.png'} />
                      {odds?.cards[0]?.length >= 1 && (
                        <div className='scroll-container non-scroll'>
                          {odds?.cards[0]?.map((item, index) => (
                            <div className='scroll-inner-wrapper'>
                              <span>
                                {item && (
                                  <Image
                                    style={{
                                      width: '20px ',
                                      height: '25px ',
                                    }}
                                    mx={'2px !important'}
                                    src={ASSETS_URL + item + '.png'}
                                  />
                                )}
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                    </Flex>
                    <Flex alignItems='center'>
                      <Image w='25px' src={ASSETS_URL + 'heart.png'} />
                      {odds?.cards[1]?.length >= 1 && (
                        <div className='scroll-container'>
                          {odds?.cards[1]?.map((item, index) => (
                            <div className='scroll-inner-wrapper'>
                              <span>
                                {item && (
                                  <Image
                                    style={{
                                      width: '20px ',
                                      height: '25px ',
                                    }}
                                    mx={'2px !important'}
                                    src={ASSETS_URL + item + '.png'}
                                  />
                                )}
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                    </Flex>
                    <Flex alignItems='center'>
                      <Image w='25px' src={ASSETS_URL + 'club.png'} />
                      {odds?.cards[2]?.length >= 1 && (
                        <div className='scroll-container'>
                          {odds?.cards[2]?.map((item, index) => (
                            <div className='scroll-inner-wrapper'>
                              <span>
                                {item && (
                                  <Image
                                    style={{
                                      width: '20px ',
                                      height: '25px ',
                                    }}
                                    mx={'2px !important'}
                                    src={ASSETS_URL + item + '.png'}
                                  />
                                )}
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                    </Flex>
                    <Flex alignItems='center'>
                      <Image w='25px' src={ASSETS_URL + 'diamond.png'} />
                      {odds?.cards[3]?.length >= 1 && (
                        <div className='scroll-container'>
                          {odds?.cards[3]?.map((item, index) => (
                            <div className='scroll-inner-wrapper'>
                              <span>
                                {item && (
                                  <Image
                                    style={{
                                      width: '20px ',
                                      height: '25px ',
                                    }}
                                    mx={'2px !important'}
                                    src={ASSETS_URL + item + '.png'}
                                  />
                                )}
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                    </Flex>
                  </Box>
                )
              }
            />
          )}
          <div class='market-title'>RACE 2020</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3036`}
          />
          <Box
            bottom='0'
            p='2'
            pos='absolute'
            right='2'
            display='flex'
            bg='black'
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules image={`${ASSETS_URL}race20 rules.jpg`} />
        </Box>
        <div className='snipcss-EpXpc race-20-20-casino new-casino race'>
          <div className='d-none-small'>
            <div className='total-count-box d-none-big'>
              <span className='cards-count'>Total Cards: 5</span>{' '}
              <span className='points-count'>Total Points: 36</span>
            </div>

            <div className='row row5'>
              <div className='col-6 col-md-3 d-none-big full-mobile'>
                <div className=''>
                  <div className='casino-nation-name'></div>
                  <div className='casino-bl-box'>
                    <div class='casino-bl-box-item'>Back</div>
                    <div class='casino-bl-box-item'>Lay</div>
                  </div>
                  <div className='casino-nation-name d-none'></div>
                </div>
              </div>
              <div className='col-6 col-md-3'>
                <div className=''>
                  <div className='casino-nation-name'>
                    <img src={ASSETS_URL + 'KHH.png'} />
                    <div className='text-right'>
                      <Popover>
                        <div id='min-max-info' className='min-max-box'>
                          <p>Min: 100</p>
                          <p>Max: 100k</p>
                        </div>
                      </Popover>
                    </div>
                  </div>
                  <div className='casino-bl-box'>
                    <div
                      onClick={() => setEvent('K of spade')}
                      className={`back-border casino-bl-box-item ${
                        isLocked ? 'suspended' : ''
                      }`}
                    >
                      <span className='casino-box-odd'>
                        {getData('K of spade').back1}
                      </span>
                    </div>
                    <div
                      onClick={() => setEvent('K of spade', false)}
                      className={`lay-border casino-bl-box-item ${
                        isLocked ? 'suspended' : ''
                      }`}
                    >
                      <span className='casino-box-odd'>
                        {' '}
                        {getData('K of spade').lay1}
                      </span>
                    </div>
                  </div>
                  <div className='casino-nation-name '>
                    {getExposureForEntity('K of spade')?.exposure || 0}
                  </div>
                </div>
              </div>
              <div className='col-6 col-md-3'>
                <div className=''>
                  <div className='casino-nation-name'>
                    <img src={ASSETS_URL + 'KDD.png'} />
                    <div className='text-right'>
                      <Popover>
                        <div id='min-max-info' className='min-max-box'>
                          <p>Min: 100</p>
                          <p>Max: 100k</p>
                        </div>
                      </Popover>
                    </div>
                  </div>
                  <div className='casino-bl-box'>
                    <div
                      onClick={() => setEvent('K of heart')}
                      className={`back-border casino-bl-box-item ${
                        isLocked ? 'suspended' : ''
                      }`}
                    >
                      <span className='casino-box-odd'>
                        {getData('K of heart').back1}
                      </span>
                    </div>
                    <div
                      onClick={() => setEvent('K of heart', false)}
                      className={`lay-border casino-bl-box-item ${
                        isLocked ? 'suspended' : ''
                      }`}
                    >
                      <span className='casino-box-odd'>
                        {getData('K of heart').lay1}
                      </span>
                    </div>
                  </div>
                  <div className='casino-nation-name'>
                    {getExposureForEntity('K of heart')?.exposure || 0}
                  </div>
                </div>
              </div>
              <div className='col-6 col-md-3'>
                <div className=''>
                  <div className='casino-nation-name'>
                    <img src={ASSETS_URL + 'KCC.png'} />
                    <div className='text-right'>
                      <Popover>
                        <div id='min-max-info' className='min-max-box'>
                          <p>Min: 100</p>
                          <p>Max: 100k</p>
                        </div>
                      </Popover>
                    </div>
                  </div>
                  <div className='casino-bl-box'>
                    <div
                      onClick={() => setEvent('K of club')}
                      className={`back-border casino-bl-box-item ${
                        isLocked ? 'suspended' : ''
                      }`}
                    >
                      <span className='casino-box-odd'>
                        {getData('K of club').back1}
                      </span>
                    </div>
                    <div
                      onClick={() => setEvent('K of club', false)}
                      className={`lay-border casino-bl-box-item ${
                        isLocked ? 'suspended' : ''
                      }`}
                    >
                      <span className='casino-box-odd'>
                        {getData('K of club').lay1}
                      </span>
                    </div>
                  </div>
                  <div className='casino-nation-name'>
                    {getExposureForEntity('K of club')?.exposure || 0}
                  </div>
                </div>
              </div>
              <div className='col-6 col-md-3'>
                <div className=''>
                  <div className='casino-nation-name'>
                    <img src={ASSETS_URL + 'KSS.png'} />
                    <div className='text-right'>
                      <Popover>
                        <div id='min-max-info' className='min-max-box'>
                          <p>Min: 100</p>
                          <p>Max: 100k</p>
                        </div>
                      </Popover>
                    </div>
                  </div>
                  <div className='casino-bl-box'>
                    <div
                      onClick={() => setEvent('K of diamond')}
                      className={`back-border casino-bl-box-item ${
                        isLocked ? 'suspended' : ''
                      }`}
                    >
                      <span className='casino-box-odd'>
                        {getData('K of diamond').back1}
                      </span>
                    </div>
                    <div
                      onClick={() => setEvent('K of diamond', false)}
                      className={`lay-border casino-bl-box-item ${
                        isLocked ? 'suspended' : ''
                      }`}
                    >
                      <span className='casino-box-odd'>
                        {getData('K of diamond').lay1}
                      </span>
                    </div>
                  </div>
                  <div className='casino-nation-name'>
                    {getExposureForEntity('K of diamond')?.exposure || 0}
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-2'>
              <div className='col-4'>
                <div className='card-content'>
                  <div className='casino-yn'>
                    <div style={{ width: '50%' }}></div>
                    <div className='casino-bl-box cc-bb-nn'>
                      <div className='casino-bl-box-item'>
                        <b>No</b>
                      </div>
                      <div className='casino-bl-box-item'>
                        <b>Yes</b>
                      </div>
                    </div>
                  </div>
                  <div className='casino-odds-box casino-yn'>
                    <div className='casino-odds-box-bhav'>
                      <b>Total points</b>
                      <div className='text-right'>
                        <Popover>
                          <div id='min-max-info' className='min-max-box'>
                            <p>Range :</p>
                            <p>100-</p>
                            <p>100k</p>
                          </div>
                        </Popover>
                      </div>
                    </div>
                    <div
                      onClick={() => setEvent('Total point', false)}
                      className={`lay-border casino-bl-box-item ${
                        isLocked ? 'suspended' : ''
                      }`}
                    >
                      <span className='casino-box-odd'>
                        {getData('Total point').lay1}
                      </span>
                      <span> {getData('Total point').laySize1}</span>
                    </div>
                    <div
                      onClick={() => setEvent('Total point')}
                      className={`back-border casino-bl-box-item ${
                        isLocked ? 'suspended' : ''
                      }`}
                    >
                      <span className='casino-box-odd'>
                        {getData('Total point').back1}
                      </span>
                      <span> {getData('Total point').backSize1}</span>
                    </div>
                  </div>

                  <div className='casino-yn rf-minheight'>
                    <div></div>
                    <div className='casino-bl-box cc-bb-nn'>
                      <div className='casino-nation-name '>
                        {getExposureForEntity('Total point')?.exposure || 0}
                      </div>
                    </div>
                  </div>

                  <div className='casino-yn'>
                    <div style={{ width: '50%' }}></div>
                    <div className='casino-bl-box cc-bb-nn'>
                      <div className='casino-bl-box-item'>
                        <b>No</b>
                      </div>
                      <div className='casino-bl-box-item'>
                        <b>Yes</b>
                      </div>
                    </div>
                  </div>
                  <div className='casino-odds-box casino-yn'>
                    <div className='casino-odds-box-bhav'>
                      <b>Total cards</b>
                      <div className='text-right'>
                        <Popover>
                          <div id='min-max-info' className='min-max-box'>
                            <p>Range :</p>
                            <p>100-</p>
                            <p>100k</p>
                          </div>
                        </Popover>
                      </div>
                    </div>
                    <div
                      onClick={() => setEvent('Total card', false)}
                      className={`lay-border casino-bl-box-item ${
                        isLocked ? 'suspended' : ''
                      }`}
                    >
                      <span className='casino-box-odd'>
                        {getData('Total card').lay1}
                      </span>
                      <span> {getData('Total card').laySize1}</span>
                    </div>
                    <div
                      onClick={() => setEvent('Total card')}
                      className={`back-border casino-bl-box-item ${
                        isLocked ? 'suspended' : ''
                      }`}
                    >
                      <span className='casino-box-odd'>
                        {getData('Total card').back1}
                      </span>
                      <span> {getData('Total card').backSize1}</span>
                    </div>
                  </div>

                  <div className='casino-yn rf-minheight'>
                    <div></div>
                    <div className='casino-bl-box cc-bb-nn'>
                      <div className='casino-nation-name '>
                        {getExposureForEntity('Total card')?.exposure || 0}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-8 win-with'>
                <div className='card-content'>
                  <div className='row row5' style={{ marginTop: 7 }}>
                    <div className='col-4'>
                      <div className=''>
                        <div className='casino-nation-name'>
                          <b>Win with 5</b>
                        </div>
                        <div className='casino-bl-box'>
                          <div
                            onClick={() => setEvent('Win with 5')}
                            className={`back-border casino-bl-box-item ${
                              isLocked ? 'suspended' : ''
                            }`}
                          >
                            <span className='casino-box-odd'>
                              {getData('Win with 5').back1}
                            </span>
                            <span className=''>{}</span>
                          </div>
                          {getExposureForEntity('Win with 5')?.exposure || 0}
                        </div>
                      </div>
                      <div className=''>
                        <div className='casino-nation-name'>
                          <b>Win with 15</b>
                        </div>
                        <div className='casino-bl-box'>
                          <div
                            onClick={() => setEvent('Win with 15')}
                            className={`back-border casino-bl-box-item ${
                              isLocked ? 'suspended' : ''
                            }`}
                          >
                            <span className='casino-box-odd'>
                              {getData('Win with 15').back1}
                            </span>
                            {/*   <span className="d-none">
                            {getData("Win with 15").backSize1}
                          </span> */}
                          </div>
                          {getExposureForEntity('Win with 15')?.exposure || 0}
                        </div>
                      </div>
                    </div>
                    <div className='col-4'>
                      <div className=''>
                        <div className='casino-nation-name'>
                          <b>Win with 6</b>
                        </div>
                        <div className='casino-bl-box'>
                          <div
                            onClick={() => setEvent('Win with 6')}
                            className={`back-border casino-bl-box-item ${
                              isLocked ? 'suspended' : ''
                            }`}
                          >
                            <span className='casino-box-odd'>
                              {getData('Win with 6').back1}
                            </span>
                            {/*   <span className="d-none">
                            {getData("Win with 6").backSize1}
                          </span> */}
                          </div>
                          {getExposureForEntity('Win with 6')?.exposure || 0}
                        </div>
                      </div>
                      <div className=''>
                        <div className='casino-nation-name'>
                          <b>Win with 16</b>
                        </div>
                        <div className='casino-bl-box'>
                          <div
                            onClick={() => setEvent('Win with 16')}
                            className={`back-border casino-bl-box-item ${
                              isLocked ? 'suspended' : ''
                            }`}
                          >
                            <span className='casino-box-odd'>
                              {getData('Win with 16').back1}
                            </span>
                            {/*  <span className="d-none">
                            {getData("Win with 16").backSize1}
                          </span> */}
                          </div>
                          {getExposureForEntity('Win with 16')?.exposure || 0}
                        </div>
                      </div>
                    </div>
                    <div className='col-4'>
                      <div className=''>
                        <div className='casino-nation-name'>
                          <b>Win with 7</b>
                        </div>
                        <div className='casino-bl-box'>
                          <div
                            onClick={() => setEvent('Win with 7')}
                            className={`back-border casino-bl-box-item ${
                              isLocked ? 'suspended' : ''
                            }`}
                          >
                            <span className='casino-box-odd'>
                              {getData('Win with 7').back1}
                            </span>
                            {/*  <span className="d-none">
                            {getData("Win with 7").backSize1}
                          </span> */}
                          </div>
                          {getExposureForEntity('Win with 7')?.exposure || 0}
                        </div>
                      </div>
                      <div className=''>
                        <div className='casino-nation-name'>
                          <b>Win with 17</b>
                        </div>
                        <div className='casino-bl-box'>
                          <div
                            onClick={() => setEvent('Win with 17')}
                            className={`back-border casino-bl-box-item ${
                              isLocked ? 'suspended' : ''
                            }`}
                          >
                            <span className='casino-box-odd'>
                              {getData('Win with 17').back1}
                            </span>
                            {/*  <span className="d-none">
                            {getData("Win with 17").backSize1}
                          </span> */}
                          </div>
                        </div>
                        {getExposureForEntity('Win with 17')?.exposure || 0}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LastResults eventId={gameName} />
      </Box>
    </Box>
  );
}
