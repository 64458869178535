import { Button } from "@chakra-ui/button";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/popover";
import React from "react";

export default ({ children }) => {
  return (
    <>
      <Popover className="custom-popover">
        <PopoverTrigger>
          <Button className="popover-button">
            {" "}
            <i className={`fas fa-info-circle `}></i>
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody>{children}</PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};
