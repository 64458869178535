import { configureStore } from '@reduxjs/toolkit';
import gameSlice from './features/gameSlice';
import accountSlice from './features/accountSlice';
import bankSlice from './features/bankSlice';
export const saveState = (state) => {
  try {
    const accountState = {
      account: state.account,
    };
    const serializedState = JSON.stringify(accountState);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    console.log(err);
  }
};

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const persistedState = loadState();
export default configureStore({
  reducer: {
    gameData: gameSlice,
    account: accountSlice,
    bank: bankSlice,
  },
  preloadedState: persistedState,
});
