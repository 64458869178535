import React, { useCallback, useEffect, useState } from "react";
import moment from "moment/moment";
import { AXIOS_INSTANCE } from "../../../service";
import { BASE_URL, BASE_URL_BANKING } from "../../../utils/constants";
import {
  Box,
  Button,
  Container,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getSignedUrlAPI } from "../../../service/BankingService";
import "./paymentSnap.css";
export default function PaymentSnap() {
  const transactionId = "";
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [filters, setFilters] = useState([
    {
      name: "All",
      event: null,
      selected: true,
    },
    {
      name: "Deposit",
      event: "DEPOSIT_TO_WALLET",
      selected: false,
    },
    {
      name: "Withdrawal",
      event: "WITHDRAW_FROM_WALLET",
      selected: false,
    },
    {
      name: "Deposit to ID",
      event: "DEPOSIT_TO_ID",
      selected: false,
    },
    {
      name: "Withdrawal from ID",
      event: "WITHDRAW_FROM_ID",
      selected: false,
    },
    {
      name: "Create ID",
      event: "CREATE_ID",
      selected: false,
    },
    {
      name: "Other",
      event: "OTHER",
      selected: false,
    },
  ]);
  const [transactionType, setTransactionType] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [ui, setUi] = useState("");
  const [showImageOrTable, setShowImageOrTable] = useState("");
  const [transactionData, setTransactionData] = useState({});

  const getTransactionDetails = useCallback(async () => {
    filters
      .filter((filter) => filter.selected && filter.event)
      .map((filter) => filter.event);
    const res = await AXIOS_INSTANCE.post(
      `${BASE_URL_BANKING}/admin/user/transactions?pageNumber=${page}&size=${size}`,
      { transactionTypes: transactionType ? [transactionType] : [] }
    );
    console.log("payment", res);
    const content = res?.data?.data?.data?.content || [];
    const sortedContent = content.sort(
      (a, b) => b.transactionId - a.transactionId
    );

    setTotalCount(res?.data?.data?.data?.totalElements);
    setData(sortedContent);
    console.log(sortedContent, "filter");
  }, [filters, page, size, transactionType]);

  const transactionDetailsById = useCallback(
    async (id) => {
      const res = await AXIOS_INSTANCE.get(
        `${BASE_URL_BANKING}/admin/transactions/${id}`
      );
      if (res.status === 200) {
        const resData = res.data.data.userTransaction;
        if (resData.transactionType === "DEPOSIT_TO_WALLET") {
          const resUrl = await getSignedUrlAPI(
            res?.data?.data?.userTransaction.paymentProofs
          );

          if (resUrl.status === 200) {
            const url = resUrl?.data?.data?.url;
            const _temp = {
              ...res?.data?.data?.userTransaction,
              paymentProofs: url,
            };
            setTransactionData(_temp);
            if (transactionId) {
              handleShow(_temp);
            }
          }
        } else {
          if (transactionId) {
            handleShow(res?.data?.data?.userTransaction);
          }
          setTransactionData(res?.data?.data?.userTransaction);
        }
      } else {
        console.error("Non-200 status code:", res.status);
      }
      console.log("transactionById", res);
      console.log("transactionbyIDRsData", res.data.data.userTransaction);
      // setBankData(res?.data?.data?.UserBankDetails)
      // setUpiPaytm(res?.data?.data?.UserUPIDetails?.paytm);
      // setUpiGpay(res?.data?.data?.UserUPIDetails?.gpay)
      // setUpiPhonePe(res?.data?.data?.UserUPIDetails?.phonepe)
    },
    [transactionId]
  );

  useEffect(() => {
    getTransactionDetails();
  }, [getTransactionDetails]);

  const loadFilters = (roles) => {
    let isAdmin;
    if (roles === "Admin") {
      isAdmin = true;
    }

    if (isAdmin) {
      setFilters([
        {
          name: "All",
          event: null,
          selected: true,
        },
        {
          name: "Deposit",
          event: "DEPOSIT_TO_WALLET",
          selected: false,
        },
        {
          name: "Withdrawal",
          event: "WITHDRAW_FROM_WALLET",
          selected: false,
        },
      ]);
    } else {
      setFilters([]);
    }
  };

  useEffect(() => {
    loadFilters("Admin");
  }, []);

  const nextPage = () => {
    if (data.length > 0) {
      setPage(page + 1);
    }
  };
  const prevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };
  const handleShow = useCallback(
    async (ele) => {
      console.log("transactionDataforRow", ele);
      if (!transactionId) {
        //call transaction api with transaction id here
        await transactionDetailsById(ele?.transactionId);
      }

      if (ele.transactionStatus !== "PENDING") {
        //set approve or reject ui
        setUi("NotPending");
        if (ele.transactionType === "DEPOSIT_TO_WALLET") {
          //show image of DEPOSIT_TO_WALLET
          setShowImageOrTable("IMAGE");
        } else {
          //instead of image need to show transaction Id data
          setShowImageOrTable("TABLE");
        }
      } else {
        setUi("Pending");
        if (ele?.transactionType === "DEPOSIT_TO_WALLET") {
          //show DEPOSIT_TO_WALLET UI set payload for Handle approve and reject
          setShowImageOrTable("IMAGE");
        } else {
          //call get api for payment detail by id then show Withdraw wallet then handle aprove reject
          setShowImageOrTable("TABLE");
        }
      }
      onOpen();
    },
    [onOpen, transactionDetailsById]
  );

  const handleApproveReject = async (choose) => {
    let deposit = true;
    if (transactionData.transactionType === "WITHDRAW_FROM_WALLET") {
      deposit = false;
    }
    //here choose value will be "APPROVED" or "REJECTED"
    const payload = {
      actualCoins: transactionData.coins,
      deposit: deposit,
      transactionId: transactionData.transactionId,
      transactionStatus: choose,
      transactionType: transactionData.transactionType,
    };
    console.log("payloadApproveReject", payload);
    const res = await AXIOS_INSTANCE.post(
      `${BASE_URL_BANKING}/admin/user-deposit-withdraw-coins`,
      payload
    );
    try {
      if (res.status == 200) {
        toast({
          title: "Transaction",
          description: `${choose}`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
        getTransactionDetails();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const buttonStyles = (type) => ({
    color: type === transactionType ? "white" : "black",
    backgroundColor: type === transactionType ? "#102A6D" : "transparent",
    border: "1px solid black",
    borderRadius: "10px",
    padding: "5px 10px",
    cursor: "pointer",
    outline: "none",
  });

  return (
    <div className="main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 main-container">
            <div>
              <div className="listing-grid">
                <div className="detail-row">
                  <h2>Payment Snap</h2>
                </div>
                <Flex gap="5px" mt="10px">
                  <button
                    onClick={() => setTransactionType("")}
                    style={buttonStyles("")}
                    variant="outlined"
                  >
                    All
                  </button>
                  <button
                    onClick={() => setTransactionType("DEPOSIT_TO_WALLET")}
                    style={buttonStyles("DEPOSIT_TO_WALLET")}
                    variant="outlined"
                  >
                    Deposit
                  </button>
                  <button
                    onClick={() => setTransactionType("WITHDRAW_FROM_WALLET")}
                    style={buttonStyles("WITHDRAW_FROM_WALLET")}
                    variant="outlined"
                  >
                    Withdraw
                  </button>
                </Flex>
                <div
                  style={{ marginTop: "25px" }}
                  className="table-responsive data-table"
                  id="tbldata"
                >
                  <div
                    id="table-active_bets_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="table-active_bets"
                      className="table table-bordered datatable dataTable no-footer"
                      role="grid"
                      aria-describedby="table-active_bets_info"
                    >
                      <thead>
                        <tr role="row" style={{ backgroundColor: "#C9C9C9" }}>
                          <th>TransactionId</th>
                          <th
                            className="sorting_asc"
                            tabIndex={0}
                            aria-controls="table-active_bets"
                            rowSpan={1}
                            colSpan={1}
                            aria-sort="ascending"
                            aria-label="Event Type: activate to sort column descending"
                            style={{ width: "141px" }}
                          >
                            User Name
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="table-active_bets"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Event Name: activate to sort column ascending"
                            style={{ width: "156px" }}
                          >
                            Coins
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="table-active_bets"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Username: activate to sort column ascending"
                            style={{ width: "133px" }}
                          >
                            Transaction Status
                          </th>

                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="table-active_bets"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Bet Type: activate to sort column ascending"
                            style={{ width: "115px" }}
                          >
                            Transaction Type{" "}
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="table-active_bets"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="User Rate: activate to sort column ascending"
                            style={{ width: "128px" }}
                          >
                            Generated At
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="table-active_bets"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Amount: activate to sort column ascending"
                            style={{ width: "106px" }}
                          >
                            Validated At
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody id="bet_tbody">
                        {data.length == 0 && (
                          <tr
                            className="odd"
                            style={{ backgroundColor: "#FFFFFF" }}
                          >
                            <td
                              valign="top"
                              colSpan={10}
                              className="dataTables_empty"
                            >
                              No data available in table
                            </td>
                          </tr>
                        )}
                        {data.map((item, index) => {
                          return (
                            <tr role="row" className="odd" key={index}>
                              <td style={{ paddingLeft: "38px" }}>
                                {item.transactionId}
                              </td>
                              <td>{item.toUserName}</td>
                              <td>{item.coins}</td>
                              <td style={{ paddingLeft: "25px" }}>
                                {item.transactionStatus}
                              </td>
                              <td>{item.transactionType}</td>
                              <td>{item.requestGenerated.split("T")[0]}</td>
                              <td>
                                {item?.requestValidated?.length > 0
                                  ? item.requestValidated.split("T")[0]
                                  : "Not Validated Yet"}
                              </td>
                              <td>
                                <button
                                  style={{
                                    backgroundColor: "#102A6D",
                                    color: "white",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                    borderRadius: "5px",
                                    marginLeft: "10px",
                                  }}
                                  onClick={() => handleShow(item)}
                                >
                                  View details
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="clientListTable_paginate"
                >
                  <a
                    className="previous  button btn btn-diamond"
                    style={{ backgroundColor: "#102A6D" }}
                    aria-controls="clientListTable"
                    data-dt-idx={0}
                    tabIndex={-1}
                    id="clientListTable_previous"
                    onClick={() => prevPage()}
                  >
                    Previous
                  </a>
                  {/* <span>
                      {
                        <a
                        className="current button btn btn-diamond"
                        aria-controls="clientListTable"
                        data-dt-idx={1}
                        tabIndex={0}
                      >
                        1
                      </a>}
                    </span> */}
                  <a
                    className="next  button btn btn-diamond"
                    style={{ backgroundColor: "#102A6D" }}
                    aria-controls="clientListTable"
                    data-dt-idx={2}
                    tabIndex={-1}
                    id="clientListTable_next"
                    onClick={() => nextPage()}
                  >
                    Next
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage Transaction</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {ui === "NotPending" && showImageOrTable === "IMAGE" ? (
              <>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ marginBottom: "8px" }}
                >
                  {/* Show Image or Table based on transaction type */}
                  <img
                    src={transactionData.paymentProofs}
                    alt="Your Image"
                    className="mr-3"
                    style={{
                      objectFit: "cover",
                      width: "300px",
                      height: "200px",
                    }}
                  />
                </div>
                <table>
                  <tr>
                    <td>Transaction ID : </td>
                    <td> {transactionData?.transactionId}</td>
                  </tr>
                  <tr>
                    <td>User Name : </td>
                    <td>{transactionData?.toUserName}</td>
                  </tr>
                  <tr>
                    <td> Transaction Status :</td>
                    <td>
                      <b>{transactionData?.transactionStatus}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Payment Type : </td>

                    <td>{transactionData?.paymentType}</td>
                  </tr>
                  <tr>
                    <td>Transaction Type :</td>
                    <td> {transactionData?.transactionType}</td>
                  </tr>
                  {transactionData?.transactionStatus === "APPROVED" ? (
                    <>
                      <tr>
                        {" "}
                        <td>Requested Coins</td>
                        <td>{transactionData?.coins}</td>
                      </tr>
                      <tr>
                        {" "}
                        <td>Approved Coins</td>
                        <td>{transactionData?.approvedCoins}</td>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <td>Coins</td>
                      <td>
                        <Input
                          type="number"
                          onChange={(e) =>
                            setTransactionData({
                              ...transactionData,
                              coins: e.target.value,
                            })
                          }
                          value={transactionData?.coins}
                        />
                      </td>
                    </tr>
                  )}
                </table>
              </>
            ) : ui === "NotPending" && showImageOrTable === "TABLE" ? (
              <table>
                <tr>
                  <td>
                    {" "}
                    <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                      TransactionId:
                    </p>
                  </td>
                  <td> {transactionData.transactionId} </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                      Transaction Status:
                    </p>
                  </td>
                  <td> {transactionData.transactionStatus} </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                      Transaction type:
                    </p>
                  </td>
                  <td> {transactionData.transactionType} </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                      Payment Type:
                    </p>
                  </td>
                  <td> {transactionData.paymentType} </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                      Coins:
                    </p>
                  </td>
                  <td> {transactionData.coins} </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                      Approved Coins:
                    </p>
                  </td>
                  <td> {transactionData.approvedCoins} </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                      Generated At:
                    </p>
                  </td>
                  <td> {transactionData.requestGenerated} </td>
                </tr>
              </table>
            ) : null}

            {ui === "Pending" && showImageOrTable === "IMAGE" && (
              <>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ marginBottom: "15px" }}
                >
                  {/* Show Image or Table based on transaction type */}
                  <img
                    src={transactionData.paymentProofs}
                    alt="payment proof"
                    className="mr-3"
                    style={{
                      objectFit: "cover",
                      width: "300px",
                      height: "200px",
                    }}
                  />
                </div>

                <table>
                  <tr>
                    <td>UTR Number : </td>
                    <td>
                      {" "}
                      {transactionData?.transfer_type ? (
                        <b>{`${transactionData?.transfer_type} : ${transactionData?.utr_number}`}</b>
                      ) : (
                        <b>{transactionData?.utr_number}</b>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Transaction ID : </td>
                    <td> {transactionData?.transactionId}</td>
                  </tr>
                  <tr>
                    <td>User Name : </td>
                    <td>{transactionData?.toUserName}</td>
                  </tr>
                  <tr>
                    <td> Transaction Status :</td>
                    <td>
                      {" "}
                      <b>{transactionData?.transactionStatus}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Payment Type : </td>

                    <td>{transactionData?.paymentType}</td>
                  </tr>
                  <tr>
                    <td>Transaction Type :</td>
                    <td> {transactionData?.transactionType}</td>
                  </tr>
                  <tr>
                    <td>Coins</td>
                    <td>
                      <Input
                        type="number"
                        onChange={(e) =>
                          setTransactionData({
                            ...transactionData,
                            coins: e.target.value,
                          })
                        }
                        value={transactionData?.coins}
                      />
                    </td>
                  </tr>
                </table>

                <Container
                  className="justify-content-center"
                  style={{
                    marginLeft: "4px",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <Flex gap={"10px"} className="justify-content-center">
                    <Box lg={3}>
                      <Button
                        style={{ color: "green" }}
                        onClick={() => handleApproveReject("APPROVED")}
                      >
                        Approve
                      </Button>
                    </Box>

                    <Box lg={3}>
                      <Button
                        onClick={() => handleApproveReject("REJECTED")}
                        style={{ color: "red" }}
                      >
                        Reject
                      </Button>
                    </Box>
                  </Flex>
                </Container>
              </>
            )}

            {ui === "Pending" && showImageOrTable === "TABLE" && (
              <>
                <table>
                  <tr>
                    <td>
                      {" "}
                      <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                        TransactionId:
                      </p>
                    </td>
                    <td> {transactionData.transactionId} </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                        Transaction Status:
                      </p>
                    </td>
                    <td>
                      {" "}
                      <b>{transactionData?.transactionStatus}</b>{" "}
                    </td>
                  </tr>

                  <>
                    {transactionData.paymentType === "BANK_SAVING" ||
                    transactionData.paymentType === "CURRENT_BANK" ? (
                      <tr>
                        <td colSpan={2} className="border-1s p-8">
                          <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                            Withdrawal Details:
                          </p>
                          <table
                            style={{
                              borderCollapse: "collapse",
                              width: "100%",
                            }}
                          >
                            <thead>
                              <tr style={{ backgroundColor: "#C9C9C9" }}>
                                <th className="border-1s p-8">Bank Name</th>
                                <th className="border-1s p-8">IFSC</th>
                                <th className="border-1s p-8">Account Name</th>
                                <th className="border-1s p-8">
                                  Account Number
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="border-1s p-8">
                                  {transactionData.bankDetails?.bankName}
                                </td>
                                <td className="border-1s p-8">
                                  {transactionData.bankDetails?.ifscCode}
                                </td>
                                <td className="border-1s p-8">
                                  {transactionData.bankDetails?.accountName}
                                </td>
                                <td className="border-1s p-8">
                                  {transactionData.bankDetails?.accountNumber}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    ) : transactionData.paymentType === "UPI_BHIM" ||
                      transactionData.paymentType === "UPI_PHONEPE" ||
                      transactionData.paymentType === "UPI_GPAY" ||
                      transactionData.paymentType === "UPI_PAYTM" ? (
                      <tr>
                        <td colSpan={2} className="border-1s p-8">
                          <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                            Withdrawal Details:
                          </p>
                          <table
                            style={{
                              borderCollapse: "collapse",
                              width: "100%",
                            }}
                          >
                            <thead>
                              <tr style={{ backgroundColor: "#C9C9C9" }}>
                                <th className="border-1s p-8">Username</th>
                                <th className="border-1s p-8">Payment ID</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="border-1s p-8">
                                  {transactionData.upiDetails?.userName}
                                </td>
                                <td className="border-1s p-8">
                                  {
                                    transactionData.upiDetails
                                      ?.paymentUpiDetailsId
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    ) : transactionData.paymentType === "USDT" ? (
                      <tr>
                        <td colSpan={2} className="border-1s p-8">
                          <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                            Withdrawal Details:
                          </p>
                          <table
                            style={{
                              borderCollapse: "collapse",
                              width: "100%",
                            }}
                          >
                            <thead>
                              <tr style={{ backgroundColor: "#C9C9C9" }}>
                                <th className="border-1s p-8">Name</th>
                                <th className="border-1s p-8">ID</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="border-1s p-8">
                                  {transactionData.usdtDetails?.userName}
                                </td>
                                <td className="border-1s p-8">
                                  {
                                    transactionData.usdtDetails
                                      ?.paymentUpiDetailsId
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    ) : null}
                  </>

                  <tr>
                    <td>
                      {" "}
                      <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                        Transaction type:
                      </p>
                    </td>
                    <td> {transactionData.transactionType} </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                        Payment Type:
                      </p>
                    </td>
                    <td> {transactionData.paymentType} </td>
                  </tr>

                  <tr>
                    <td>
                      {" "}
                      <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                        Generated At:
                      </p>
                    </td>
                    <td> {transactionData.requestGenerated} </td>
                  </tr>
                  <tr>
                    <td>Coins</td>
                    <td>
                      <Input
                        type="number"
                        onChange={(e) =>
                          setTransactionData({
                            ...transactionData,
                            coins: e.target.value,
                          })
                        }
                        value={transactionData?.coins}
                      />
                    </td>
                  </tr>
                </table>
                <Container>
                  <Flex
                    gap={"10px"}
                    className="justify-content-center mt-3 mb-3"
                  >
                    <Box lg={3}>
                      <Button
                        style={{ color: "green" }}
                        onClick={() => handleApproveReject("APPROVED")}
                      >
                        Approve
                      </Button>
                    </Box>

                    <Box lg={3}>
                      <Button
                        onClick={() => handleApproveReject("REJECTED")}
                        style={{ color: "red" }}
                      >
                        Reject
                      </Button>
                    </Box>
                  </Flex>
                </Container>
              </>
            )}

            {/* Three spans/items in the same line */}

            {/* Additional modal body content goes here */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
