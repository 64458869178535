import { Box, Grid, GridItem, Switch, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomInput from "../../components/UI/CustomInput";
import CustomSelect from "../../components/UI/CustomSelect";
import {
  addNewClientAPI,
  getGlobalSettingsAPI,
  getUserDataAPI,
} from "../../service/UserService";
import { Encrypt, ROLE_MAPPING } from "../../utils/constants";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
export default function AddClient() {
  const [clientData, setClientData] = useState({});
  const [globalSetting, setGlobalSetting] = useState({});
  const [payload, setPayload] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const role = useSelector((state) => state.account.userData.userType);
  const [bankFunctionalityEnabled, setBankFunctionalityEnabled] = useState(globalSetting.canUseBanking);
  const [userCreationEnabled, setUserCreationEnabled] = useState(globalSetting.canCreateUser);

  const setData = (e) => {
    if (e.target.name.endsWith("DownLine")) {
      let currentKey = e.target.name.split("DownLine")[0];
      if (
        e.target.value > clientData.partnershipAndCommission[currentKey] ||
        e.target.value < 0
      ) {
        return;
      }
      setPayload({
        ...payload,
        [e.target.name]: e.target.value,
        [currentKey]:
          clientData.partnershipAndCommission[currentKey] - e.target.value > 0
            ? clientData.partnershipAndCommission[currentKey] - e.target.value
            : 0,
      });
    } else {
      setPayload({ ...payload, [e.target.name]: e.target.value });
    }
  };


  const onBlurValidation = (e) => {
    let numberCheckRegex = /^[0-9]*$/;
    switch (e.target.name) {
      case "username":
        if (e.target.value.length > 20) {
          toast.error("Username cannot be greater than 20 characters");
          setPayload({ ...payload, [e.target.name]: "" });
          return;
        }
        break;
      case "password":
        if (e.target.value.length > 20) {
          toast.error("Password cannot be greater than 20 characters");
          setPayload({ ...payload, password: "" });
          return;
        }
        break;
      case "contact":
        if (
          e.target.value.length > 10 ||
          e.target.value.length < 10 ||
          isNaN(e.target.value) ||
          e.target.value.includes(" ")
        ) {
          toast.error("Phone number should be 10 digits");
          setPayload({
            ...payload,
            [e.target.name]: "",
          });
          return;
        }
        break;
      case "creditReference":
        if (e.target.value.length > 10) {
          toast.error("Credit reference cannot be greater than 20 characters");
          setPayload({
            ...payload,
            [e.target.name]: "",
          });
          return;
        }
        if (
          isNaN(e.target.value) ||
          e.target.value < 0 ||
          !numberCheckRegex.test(e.target.value)
        ) {
          toast.error("Credit reference must be a number");
          setPayload({
            ...payload,
            [e.target.name]: "",
          });
          return;
        }

        break;
      case "exposureLimit":
        if (e.target.value.length > 10) {
          toast.error("Exposure  cannot be greater than 20 characters");
          setPayload({
            ...payload,
            [e.target.name]: "",
          });
          return;
        }
        if (
          isNaN(e.target.value) ||
          e.target.value < 0 ||
          !numberCheckRegex.test(e.target.value)
        ) {
          toast.error("Exposure must be a number");
          setPayload({
            ...payload,
            [e.target.name]: "",
          });
          return;
        }

        break;
      case "city":
        if (e.target.value.length > 20) {
          toast.error("City cannot be greater than 20 characters");
          setPayload({
            ...payload,
            [e.target.name]: "",
          });
          return;
        } else if (e.target.value.includes(" ")) {
          toast.error("City cannot contain spaces");
          setPayload({
            ...payload,
            [e.target.name]: "",
          });
          return;
        } else if (parseInt(e.target.value)) {
          toast.error("City cannot contain numbers");
          setPayload({
            ...payload,
            [e.target.name]: "",
          });
          return;
        }
        break;
      default:
        break;
    }
  };

  const getGlobalSetting = async () => {
    const { response } = await getGlobalSettingsAPI();
    setGlobalSetting(response);

    setBankFunctionalityEnabled(response.canUseBanking);
    setUserCreationEnabled(response.canCreateUser);

    setPayload({
      ...payload,
      ...response,
      bankFunctionalityEnabled: response.canUseBanking,
      userCreationEnabled: response.canCreateUser,
    });
  };

  const getUserInfo = async () => {
    const { response } = await getUserDataAPI();
    if (response.userType == "CLIENT") {
      setIsAdmin(false);
    }
    setClientData(response);
  };

  const handleBankFunctionality = () => {
    if (!globalSetting.canUseBanking) return;
    setBankFunctionalityEnabled((prevState) => {
      const newState = !prevState;
      setPayload((prevPayload) => ({
        ...prevPayload,
        canUseBanking: newState,
      }));
      return newState;
    });
  };

  const handleUserFunctionality = () => {
    if (!globalSetting.canCreateUser) return;
    setUserCreationEnabled((prevState) => {
      const newState = !prevState;
      setPayload((prevPayload) => ({
        ...prevPayload,
        canCreateUser: newState,
      }));
      return newState;
    });
  };

  const handleSubmit = async () => {
    let filteredPayload = payload;

    Object.keys(filteredPayload).forEach((key) => {
      if (key.endsWith("DownLine") || key == "") {
        delete filteredPayload[key];
      }
    });

    if (!filteredPayload.creditReference) {
      toast.error("credit referrence is required");
      return;
    }
    if (!filteredPayload.userType) {
      toast.error("User type is required");
      return;
    }
    if (
      !filteredPayload.exposureLimit &&
      filteredPayload.userType == "CLIENT"
    ) {
      toast.error("Exposure limit is required");
      return;
    }
    if (!filteredPayload.city) {
      toast.error("City is required");
      return;
    }
    if (!filteredPayload.contact) {
      toast.error("Contact is required");
      return;
    }
    if (!filteredPayload.fullName) {
      toast.error("Full name is required");
      return;
    }
    //number check for exposure limit and credit limit
    if (
      isNaN(
        filteredPayload.exposureLimit || filteredPayload.exposureLimit < 0
      ) &&
      filteredPayload.userType == "CLIENT"
    ) {
      toast.error("Exposure limit must be a number");
      return;
    }

    if (
      isNaN(filteredPayload.creditReference) ||
      filteredPayload.creditReference < 0
    ) {
      toast.error("Credit reference must be a number");
      return;
    }

    if (filteredPayload.userType == "CLIENT") {
      filteredPayload.minBetCricket = payload.minBetCricket;
      filteredPayload.minBetFootball = payload.minBetFootball;
      filteredPayload.minBetTennis = payload.minBetTennis;
      filteredPayload.maxBetCricket = payload.maxBetCricket;
      filteredPayload.maxBetFootball = payload.maxBetFootball;
      filteredPayload.maxBetTennis = payload.maxBetTennis;
      filteredPayload.delayCricket = payload.delayCricketOdds;
      filteredPayload.delayFootball = payload.delayFootballOdds;
      filteredPayload.delayTennis = payload.delayTennisOdds;
    }
    if (filteredPayload.password !== filteredPayload.confirmPassword) {
      setPayload({
        ...payload,
        password: "",
        confirmPassword: "",
      });
      toast.error("Password and Confirm Password must be same");
      return;
    }
    // var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z!@#$%^&*]{8,}$/;
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$#!%*?&]{8,}$/;
    if (!regex.test(filteredPayload.password)) {
      setPayload({
        ...payload,
        password: "",
        confirmPassword: "",
      });
      toast.error(
        "Password must be at least 8 characters long and contains 1 alphabet, 1 number and one caps and one small letter"
      );
      return;
    }
    filteredPayload.commissionInCricket =
      filteredPayload.userType === "ADMIN"
        ? 100 - payload.commissionInCricket
        : payload.commissionInCricket;
    filteredPayload.commissionInFootball =
      filteredPayload.userType === "ADMIN"
        ? 100 - payload.commissionInFootball
        : payload.commissionInFootball;
    filteredPayload.commissionInTennis =
      filteredPayload.userType === "ADMIN"
        ? 100 - payload.commissionInTennis
        : payload.commissionInTennis;
    filteredPayload.commissionInCasino =
      filteredPayload.userType === "ADMIN"
        ? 100 - payload.commissionInCasino
        : payload.commissionInCasino;
    filteredPayload.partnershipInCricket =
      filteredPayload.userType === "ADMIN"
        ? 100 - payload.partnershipInCricket
        : payload.partnershipInCricket;
    filteredPayload.partnershipInFootball =
      filteredPayload.userType === "ADMIN"
        ? 100 - payload.partnershipInFootball
        : payload.partnershipInFootball;
    filteredPayload.partnershipInTennis =
      filteredPayload.userType === "ADMIN"
        ? 100 - payload.partnershipInTennis
        : payload.partnershipInTennis;
    delete filteredPayload["confirmPassword"];
    filteredPayload.password = Encrypt(payload.password);
    try {
      const { response, code, error, status } = await addNewClientAPI(
        filteredPayload
      );
      if (code === 200) {
        toast.success(response?.status || "User Added Successfully");
        setPayload({});
        setTimeout(() => {
          window.location.href = "/admin/manage-client/list";
        }, 500);
      } else if (code !== 200 && status) {
        toast.error(
          status || "Something went wrong Please check all you field"
        );
        setPayload({
          ...payload,
          password: "",
          confirmPassword: "",
        });
      } else {
        toast.error(
          error.description || "Something went wrong Please check all you field"
        );
        setPayload({
          ...payload,
          password: "",
          confirmPassword: "",
        });
      }
    } catch (err) {
      setPayload({});
      toast.error(
        err.message || "Something went wrong Please check all you field"
      );
    }
  };

  useEffect(() => {
    getGlobalSetting();
    getUserInfo();
  }, []);

  console.log(payload, "payload");
  console.log(bankFunctionalityEnabled, userCreationEnabled, "payload");
  return (
    <Box p="5" bg="#F9F9F9">
      <Text fontSize="xl">Add Account</Text>
      <Grid mt="5" rowGap={2} columnGap={6} templateColumns={"repeat(2,1fr)"}>
        <GridItem>
          <Box p="5px 10px" className="title_bar">
            Personal Details
          </Box>
          <Grid
            templateColumns={{ base: "repeat(1,1fr)", lg: "repeat(2,1fr)" }}
            templateRows="repeat(3,1fr)"
            columnGap={10}
            rowGap={2}
            p="2"
          >
            <GridItem>
              <CustomInput
                name="username"
                onChange={setData}
                label="Client Name:"
                onBlur={onBlurValidation}
                value={payload.username}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                name="password"
                onChange={setData}
                label="Password:"
                type="password"
                value={payload.password}
                onBlur={onBlurValidation}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                name="confirmPassword"
                onChange={setData}
                label="Confirm Password:"
                type="password"
                value={payload.confirmPassword}
                onBlur={onBlurValidation}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                name="fullName"
                onBlur={onBlurValidation}
                onChange={setData}
                label="Full Name:"
                value={payload.fullName}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                onBlur={onBlurValidation}
                name="city"
                onChange={setData}
                label="City:"
                value={payload.city}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                name="contact"
                type="number"
                onBlur={onBlurValidation}
                onChange={setData}
                label="Phone:"
                value={payload.contact}
              />
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem>
          <Box p="5px 10px" className="title_bar">
            Account Details
          </Box>
          <Grid
            templateColumns={{ base: "repeat(1,1fr)", lg: "repeat(2,1fr)" }}
            templateRows="repeat(3,1fr)"
            columnGap={10}
            rowGap={2}
            p="2"
          >
            <GridItem>
              <CustomSelect
                onChange={setData}
                name="userType"
                label="Account Type:"
                options={ROLE_MAPPING[role]}
                value={payload.userType}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                name="creditReference"
                onChange={setData}
                type="number"
                label="Credit Reference:"
              />
            </GridItem>
            {payload?.userType == "CLIENT" && (
              <GridItem>
                <CustomInput
                  name="exposureLimit"
                  onChange={setData}
                  type="number"
                  label="Exposure Limit:"
                />
              </GridItem>
            )}


            <GridItem>
              <Text>Bank Functionality :</Text>
              <Switch
                value={bankFunctionalityEnabled}
                isChecked={bankFunctionalityEnabled}
                isDisabled={!globalSetting.canUseBanking}
                onChange={handleBankFunctionality} />
            </GridItem>
            <GridItem>
              <Text>User Creation :</Text>
              <Switch
                value={userCreationEnabled}
                isChecked={userCreationEnabled}
                isDisabled={!globalSetting.canCreateUser}
                onChange={handleUserFunctionality} />
            </GridItem>

          </Grid>
        </GridItem>
        <GridItem colSpan={2}>
          <Box p="5px 10px" className="title_bar">
            Commission Setting:
          </Box>
          <Grid
            templateColumns="repeat(4,1fr)"
            templateRows="repeat(4,1fr)"
            rowGap={2}
            columnGap={3}
            p="2"
          >
            <GridItem colStart={2}>
              <Text>Cricket</Text>
            </GridItem>
            <GridItem>
              <Text>Soccer</Text>
            </GridItem>
            <GridItem>
              <Text>Tennis</Text>
            </GridItem>
            <GridItem>
              <Text>Up Line</Text>
            </GridItem>
            <GridItem>
              <CustomInput
                name="commissionInCricketDefault"
                value={
                  clientData?.partnershipAndCommission?.commissionInCricket
                }
                disabled
                onChange={setData}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                name="commissionInFootballDefault"
                disabled
                onChange={setData}
                value={
                  clientData?.partnershipAndCommission?.commissionInFootball
                }
              />
            </GridItem>
            <GridItem>
              <CustomInput
                disabled
                name="commissionInTennisDefault"
                onChange={setData}
                value={clientData?.partnershipAndCommission?.commissionInTennis}
              />
            </GridItem>
            <GridItem>
              <Text>Down Line</Text>
            </GridItem>
            <GridItem>
              <CustomInput
                type="number"
                max={clientData?.partnershipAndCommission?.commissionInCricket}
                name="commissionInCricketDownLine"
                onChange={setData}
                disabled={payload.userType === "CLIENT"}
                value={payload?.commissionInCricketDownLine}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                name="commissionInFootballDownLine"
                onChange={setData}
                disabled={payload.userType === "CLIENT"}
                value={payload?.commissionInFootballDownLine}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                name="commissionInTennisDownLine"
                onChange={setData}
                disabled={payload.userType === "CLIENT"}
                value={payload?.commissionInTennisDownLine}
              />
            </GridItem>
            <GridItem>
              <Text>Our</Text>
            </GridItem>
            <GridItem>
              <CustomInput
                name="commissionInCricket"
                disabled
                onChange={setData}
                value={payload.commissionInCricket}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                disabled
                name="commissionInFootball"
                onChange={setData}
                value={payload.commissionInFootball}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                disabled
                name="commissionInTennis"
                value={payload.commissionInTennis}
              />
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem colSpan={2}>
          <Box p="5px 10px" className="title_bar">
            Partnership Setting:
          </Box>
          <Grid
            templateColumns="repeat(4,1fr)"
            templateRows="repeat(4,1fr)"
            rowGap={2}
            columnGap={3}
            p="2"
          >
            <GridItem colStart={2}>
              <Text>Cricket</Text>
            </GridItem>
            <GridItem>
              <Text>Soccer</Text>
            </GridItem>
            <GridItem>
              <Text>Tennis</Text>
            </GridItem>
            <GridItem>
              <Text>Up Line</Text>
            </GridItem>
            <GridItem>
              <CustomInput
                disabled
                onChange={setData}
                name="partnershipInCricketDefault"
                value={
                  clientData?.partnershipAndCommission?.partnershipInCricket
                }
              />
            </GridItem>
            <GridItem>
              <CustomInput
                disabled
                onChange={setData}
                name="partnershipInFootballDefault"
                value={
                  clientData?.partnershipAndCommission?.partnershipInFootball
                }
              />
            </GridItem>
            <GridItem>
              <CustomInput
                disabled
                onChange={setData}
                name="partnershipInTennisDefault"
                value={
                  clientData?.partnershipAndCommission?.partnershipInTennis
                }
              />
            </GridItem>
            <GridItem>
              <Text>Down Line</Text>
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                name="partnershipInCricketDownLine"
                disabled={payload.userType === "CLIENT"}
                value={payload?.partnershipInCricketDownLine}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                name="partnershipInFootballDownLine"
                disabled={payload.userType === "CLIENT"}
                value={payload?.partnershipInFootballDownLine}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                name="partnershipInTennisDownLine"
                disabled={payload.userType === "CLIENT"}
                value={payload?.partnershipInTennisDownLine}
              />
            </GridItem>
            <GridItem>
              <Text>Our</Text>
            </GridItem>
            <GridItem>
              <CustomInput
                disabled
                onChange={setData}
                value={payload?.partnershipInCricket}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                disabled
                onChange={setData}
                value={payload?.partnershipInFootball}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                disabled
                onChange={setData}
                value={payload?.partnershipInTennis}
              />
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem colSpan={2}>
          <Box p="5px 10px" className="title_bar">
            Bet Limit Setting:
          </Box>
          <Grid
            templateColumns="repeat(4,1fr)"
            templateRows="repeat(4,1fr)"
            rowGap={2}
            columnGap={3}
            p="2"
          >
            <GridItem colStart={2}>
              <Text>Cricket</Text>
            </GridItem>
            <GridItem>
              <Text>Soccer</Text>
            </GridItem>
            <GridItem>
              <Text>Tennis</Text>
            </GridItem>
            <GridItem>
              <Text>Minimum</Text>
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                value={payload.minBetCricket}
                name="minBetCricket"
              />
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                value={payload.minBetFootball}
                name="minBetFootball"
              />
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                value={payload.minBetTennis}
                name="minBetTennis"
              />
            </GridItem>
            <GridItem>
              <Text>Maximum</Text>
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                value={payload.maxBetCricket}
                name="maxBetCricket"
              />
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                value={payload.maxBetFootball}
                name="maxBetFootball"
              />
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                value={payload.maxBetTennis}
                name="maxBetTennis"
              />
            </GridItem>
            <GridItem>
              <Text>Delay</Text>
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                value={payload.delayCricketOdds}
                name="delayCricketOdds"
              />
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                value={payload.delayFootballOdds}
                name="delayFootballOdds"
              />
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                value={payload.delayTennisOdds}
                name="delayTennisOdds"
              />
            </GridItem>
          </Grid>
        </GridItem>

        <GridItem colStart={2}>
          <Box display="flex" mr="3" justifyContent="flex-end">
            <CustomInput
              name="transactionPassword"
              onChange={setData}
              w="100%"
              label="Transaction Password:"
              placeholder="Transaction Password"
              type="password"
            />
          </Box>
        </GridItem>
        <GridItem colStart={2}>
          <button
            onClick={handleSubmit}
            style={{
              float: "right",
            }}
            disabled={
              !payload.transactionPassword ||
              payload.transactionPassword.length < 6 ||
              !parseInt(payload.transactionPassword)
            }
            className="btn"
          >
            <Text fontSize="sm">Create Account</Text>
          </button>
        </GridItem>
      </Grid>
    </Box>
  );
}
