import { Box, Button, Input, Select, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import CustomTable from "../../components/UI/Table";
import { betHistoryAPI, unsettledBetsAPI } from "../../service/AuthService";
import { getCurrentDateTimeLocal } from "../../utils/constants";
import moment from "moment/moment";

export default function CurrentBets() {
  const [from, setFrom] = React.useState(getCurrentDateTimeLocal(true));
  const [to, setTo] = React.useState(getCurrentDateTimeLocal());
  const [sport, setSport] = React.useState("MATCHED");
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [search_, setSearch] = React.useState("");
  const getBetHistory = async () => {
    let payload = {
      betStatus: sport,
      pageNumber: page,
      pageSize: size,
    };
    if (search_.length > 0) {
      payload["search"] = search_;
    }
    const {
      response: { content },
    } = await unsettledBetsAPI(payload);
    let data_ = content.map((item, index) => ({
      ...item,
      bet_type: item.back_rate || item.back_run ? "Back" : "Lay",
      rate: item.back_run ? item.back_run : item.lay_run,
      "p/l": item.profit ? item.profit : item.loss,
    }));
    setData(data_);
  };
  useEffect(() => {
    getBetHistory();
  }, [sport, page, size]);
  const search = (e) => {
    let payload = data;
    let inputValue = e?.target?.value?.trim()?.toLowerCase();
    if (e?.target?.value?.trim()?.length > 0) {
      payload = data.filter(
        (item) =>
          item?.bet_type?.toLowerCase()?.search(inputValue) != -1 ||
          item?.event_name?.toLowerCase()?.search(inputValue) != -1 ||
          item?.runner_name?.toLowerCase()?.search(inputValue) != -1 ||
          item?.userName?.toLowerCase()?.search(inputValue) != -1 ||
          item?.marketName?.toLowerCase()?.search(inputValue) != -1 ||
          String(item?.amount)?.toLowerCase()?.search(inputValue) != -1 ||
          String(item?.back_rate)?.toLowerCase()?.search(inputValue) != -1 ||
          String(item?.lay_rate)?.toLowerCase()?.search(inputValue) != -1 ||
          item?.event_type?.toLowerCase()?.search(inputValue) != -1
      );
      setData(payload);
    } else {
      getBetHistory();
      setSize(10);
    }
  };
  const nextPage = () => {
    if (data.length > 0) {
      setPage(page + 1);
    }
  };
  const prevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };
  return (
    <div className="main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 main-container">
            <div>
              <div className="listing-grid">
                <div className="detail-row">
                  <h2>Current Bets</h2>
                  <div id="form-search">
                    <div className="m-t-20">
                      <div className="row">
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="search-bet_type">Choose Type</label>
                            <select
                              name="search-bet_type"
                              id="search-bet_type"
                              className="form-control"
                              onChange={(e) => setSport(e.target.value)}
                            >
                              <option value="MATCHED">Matched</option>
                              <option value="UNMATCHED">Unmatched</option>
                              <option value="DELETED">Deleted</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <label style={{ width: "100%" }}>&nbsp;</label>
                          <button
                            type="submit"
                            className="btn btn-diamond"
                            id="loaddata"
                            onClick={getBetHistory}
                            style={{backgroundColor:'#102A6D'}}
                          >
                            Load
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive data-table" id="tbldata">
                  <div
                    id="table-active_bets_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      className="dataTables_length"
                      id="table-active_bets_length"
                    >
                      <label>
                        Show{" "}
                        <select
                          name="table-active_bets_length"
                          aria-controls="table-active_bets"
                          className
                          onChange={(e) => setSize(e.target.value)}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>{" "}
                        entries
                      </label>
                    </div>
                    <div
                      id="table-active_bets_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        Search:
                        <input
                          onFocus={() => search_.length > 2 && setSize(50)}
                          /* onClick={() => search_.length > 2 && setSize(50)} */
                          /* onBlur={() => setSize(10)} */
                          type="search"
                          className="form-control"
                          placeholder
                          aria-controls="table-active_bets"
                          onChange={search}
                        />
                      </label>
                    </div>
                    <table
                      id="table-active_bets"
                      className="table table-bordered datatable dataTable no-footer"
                      role="grid"
                      aria-describedby="table-active_bets_info"
                    >
                      <thead>
                        <tr role="row" style={{backgroundColor:'#C9C9C9'}}>
                          <th>S.No.</th>
                          <th
                            className="sorting_asc"
                            tabIndex={0}
                            aria-controls="table-active_bets"
                            rowSpan={1}
                            colSpan={1}
                            aria-sort="ascending"
                            aria-label="Event Type: activate to sort column descending"
                            style={{ width: "141px" }}
                          >
                            Event Type
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="table-active_bets"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Event Name: activate to sort column ascending"
                            style={{ width: "156px" }}
                          >
                            Event Name
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="table-active_bets"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Username: activate to sort column ascending"
                            style={{ width: "133px" }}
                          >
                            Username
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="table-active_bets"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Runner Name: activate to sort column ascending"
                            style={{ width: "175px" }}
                          >
                            Runner Name
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="table-active_bets"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Bet Type: activate to sort column ascending"
                            style={{ width: "115px" }}
                          >
                            Bet Type
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="table-active_bets"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="User Rate: activate to sort column ascending"
                            style={{ width: "128px" }}
                          >
                            User Rate
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="table-active_bets"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Amount: activate to sort column ascending"
                            style={{ width: "106px" }}
                          >
                            Amount
                          </th>

                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="table-active_bets"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Place Date: activate to sort column ascending"
                            style={{ width: "141px" }}
                          >
                            Place Date
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="table-active_bets"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Match Date: activate to sort column ascending"
                            style={{ width: "150px" }}
                          >
                            Match Date
                          </th>
                        </tr>
                      </thead>
                      <tbody id="bet_tbody">
                        {data.length == 0 && (
                          <tr className="odd" style={{backgroundColor:'#FFFFFF'}}>
                            <td
                              valign="top"
                              colSpan={10}
                              className="dataTables_empty"
                            >
                              No data available in table
                            </td>
                          </tr>
                        )}
                        {data.map((item, index) => {
                          return (
                            <tr
                              style={{
                                backgroundColor: `${
                                  item.bet_type === "Back"
                                    ? "white"
                                    : "white"
                                } `,
                              }}
                              role="row"
                              className="odd"
                              key={index}
                            >
                              <td width="60px">{index + 1}</td>
                              <td className="sorting_1">{item.event_type}</td>
                              <td>{item.event_name}</td>
                              <td>{item.userName}</td>
                              <td>{item.runner_name}</td>
                              <td>{item.bet_type}</td>
                              <td>
                                {item.back_run ? item.back_run : item.lay_run}
                              </td>
                              <td>{item.amount}</td>

                              <td>
                                {moment(item.created_date + "Z")
                                  .utc()
                                  .local()
                                  .format("DD/MM/YYYY hh:mm:ss A")}
                              </td>
                              <td>
                                {moment(item?.created_date)
                                  .utc()
                                  .format("DD-MM-YYYY HH:mm:ss")}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {/* <div
                      className="dataTables_info"
                      id="table-active_bets_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing 0 to 0 of 0 entries
                    </div> */}
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="table-active_bets_paginate"
                    >
                      <a
                        className="previous  button btn btn-diamond"
                        aria-controls="table-active_bets"
                        data-dt-idx={0}
                        tabIndex={-1}
                        id="table-active_bets_previous"
                        onClick={prevPage}
                        style={{backgroundColor:'#102A6D'}}
                        href
                      >
                        Previous
                      </a>
                      <span />
                      <a
                        className="next  button btn btn-diamond"
                        aria-controls="table-active_bets"
                        data-dt-idx={1}
                        tabIndex={-1}
                        id="table-active_bets_next"
                        onClick={nextPage}
                        style={{backgroundColor:'#102A6D'}}
                        href
                      >
                        Next
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  /*  return (
    <Box>
      <Box
        p="2"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        fontWeight="bold"
      >
        <Text fontSize="2xl">Bet History</Text>
      </Box>
      <Box mt="5" display="flex" p="2" w="60%">
        <Select
          onChange={(e) => {
            setSport(e.target.value);
          }}
          mx="1"
          value={sport}
        >
          <option value="all">Sports Type</option>
          <option value="CRICKET">Cricket</option>
          <option value="SOCCER">Football</option>
          <option value="TENNIS">Tennis</option>
          <option value="CASINO">Casino</option>
        </Select>
        <Select
          onChange={(e) => {
            setMatched(e.target.value);
          }}
          mx="1"
          value={matched}
        >
          <option value="all">Bet Status</option>
          <option value="MATCHED">Matched</option>
          <option value="DELETED">Deleted</option>
        </Select>
        <Input
          onChange={(e) => {
            setFrom(e.target.value);
          }}
          value={from}
          mx="1"
          type="datetime-local"
        />
        <Input
          onChange={(e) => {
            setTo(e.target.value);
          }}
          value={to}
          mx="1"
          type="datetime-local"
        />
        <button
          style={{
            backgroundColor: "#0079FB",
            color: "white",
            paddingLeft: "15px",
            paddingRight: "15px",
            borderRadius: "5px",
            marginLeft: "10px",
          }}
          onClick={() => getBetHistory()}
        >
          Submit
        </button>
      </Box>
      <CustomTable
        columns={[
          {
            name: "Event Name",
            id: "event_name",
          },
          {
            name: "Nation",
            id: "runner_name",
          },
          {
            name: "Bet Type",
            id: "bet_type",
          },
          {
            name: "User Rate",
            id: "rate",
          },
          {
            name: "Amount",
            id: "amount",
          },
          {
            name: "Profit/Loss",
            id: "p/l",
          },
          {
            name: "Place Date",
            id: "created_date",
          },
          {
            name: "Match Date",
            id: "created_date",
          },
        ]}
        rows={data}
        currentPage_={page}
      />
    </Box>
  ); */
}
