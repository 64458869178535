import React from "react";
import "./index.css";
import { Box, Flex, Image } from "@chakra-ui/react";
import { ASSETS_URL } from "../../utils/constants";
export default function CardDrawer({
  cards,
  labels,
  custom,
  multiLevel = false,
  singleCard,
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div
      class={`casino-video-cards snipcss-BAg74 ${!isOpen ? "hide-cards" : ""}`}
    >
      <div onClick={() => setIsOpen(!isOpen)} class="casino-cards-shuffle">
        <Image
          height="30px"
          src={require("../../assets/img/icons/menu-bar.png")}
        />
      </div>
      {isOpen && (
        <Box
          paddingRight={isOpen ? "20px" : ""}
          class="casino-video-cards-container "
        >
          {custom ? (
            custom
          ) : multiLevel ? (
            cards.map((internalCard, index) => (
              <div className="multilevel-wrapper">
                {labels && (
                  <div className="casino-video-cards-label">
                    {labels[index]}
                  </div>
                )}
                <div className="inner-wrapper">
                  {singleCard ? (
                    <span>
                      <img
                        className="casino-card-img"
                        src={ASSETS_URL + internalCard[0] + ".png"}
                      />
                    </span>
                  ) : (
                    <>
                      {internalCard.map((card) => (
                        <span>
                          <img
                            className="casino-card-img"
                            src={ASSETS_URL + card + ".png"}
                          />
                        </span>
                      ))}
                    </>
                  )}
                </div>
              </div>
            ))
          ) : (
            <Flex>
              {cards.map((card) => (
                <span>
                  <img
                    className="casino-card-img"
                    src={ASSETS_URL + card + ".png"}
                  />
                </span>
              ))}
            </Flex>
          )}
        </Box>
      )}
    </div>
  );
}
