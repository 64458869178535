import { Stomp } from '@stomp/stompjs';
import { JSEncrypt } from 'jsencrypt';
import store from '../redux/store';
export const PROD_IP = process.env.REACT_APP_API_URL;
export const DEV_IP = process.env.REACT_APP_API_URL;
export const IS_PROD = PROD_IP.startsWith('api');
export const ASSETS_URL = 'http://casinoassets.betcricks.com/static/';
var BASE_URL_;
var CRICKET_SERVICE_URL_, CRICKET_SERVICE_URL_SOCKET_;
var SOCCER_AND_TENNIS_SERVICE_URL_, SOCCER_AND_TENNIS_SERVICE_URL_SOCKET_;
var CASINO_SERVICE_URL_;
if (PROD_IP.startsWith('api')) {
  BASE_URL_ = `https://${process.env.REACT_APP_API_URL}/auth/api`;
  CRICKET_SERVICE_URL_ = `https://${process.env.REACT_APP_API_URL}/cricket/api`;
  CRICKET_SERVICE_URL_SOCKET_ = `https://${process.env.REACT_APP_API_URL}/cricket/websocket`;

  SOCCER_AND_TENNIS_SERVICE_URL_ = `https://${process.env.REACT_APP_API_URL}/tas/api`;
  SOCCER_AND_TENNIS_SERVICE_URL_SOCKET_ = `https://${process.env.REACT_APP_API_URL}/tas/websocket`;

  CASINO_SERVICE_URL_ = `https://${process.env.REACT_APP_API_URL}`;
} else {
  BASE_URL_ = `http://${process.env.REACT_APP_API_URL}:8000/api`;
  CRICKET_SERVICE_URL_ = `http://${process.env.REACT_APP_API_URL}:8001/api`;
  SOCCER_AND_TENNIS_SERVICE_URL_ = `http://${process.env.REACT_APP_API_URL}:8002/api`;
  CASINO_SERVICE_URL_ = `http://${process.env.REACT_APP_API_URL}`;
}

export const BASE_URL = BASE_URL_;
export const CRICKET_SERVICE_URL = CRICKET_SERVICE_URL_;
export const CRICKET_SERVICE_URL_SOCKET = CRICKET_SERVICE_URL_SOCKET_;
export const SOCCER_AND_TENNIS_SERVICE_URL = SOCCER_AND_TENNIS_SERVICE_URL_;
export const SOCCER_AND_TENNIS_SERVICE_URL_SOCKET =
  SOCCER_AND_TENNIS_SERVICE_URL_SOCKET_;
export const CASINO_SERVICE_URL = CASINO_SERVICE_URL_;
export const BASE_URL_BANKING = !IS_PROD
  ? `http://${process.env.REACT_APP_API_URL}:8888/api`
  : `https://${process.env.REACT_APP_API_URL}/banking/api`;
export const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQB0ZacT13Ok+3pX0lmuJ9dJ
F6atV7Vwv5gmgpvXiBVYKKM4sfGb2qg395uVcampRgLrM63KxElPkWaa90j10ol6
fnHneE3sGuNIrvUmSvtqgeSD8nS4GXdkDecD+HSB7OCOcr+oxsGZzXJdzN/5QyUd
DGpRTlm8T+yB6wa9sJPPgZJ/C650JF57WF+G3qSngqJZEtGrKLlZYL24AqVEg85d
gLsrawWpZUEGeYqhYsdSeoGqkAR6E8Ybw+YWEYCFyMLZi2Fe5MVwMARWQ5kzpKTx
0ur7DPsghrAem08DIAekAN97F1DS48Vs88T29C2716ylE27ICyiMLDw9DLXekhAD
AgMBAAE=
-----END PUBLIC KEY-----`;
var ENCRYPT = new JSEncrypt();
ENCRYPT.setPublicKey(PUBLIC_KEY);
export const Encrypt = (data) => ENCRYPT.encrypt(data);
export const getToken = () => {
  return store.getState().account.userData.token;
};
export function searchSubstringInArrayObjects(arr, key, substring) {
  const result = [];

  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];
    if (obj[key].includes(substring)) {
      result.push(obj);
    }
  }

  return result;
}
export const getCurrentDateTimeLocal = (
  prev = false,
  date_ = false,
  time = false
) => {
  let date = new Date(); // create a new Date object with the current date and time
  let year = date.getFullYear(); // get the four-digit year (e.g. 2023)
  let month = ('0' + (date.getMonth() + 1)).slice(-2); // get the month as a zero-padded two-digit string (e.g. "03")
  let day = ('0' + date.getDate()).slice(-2); // get the day of the month as a zero-padded two-digit string (e.g. "04")
  let hour = ('0' + date.getHours()).slice(-2); // get the hour as a zero-padded two-digit string (e.g. "09")
  let minute = ('0' + date.getMinutes()).slice(-2); // get the minute as a zero-padded two-digit string (e.g. "05")
  if (prev) {
    if (date_) {
      day = ('0' + (date.getDate() - date_)).slice(-2);
    } else {
      day = ('0' + (date.getDate() - 1)).slice(-2);
    }
  }
  let formattedDate = `${year}-${month}-${day} ${hour}:${minute}`; // concatenate the year, month, and day with hyphens as the separator
  return formattedDate;
};

export const ROLE_MAPPING = {
  SUPER_ADMIN: [
    {
      value: null,
      label: 'Select Account Type',
    },
    {
      value: 'ADMIN',
      label: 'Admin',
    },
  ],
  ADMIN: [
    {
      value: null,
      label: 'Select Account Type',
    },

    {
      value: 'SUPER',
      label: 'Super',
    },
    {
      value: 'MASTER',
      label: 'Master',
    },
    {
      value: 'AGENT',
      label: 'Agent',
    },
    {
      value: 'CLIENT',
      label: 'Client',
    },
  ],
  SUPER: [
    {
      value: null,
      label: 'Select Account Type',
    },
    {
      value: 'MASTER',
      label: 'Master',
    },
    {
      value: 'AGENT',
      label: 'Agent',
    },
    {
      value: 'CLIENT',
      label: 'Client',
    },
  ],
  MASTER: [
    {
      value: null,
      label: 'Select Account Type',
    },
    {
      value: 'AGENT',
      label: 'Agent',
    },
    {
      value: 'CLIENT',
      label: 'Client',
    },
  ],
  AGENT: [
    {
      value: null,
      label: 'Select Account Type',
    },
    {
      value: 'CLIENT',
      label: 'Client',
    },
  ],
};
export const createSocket = (
  id,
  dispatch,
  game = 'dragonTiger20',
  port,
  service
) => {
  let stompURL;
  if (id === 'casino') {
    let _CASINO_SERVICE_URL = !IS_PROD
      ? `${CASINO_SERVICE_URL}:${port}`
      : CASINO_SERVICE_URL;
    stompURL = !IS_PROD
      ? _CASINO_SERVICE_URL.replace('http', 'ws') + `/${service}/websocket`
      : _CASINO_SERVICE_URL.replace('https', 'wss') + `/${service}/websocket`;
  } else {
    stompURL =
      id == 'cricket'
        ? !IS_PROD
          ? CRICKET_SERVICE_URL.replace('http', 'ws').replace(
              '/api',
              '/websocket'
            )
          : CRICKET_SERVICE_URL_SOCKET.replace('https', 'wss')
        : !IS_PROD
        ? SOCCER_AND_TENNIS_SERVICE_URL.replace('http', 'ws').replace(
            '/api',
            '/websocket'
          )
        : SOCCER_AND_TENNIS_SERVICE_URL_SOCKET.replace('https', 'wss');
  }

  let stompClient = Stomp.client(stompURL);
  stompClient.debug = () => {
    return null;
  };
  stompClient.connect({}, (frame) => {
    if (id != 'casino') {
      stompClient.subscribe('/live/liveMatches', function (message) {
        try {
          let data = JSON.parse(message.body);
          if (id == 'cricket')
            dispatch({
              type: 'gameData/setCricketGames',
              payload: data,
            });
          if (id == 'soccer' || id == 'tennis') {
            dispatch({
              type: 'gameData/setSoccerGames',
              payload: data.soccerAllMatchesResponseDTOS,
            });
            dispatch({
              type: 'gameData/setTennisGames',
              payload: data.tennisAllMatchesResponseDTOS,
            });
          }
        } catch (e) {
          console.log(e);
        }
      });
    }
    let url = '';
    if (id == 'cricket') url = '/live/liveOdds';
    if (id == 'soccer') url = '/live/soccer/liveOdds';
    if (id == 'tennis') url = '/live/tennis/liveOdds';
    if (id == 'casino') url = `/live/${game}`;
    stompClient.subscribe(url, function (message) {
      try {
        if (id == 'cricket')
          dispatch({
            type: 'gameData/setCricketOdds',
            payload: JSON.parse(message.body),
          });
        if (id == 'soccer')
          dispatch({
            type: 'gameData/setSoccerOdds',
            payload: JSON.parse(message.body),
          });
        if (id == 'tennis')
          dispatch({
            type: 'gameData/setTennisOdds',
            payload: JSON.parse(message.body),
          });
        if (id == 'casino') {
          switch (game) {
            case 'dragonTiger20':
              dispatch({
                type: 'gameData/setDragonTiger20Odds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'casinoMeter':
              dispatch({
                type: 'gameData/setCasinoMeterOdds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'teenPatti20':
              dispatch({
                type: 'gameData/setTeenPatti20Odds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'dragonTiger202':
              dispatch({
                type: 'gameData/setDragonTiger202Odds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'lucky7':
              dispatch({
                type: 'gameData/setLucky7Odds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'lucky7eu':
              dispatch({
                type: 'gameData/setLucky7euOdds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'casinoQueen':
              dispatch({
                type: 'gameData/setCasinoQueenOdds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'aaa':
              dispatch({
                type: 'gameData/setAaaOdds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'poker20':
              dispatch({
                type: 'gameData/setPoker20Odds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'dragonTigerOneDay':
              dispatch({
                type: 'gameData/setDragonTigerOneDayOdds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'teen':
              dispatch({
                type: 'gameData/setTeenOdds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'andarBahar20':
              dispatch({
                type: 'gameData/setAndarBahar20Odds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'abj':
              dispatch({
                type: 'gameData/setAbjOdds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'card32':
              dispatch({
                type: 'gameData/setCard32Odds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'dragonTigerLion':
              dispatch({
                type: 'gameData/setDragonTigerLionOdds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'teen8':
              dispatch({
                type: 'gameData/setTeen8Odds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'teen9':
              dispatch({
                type: 'gameData/setTeen9Odds',
                payload: JSON.parse(message.body),
              });
              break;

            case 'poker9':
              dispatch({
                type: 'gameData/setPoker9Odds',
                payload: JSON.parse(message.body),
              });
              break;

            case 'card32eu':
              dispatch({
                type: 'gameData/setCard32euOdds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'baccarat2':
              dispatch({
                type: 'gameData/setBaccarat20Odds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'race20':
              dispatch({
                type: 'gameData/setRace20Odds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'worli2':
              dispatch({
                type: 'gameData/setWorli2Odds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'bollywoodTable':
              dispatch({
                type: 'gameData/setBollywoodTableOdds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'casinoWar':
              dispatch({
                type: 'gameData/setCasinoWarOdds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'abj':
              dispatch({
                type: 'gameData/setAbjOdds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'baccarat':
              dispatch({
                type: 'gameData/setBaccaratOdds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'cmatch20':
              dispatch({
                type: 'gameData/setCmatch20Odds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'worli':
              dispatch({
                type: 'gameData/setWorliOdds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'superOver':
              dispatch({
                type: 'gameData/setSuperOverOdds',
                payload: JSON.parse(message.body),
              });
              break;
            case 'cricketV3':
              dispatch({
                type: 'gameData/setCricketv3Odds',
                payload: JSON.parse(message.body),
              });
              break;
          }
        }
      } catch (e) {
        console.log(e);
      }
    });
  });
};

export const sidebarCasinoList = [
  {
    name: 'race 20-20',
    url: 'race20.png',
    path: 'race20',
  },
  {
    name: 'casino queen',
    url: 'queen.jpg',
    path: 'casinoQueen',
  },

  {
    name: 'Dragon Tiger',
    url: '20-20 dragon tiger 2.jpg',
    path: '?name=dragon-tiger',
  },
  {
    name: 'Sports Casino',
    url: '',
    path: '?name=sports-casino',
  },
  {
    name: 'Andar Bahar',
    url: '',
    path: '?name=andar-bahar',
  },

  {
    name: 'Bollywood Casino',
    url: 'casino war.jpg',
    path: '?name=bollywood',
  },
  {
    name: 'Casino War',
    url: 'casino war.jpg',
    path: 'casinoWar',
  },

  // {
  //   name: "worli matka",
  //   url: "worli matka.jpg",
  //   path: "worli",
  // },
  {
    name: 'Worli',
    url: 'instant worli.jpg',
    path: 'worli2',
  },
  {
    name: 'lottery',
    url: 'lottery.jpg',
    path: '',
  },
];
