import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  color,
  Flex,
  Grid,
  GridItem,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Flip, Flipr } from '../../../components/Flip';
import { placeCasinoBetAPI } from '../../../service/casino';
import { ASSETS_URL, bgColor, secondaryColor } from '../../../utils/constants';

import { cricketFancyPL, cricketMatchOddsPL } from '../game/profitLoss';

import '../games/css/inning-rule.css';
import { useMemo } from 'react';
import Iframe from 'react-iframe';
import IframeComponent from '../../../components/Iframe';
import CasinoVideoRules from '../../../components/CasinoVideoRules';
import LastResults from '../../../components/LastResults';
import { setGameKey } from '../../../redux/features/gameSlice';

export default function SuperOver() {
  const dispatch = useDispatch();
  const odds_ = useSelector((state) => state.gameData.superOverOdds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [selectedEventForBet, setSelectedEventForBet] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const toast = useToast();
  const gameName = 'SUPEROVER';
  const CustomButton = ({ text, item, size, ...rest }) => (
    <Button
      size={size ? size : 'sm'}
      my='2'
      display='flex'
      justifyContent='center'
      alignItems='center'
      className='pattiButton'
      onClick={isLocked ? null : rest.onClick}
    >
      {isLocked ? <FaLock /> : item ? item : text}
    </Button>
  );
  const CustomButtonPink = ({ text, item, size, ...rest }) => (
    <Button
      size={size ? size : 'sm'}
      my='2'
      display='flex'
      justifyContent='center'
      alignItems='center'
      className='casinoButton-pink'
      onClick={isLocked ? null : rest.onClick}
    >
      {isLocked ? <FaLock /> : item ? item : text}
    </Button>
  );
  const getData = (id, key = 'oddDetailsDTOS') => {
    let data = odds[key]?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  const setEvent = (id, isBack = true, marketName = 'oddDetailsDTOS') => {
    let selectedEventForBet = getData(id, marketName);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit =
      marketName == 'odds'
        ? cricketMatchOddsPL(
            isBack ? selectedEventForBet.back1 : selectedEventForBet.lay1,
            selectedAmount,
            isBack ? 'back' : 'lay'
          ).profit
        : cricketFancyPL(
            isBack ? selectedEventForBet.back1 : selectedEventForBet.lay1,
            selectedAmount,
            isBack ? 'back' : 'lay'
          ).profit;
    payload.loss =
      marketName == 'odds'
        ? cricketMatchOddsPL(
            isBack ? selectedEventForBet.back1 : selectedEventForBet.lay1,
            selectedAmount,
            isBack ? 'back' : 'lay'
          ).profit
        : cricketFancyPL(
            isBack ? selectedEventForBet.back1 : selectedEventForBet.lay1,
            selectedAmount,
            isBack ? 'back' : 'lay'
          ).profit;
    payload.selectedOdd = isBack
      ? selectedEventForBet.back1
      : selectedEventForBet.lay1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = isBack ? 'back' : 'lay';

    dispatch({
      type: 'gameData/setSelectedEventForBet',
      payload,
    });
  };

  useEffect(() => {
    dispatch(setGameKey('superOver'));
  }, [dispatch]);

  useEffect(() => {
    if (odds?.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);
  const ref = useRef();
  return (
    <Box
      display='flex'
      flexDirection={{ base: 'column', sm: 'column', lg: 'row' }}
    >
      <Box mt='1' w={{ base: '100%', sm: '100%', lg: '70%' }} mr='1'>
        <Box
          h='50vh'
          overflow='hidden'
          mb='2'
          pos='relative'
          className='casino-video'
        >
          {/*   {odds?.cards && (
            <Box
              top="8"
              p="2"
              pos="absolute"
              display="flex"
              flexDirection="column"
              bg="black"
            >
              <Box display="flex" mb="2">
                {odds?.cards[0].map((item) => (
                  <img
                    style={{
                      marginRight: "5px",
                    }}
                    className="cardimg"
                    src={require(`../../../assets/img/casino/${item}.png`)}
                    alt=""
                  />
                ))}
              </Box>
              <Box display="flex" color={"gray.500"}>
                {odds?.cards[1].map((item) => (
                  <img
                    style={{
                      marginRight: "5px",
                    }}
                    className="cardimg"
                    src={require(`../../../assets/img/casino/${item}.png`)}
                    alt=""
                  />
                ))}
              </Box>
            </Box>
          )} */}
          <div className='market-title' style={{ width: '100%' }}>
            SUPER OVER
          </div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3060`}
          />
          <Box
            bottom='0'
            p='2'
            pos='absolute'
            right='2'
            display='flex'
            bg='black'
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules image={`${ASSETS_URL}superover rules.jpg`} />
        </Box>

        {/* <div className="markets">
          <div className="bookmaker-market">
            <div className="market-title mt-1">
              Bookmaker market
              <a href="javascript:void(0)" className="m-r-5 game-rules-icon">
                <span>
                  <i className="fa fa-info-circle float-right"></i>
                </span>
              </a>
            </div>
            <div className="table-header">
              <div className="float-left country-name box-4 text-info"></div>
              <div className="box-1 float-left"></div>
              <div className="box-1 float-left"></div>
              <div className="back box-1 float-left text-center">
                <b>BACK</b>
              </div>
              <div className="lay box-1 float-left text-center">
                <b>LAY</b>
              </div>
              <div className="box-1 float-left"></div>
              <div className="box-1 float-left"></div>
            </div>
            <div className="table-body">
              {odds?.oddDetailsDTOS?.map((match, index) => (
                <div className="table-row suspended">
                  <div className="float-left country-name box-4">
                    <span className="team-name">
                      <b>{match.runnerName ? match.runnerName : match.nat}</b>
                    </span>
                    <p>
                      <span className="float-left">0</span>{" "}
                      <span className="float-right">0</span>
                    </p>
                  </div>
                  <div className="box-1 back2 float-left text-center"></div>
                  <div className="box-1 back1 float-left back-2 text-center"></div>
                  <div
                    className="box-1 back float-left back lock text-center betting-disabled"
                    data={odds}
                    match={match}
                    oddsKey={"back1"}
                    index={index}
                    isFancy={true}
                    onClick={(data_) => setEvent(data_.runnerName)}
                  >
                    {" "}
                    <span className="odd d-block">1.98</span>{" "}
                    <span data-v-b8cbfc58="">1000k</span>
                  </div>
                  <div
                    className="box-1 lay float-left text-center betting-disabled"
                    data={odds}
                    isFancy={true}
                    match={match}
                    oddsKey={"lay1"}
                    index={index}
                    back={false}
                    onClick={(data_) => setEvent(data_.runnerName, false)}
                  >
                    <span className="odd d-block">1.98</span>{" "}
                    <span data-v-b8cbfc58="">1000k</span>
                  </div>
                  <div className="box-1 lay1 float-left text-center"></div>
                  <div className="box-1 lay2 float-left text-center"></div>
                </div>
              ))}
            </div>
          </div>
          <div className="fancy-market row row5">
            <div className="col-6">
              <div>
                <div className="market-title mt-1">
                  Fancy1 Market
                  <a
                    href="javascript:void(0)"
                    className="m-r-5 game-rules-icon"
                  >
                    <span>
                      <i className="fa fa-info-circle float-right"></i>
                    </span>
                  </a>
                </div>
                <div className="table-header">
                  <div className="float-left country-name box-6"></div>
                  <div className="back box-1 float-left back text-center">
                    <b>Back</b>
                  </div>
                  <div className="box-1 float-left lay text-center">
                    <b>Lay</b>
                  </div>
                  <div className="box-2 float-left"></div>
                </div>
                <div className="table-body">
                  <div className="fancy-tripple">
                    <div data-title="ACTIVE" className="table-row">
                      <div className="float-left country-name box-6">
                        <p className="m-b-0">0.5 Over Wicket RSA</p>
                        <p className="m-b-0">
                          <span>0</span>
                        </p>
                      </div>
                      <div className="box-1 back float-left text-center">
                        <span className="odd d-block">7.41</span>{" "}
                        <span>100</span>
                      </div>
                      <div className="box-1 lay float-left text-center betting-disabled"></div>
                      <div className="box-2 float-left text-right min-max"></div>
                    </div>
                  </div>
                  <div className="fancy-tripple">
                    <div data-title="ACTIVE" className="table-row">
                      <div className="float-left country-name box-6">
                        <p className="m-b-0">0.5 Over Zero RSA</p>
                        <p className="m-b-0">
                          <span>0</span>
                        </p>
                      </div>
                      <div className="box-1 back float-left text-center">
                        <span className="odd d-block">7.41</span>{" "}
                        <span>100</span>
                      </div>
                      <div className="box-1 lay float-left text-center betting-disabled"></div>
                      <div className="box-2 float-left text-right min-max"></div>
                    </div>
                  </div>
                  <div className="fancy-tripple">
                    <div data-title="ACTIVE" className="table-row">
                      <div className="float-left country-name box-6">
                        <p className="m-b-0">0.5 Over One RSA</p>
                        <p className="m-b-0">
                          <span>0</span>
                        </p>
                      </div>
                      <div className="box-1 back float-left text-center">
                        <span className="odd d-block">5.94</span>{" "}
                        <span>100</span>
                      </div>
                      <div className="box-1 lay float-left text-center betting-disabled"></div>
                      <div className="box-2 float-left text-right min-max"></div>
                    </div>
                  </div>
                  <div className="fancy-tripple">
                    <div data-title="ACTIVE" className="table-row">
                      <div className="float-left country-name box-6">
                        <p className="m-b-0">0.5 Over Two RSA</p>
                        <p className="m-b-0">
                          <span>0</span>
                        </p>
                      </div>
                      <div className="box-1 back float-left text-center">
                        <span className="odd d-block">7.41</span>{" "}
                        <span>100</span>
                      </div>
                      <div className="box-1 lay float-left text-center betting-disabled"></div>
                      <div className="box-2 float-left text-right min-max"></div>
                    </div>
                  </div>
                  <div className="fancy-tripple">
                    <div data-title="ACTIVE" className="table-row">
                      <div className="float-left country-name box-6">
                        <p className="m-b-0">0.5 Over Three RSA</p>
                        <p className="m-b-0">
                          <span>0</span>
                        </p>
                      </div>
                      <div className="box-1 back float-left text-center">
                        <span className="odd d-block">5.94</span>{" "}
                        <span>100</span>
                      </div>
                      <div className="box-1 lay float-left text-center betting-disabled"></div>
                      <div className="box-2 float-left text-right min-max"></div>
                    </div>
                  </div>
                  <div className="fancy-tripple">
                    <div data-title="ACTIVE" className="table-row">
                      <div className="float-left country-name box-6">
                        <p className="m-b-0">0.5 Over Boundry RSA</p>
                        <p className="m-b-0">
                          <span>0</span>
                        </p>
                      </div>
                      <div className="box-1 back float-left text-center">
                        <span className="odd d-block">3.32</span>{" "}
                        <span>100</span>
                      </div>
                      <div className="box-1 lay float-left text-center betting-disabled"></div>
                      <div className="box-2 float-left text-right min-max"></div>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div> */}

        <div className='bookmaker-market'>
          <div className='markets'>
            {odds?.oddDetailsDTOS.length > 0 && (
              <>
                <div className='market-title mt-1' style={{ width: '100%' }}>
                  Bookmaker market
                  <a
                    href='javascript:void(0)'
                    className='m-r-5 game-rules-icon'
                  >
                    <span>
                      <i className='fa fa-info-circle float-right'></i>
                    </span>
                  </a>
                </div>
                <div className='table-header'>
                  <div className='float-left country-name box-4 text-info'></div>
                  <div className='box-1 float-left'></div>
                  <div className='box-1 float-left'></div>
                  <div className='back box-1 float-left text-center'>
                    <b>BACK</b>
                  </div>
                  <div className='lay box-1 float-left text-center'>
                    <b>LAY</b>
                  </div>
                  <div className='box-1 float-left'></div>
                  <div className='box-1 float-left'></div>
                </div>
                <div className='table-body'>
                  {odds?.oddDetailsDTOS?.map((match, index) => (
                    <div
                      data-title='SUSPENDED'
                      className={`table-row ${isLocked ? 'suspendedtext' : ''}`}
                    >
                      <div className='float-left country-name box-4'>
                        <span className='team-name'>
                          <b>
                            {match.runnerName ? match.runnerName : match.nat}
                          </b>
                        </span>
                        <p>
                          <span className='float-left'>0</span>{' '}
                          <span className='float-right'>0</span>
                        </p>
                      </div>
                      <div className='box-1 back2 float-left text-center'></div>
                      <div className='box-1 back1 float-left back-2 text-center'></div>
                      <div
                        className='box-1 back float-left back lock text-center betting-disabled'
                        data={odds}
                        match={match}
                        oddsKey={'back1'}
                        index={index}
                        isFancy={true}
                        onClick={(data_) => {
                          if (getData(match.runnerName).back1 > 0) {
                            setEvent(data_.runnerName);
                          }
                        }}
                      >
                        {' '}
                        <span className='odd d-block'>
                          {getData(match.runnerName).back1}
                        </span>{' '}
                        <span data-v-b8cbfc58=''>
                          {getData(match.runnerName).backSize1}
                        </span>
                      </div>
                      <div
                        className='box-1 lay float-left text-center betting-disabled'
                        data={odds}
                        isFancy={true}
                        match={match}
                        oddsKey={'lay1'}
                        index={index}
                        back={false}
                        onClick={(data_) => {
                          if (getData(match.runnerName).lay1 > 0) {
                            setEvent(data_.runnerName, false);
                          }
                        }}
                      >
                        <span className='odd d-block'>
                          {getData(match.runnerName).lay1}
                        </span>{' '}
                        <span data-v-b8cbfc58=''>
                          {getData(match.runnerName).laySize1}
                        </span>
                      </div>
                      <div className='box-1 lay1 float-left text-center'></div>
                      <div className='box-1 lay2 float-left text-center'></div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {odds?.fancyOddDetailsDTOS.length > 0 && (
              <div className='fancy-market row row5'>
                <div className='col-6'>
                  <div>
                    <div
                      className='market-title mt-1'
                      style={{ width: '100%' }}
                    >
                      Fancy Market
                      <a
                        href='javascript:void(0)'
                        className='m-r-5 game-rules-icon'
                      >
                        <span>
                          <i className='fa fa-info-circle float-right'></i>
                        </span>
                      </a>
                    </div>
                    <div className='table-header'>
                      <div className='float-left country-name box-6'></div>
                      <div className='box-1 float-left lay text-center'>
                        <b>No</b>
                      </div>
                      <div className='back box-1 float-left back text-center'>
                        <b>Yes</b>
                      </div>
                      <div className='box-2 float-left'></div>
                    </div>
                    <div className='table-body'>
                      {odds?.fancyOddDetailsDTOS?.map((match, index) => {
                        return (
                          <div className='fancy-tripple'>
                            <div
                              data-title='SUSPENDED'
                              className={`table-row ${
                                isLocked ? 'suspendedtext' : ''
                              }`}
                            >
                              <div className='float-left country-name box-6'>
                                <p className='m-b-0'>
                                  <a href='javascript:void(0)'>
                                    {match.runnerName
                                      ? match.runnerName
                                      : match.nat}
                                  </a>
                                </p>
                                <p className='m-b-0'>
                                  <span>0</span>
                                </p>
                              </div>
                              <div className='box-1 lay float-left text-center'>
                                <span
                                  className='odd d-block'
                                  data={odds}
                                  isFancy={true}
                                  match={match}
                                  oddsKey={'lay1'}
                                  index={index}
                                  back={false}
                                  onClick={(data_) => {
                                    if (match.back1 > 0) {
                                      setEvent(
                                        match.runnerName,
                                        false,
                                        'fancyOddDetailsDTOS'
                                      );
                                    }
                                  }}
                                >
                                  {match.back1}
                                </span>{' '}
                                <span>
                                  {getData(match.runnerName).laySize1}
                                </span>
                              </div>
                              <div className='box-1 back float-left text-center'>
                                <span
                                  className='odd d-block'
                                  data={odds}
                                  match={match}
                                  oddsKey={'back1'}
                                  index={index}
                                  isFancy={true}
                                  onClick={(data_) => {
                                    if (getData(match.runnerName).back1 > 0) {
                                      setEvent(
                                        match.runnerName,
                                        true,
                                        'fancyOddDetailsDTOS'
                                      );
                                    }
                                  }}
                                >
                                  {getData(match.runnerName).back1}
                                </span>{' '}
                                <span>
                                  {getData(match.runnerName).laySize1}
                                </span>
                              </div>
                              <div className='box-2 float-left text-right min-max'></div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div></div>
                  </div>
                </div>
                <div className='col-6'>
                  <div>
                    <div
                      className='market-title mt-1'
                      style={{ width: '100%' }}
                    >
                      Fancy1 Market
                      <a
                        href='javascript:void(0)'
                        className='m-r-5 game-rules-icon'
                      >
                        <span>
                          <i className='fa fa-info-circle float-right'></i>
                        </span>
                      </a>
                    </div>
                    <div className='table-header'>
                      <div className='float-left country-name box-6'></div>
                      <div className='back box-1 float-left back text-center'>
                        <b>Back</b>
                      </div>
                      <div className='box-1 float-left lay text-center'>
                        <b>Lay</b>
                      </div>
                      <div className='box-2 float-left'></div>
                    </div>
                    {odds?.fancy2OddDetailsDTOS?.map((match, index) => (
                      <div className='table-body'>
                        <div className='fancy-tripple'>
                          <div
                            data-title='Suspended'
                            className={`table-row  ${
                              isLocked ? 'suspendedtext' : ''
                            }`}
                          >
                            <div className='float-left country-name box-6'>
                              <p className='m-b-0'>
                                {match.runnerName
                                  ? match.runnerName
                                  : match.nat}
                              </p>
                              <p className='m-b-0'>
                                <span>0</span>
                              </p>
                            </div>
                            <div
                              className='box-1 back float-left text-center'
                              onClick={(data_) => {
                                if (match.back1 > 0) {
                                  setEvent(
                                    match.runnerName,
                                    true,
                                    'fancyOddDetailsDTOS'
                                  );
                                }
                              }}
                            >
                              <span className='odd d-block'>{match.back1}</span>{' '}
                              <span style={{ paddingTop: '8px' }}>
                                {match.backSize1}
                              </span>
                            </div>
                            <div
                              className='box-1 lay float-left text-center betting-disabled'
                              onClick={() => {
                                if (match.lay1) {
                                  setEvent(
                                    match.runnerName,
                                    true,
                                    'fancyOddDetailsDTOS'
                                  );
                                }
                              }}
                            >
                              {' '}
                              <span className='odd d-block'>
                                {match.lay1}
                              </span>{' '}
                              <span style={{ paddingTop: '8px' }}>
                                {match.laySize1}
                              </span>
                            </div>
                            <div className='box-2 float-left text-right min-max'>
                              {' '}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <LastResults eventId={gameName} />
      </Box>
    </Box>
  );
}
