import {
  Box,
  Button,
  color,
  Flex,
  Grid,
  GridItem,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Flip, Flipr } from '../../../components/Flip';
import {
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from '../../../service/casino';

import CardDrawer from '../../../components/CardDrawer/CardDrawer';
import { useMemo } from 'react';
import Iframe from 'react-iframe';
import IframeComponent from '../../../components/Iframe';
import CasinoVideoRules from '../../../components/CasinoVideoRules';
import { ASSETS_URL } from '../../../utils/constants';
import LastResults from '../../../components/LastResults';
import { setGameKey } from '../../../redux/features/gameSlice';
import { getService } from '../../../utils/commonFunctions';
import { useLocation } from 'react-router-dom';

export default function Cards32A() {
  const dispatch = useDispatch();
  const location = useLocation();
  const odds_ = useSelector((state) => state.gameData.card32Odds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [selectedEventForBet, setSelectedEventForBet] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [plExposure, setPlExposure] = useState(null);
  const [isLocked, setIsLocked] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const authDetails = useSelector((state) => state.account.authDetails);
  const toast = useToast();
  const gameName = 'CARD_32';
  const CustomButton = ({ text, item, size, ...rest }) => (
    <Button
      size={size ? size : 'sm'}
      my='2'
      display='flex'
      justifyContent='center'
      alignItems='center'
      className='pattiButton'
      onClick={isLocked ? null : rest.onClick}
    >
      {isLocked ? <FaLock /> : item ? item : text}
    </Button>
  );
  const CustomButtonPink = ({ text, item, size, ...rest }) => (
    <Button
      size={size ? size : 'sm'}
      my='2'
      display='flex'
      justifyContent='center'
      alignItems='center'
      className='casinoButton-pink'
      onClick={isLocked ? null : rest.onClick}
    >
      {isLocked ? <FaLock /> : item ? item : text}
    </Button>
  );
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  const getProfit = (size) => {
    return (size - 1) * selectedAmount;
  };
  const setEvent = (id, isBack = true) => {
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(
      isBack ? selectedEventForBet.back1 : selectedEventForBet.lay1
    );
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = isBack
      ? selectedEventForBet.back1
      : selectedEventForBet.lay1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = isBack ? 'back' : 'lay';

    dispatch({
      type: 'gameData/setSelectedEventForBet',
      payload,
    });
  };

  useEffect(() => {
    dispatch(setGameKey('card32'));
  }, [dispatch]);
  useEffect(() => {
    if (odds?.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);
  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id,
      authDetails?.uid
    );
    if (res && res?.code == 200) {
      setPlExposure(res.response);
    }
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data;
      for (let i = 0; i < plExposure.length; i++) {
        let data = plExposure[i]?.casinoPLExposure?.find(
          (item) => item.selectionName == selectionName
        );
        if (data) {
          return data;
        }
      }
      return data ? data : null;
    }
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);
  return (
    <Box
      display='flex'
      flexDirection={{ base: 'column', sm: 'column', lg: 'row' }}
      className='card-game-box'
    >
      <Box mt='1' w={{ base: '100%', sm: '100%', lg: '70%' }} mr='1'>
        <Box
          h='50vh'
          overflow='hidden'
          mb='2'
          pos='relative'
          className='casino-video'
        >
          {odds?.cards && (
            <CardDrawer
              cards={[
                odds?.cards[0],
                odds?.cards[1],
                odds?.cards[2],
                odds?.cards[3],
              ]}
              labels={['TOTAL 8', 'TOTAL 9', 'TOTAL 10', 'TOTAL 11']}
              multiLevel={true}
              singleCard
            />
          )}
          <div className='market-title'>32 CARDS A</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3055`}
          />
          <Box
            bottom='0'
            p='2'
            pos='absolute'
            right='2'
            display='flex'
            bg='black'
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules image={`${ASSETS_URL}32 cards a_rule.jpg`} />
        </Box>
        <div className='card-content m-t-0'>
          <div className='row'>
            <div className='col-md-6 m-b-10 main-market p-r-5'>
              <div className='live-poker'>
                <table className='table coupon-table table table-bordered'>
                  <thead>
                    <tr>
                      <th className='box-6'></th>
                      <th className='box-2 back'>BACK</th>
                      <th className='box-2 lay-color'>LAY</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='box-6'>
                        <b>Player 8</b>
                        <p className='m-b-0'>
                          <span>
                            {getExposureForEntity('Player 8')?.exposure}
                          </span>
                        </p>
                      </td>
                      <td
                        className={`box-2 back teen-section ${
                          isLocked ? 'suspendedtd' : ''
                        }`}
                      >
                        <button
                          onClick={() => setEvent('Player 8')}
                          className='back'
                        >
                          <span className='odd'>
                            {' '}
                            {getData('Player 8').back1}
                          </span>{' '}
                          <span>0</span>
                        </button>
                      </td>
                      <td
                        className={`box-2 lay teen-section ${
                          isLocked ? 'suspendedtd' : ''
                        }`}
                      >
                        <button
                          onClick={() => setEvent('Player 8', false)}
                          className='lay'
                        >
                          <span className='odd'>
                            <b>{getData('Player 8').lay1}</b>
                          </span>{' '}
                          <span>0</span>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td className='box-6'>
                        <b>Player 9</b>
                        <p className='m-b-0'>
                          <span>
                            {' '}
                            {getExposureForEntity('Player 9')?.exposure}
                          </span>
                        </p>
                      </td>
                      <td
                        className={`box-2 back teen-section ${
                          isLocked ? 'suspendedtd' : ''
                        }`}
                      >
                        <button
                          onClick={() => setEvent('Player 9')}
                          className='back'
                        >
                          <span className='odd'>
                            {getData('Player 9').back1}
                          </span>{' '}
                          <span>0</span>
                        </button>
                      </td>
                      <td
                        className={`box-2 lay teen-section ${
                          isLocked ? 'suspendedtd' : ''
                        }`}
                      >
                        <button
                          onClick={() => setEvent('Player 9', false)}
                          className='lay'
                        >
                          <span className='odd'>
                            <b>{getData('Player 9').lay1}</b>
                          </span>{' '}
                          <span>0</span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='col-md-6 m-b-10 main-market p-l-5'>
              <div className='live-poker'>
                <table className='table coupon-table table table-bordered'>
                  <thead>
                    <tr>
                      <th className='box-6'></th>
                      <th className='box-2 back'>BACK</th>
                      <th className='box-2 lay'>LAY</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='box-6'>
                        <b>Player 10</b>
                        <p className='m-b-0'>
                          <span>
                            {' '}
                            {getExposureForEntity('Player 10')?.exposure}
                          </span>
                        </p>
                      </td>
                      <td
                        className={`box-2 back teen-section ${
                          isLocked ? 'suspendedtd' : ''
                        }`}
                      >
                        <button
                          className='back'
                          onClick={() => setEvent('Player 10')}
                        >
                          <span className='odd'>
                            {getData('Player 10').back1}
                          </span>{' '}
                          <span>0</span>
                        </button>
                      </td>
                      <td
                        className={`box-2 lay teen-section ${
                          isLocked ? 'suspendedtd' : ''
                        }`}
                      >
                        <button
                          onClick={() => setEvent('Player 10', false)}
                          className='lay'
                        >
                          <span className='odd'>
                            <b>{getData('Player 10').lay1}</b>
                          </span>{' '}
                          <span>0</span>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td className='box-6'>
                        <b>Player 11</b>
                        <p className='m-b-0'>
                          <span>
                            {' '}
                            {getExposureForEntity('Player 11')?.exposure}
                          </span>
                        </p>
                      </td>
                      <td
                        className={`box-2 back teen-section ${
                          isLocked ? 'suspendedtd' : ''
                        }`}
                      >
                        <button
                          onClick={() => setEvent('Player 11')}
                          className='back'
                        >
                          <span className='odd'>
                            {' '}
                            {getData('Player 11').back1}
                          </span>{' '}
                          <span>0</span>
                        </button>
                      </td>
                      <td
                        className={`box-2 lay teen-section ${
                          isLocked ? 'suspendedtd' : ''
                        }`}
                      >
                        <button
                          onClick={() => setEvent('Player 11', false)}
                          className='lay'
                        >
                          <span className='odd'>
                            <b>{getData('Player 11').lay1}</b>
                          </span>{' '}
                          <span>0</span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <LastResults eventId={gameName} />
      </Box>
    </Box>
  );
}
