import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Flip, Flipr } from '../../../components/Flip';
import {
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from '../../../service/casino';
import './css/bollywood.css';
import CardDrawer from '../../../components/CardDrawer/CardDrawer';
import { ASSETS_URL } from '../../../utils/constants';
import { useMemo } from 'react';
import Iframe from 'react-iframe';
import IframeComponent from '../../../components/Iframe';
import CasinoVideoRules from '../../../components/CasinoVideoRules';
import LastResults from '../../../components/LastResults';
import Popover from '../../../components/Popover';
import { setGameKey } from '../../../redux/features/gameSlice';
import { getService } from '../../../utils/commonFunctions';
import { useLocation } from 'react-router-dom';
export default function BollywoodCasino() {
  const location = useLocation();
  const odds_ = useSelector((state) => state.gameData.bollywoodTableOdds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [plExposure, setPlExposure] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const toast = useToast();
  const dispatch = useDispatch();
  const gameName = 'BOLLYWOOD_TABLE';
  const Card7 = [
    {
      id: 'Tiger Card 1',
      img: require('../../../assets/img/casino/7.png'),
    },
  ];
  const allCard = [
    {
      id: 'Tiger Card 1',
      img: require('../../../assets/img/casino/1 - Copy.png'),
    },
    {
      id: 'Tiger Card J',
      img: require('../../../assets/img/casino/11.png'),
    },
    {
      id: 'Tiger Card Q',
      img: require('../../../assets/img/casino/12.png'),
    },
    {
      id: 'Tiger Card K',
      img: require('../../../assets/img/casino/13.png'),
    },
  ];

  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  const getProfit = (size) => {
    return (size - 1) * selectedAmount;
  };
  const setEvent = (id, isBack = true) => {
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(
      isBack ? selectedEventForBet.back1 : selectedEventForBet.lay1
    );
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = isBack
      ? selectedEventForBet.back1
      : selectedEventForBet.lay1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = isBack ? 'back' : 'lay';

    dispatch({
      type: 'gameData/setSelectedEventForBet',
      payload,
    });
  };

  useEffect(() => {
    dispatch(setGameKey('bollywoodTable'));
  }, [dispatch]);

  useEffect(() => {
    if (odds?.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);
  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id
    );
    let transformedData = [];

    if (res && res?.code == 200) {
      res.response.map((item) =>
        item.casinoPLExposure.map((item) => transformedData.push(item))
      );
      setPlExposure(transformedData);
    }
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data = plExposure?.find(
        (exposure) => exposure.selectionName == selectionName
      );
      return data ? data : null;
    }
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);
  return (
    <Box
      display='flex'
      flexDirection={{ base: 'column', sm: 'column', lg: 'row' }}
    >
      <Box mt='1' mr='1' w={{ base: '100%', sm: '100%', lg: '70%' }}>
        <Box h='60vh' mb='2' pos='relative' className='casino-video'>
          {odds?.cards && <CardDrawer cards={[odds?.cards[0]]} />}
          <div class='market-title'>Bollywood Table</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3041`}
          />
          <Box
            bottom='0'
            p='2'
            pos='absolute'
            right='2'
            display='flex'
            bg='black'
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules
            custom={
              <div className='rules-body'>
                <img
                  src='https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/rules/ddb-rules.jpg'
                  className='rules-body'
                />
              </div>
            }
          />
        </Box>
        <div className='casino-detail snipcss-7jBgt bollywood-casion-main'>
          <div className='container-fluid container-fluid-5'>
            <div className='row row5 d-none-small'>
              <div className='col-4'>
                <div className='casino-box-row'>
                  <div className='casino-nation-name'>
                    <b>A. Don</b>
                  </div>
                  <div
                    className={`casino-bl-box ${isLocked ? 'suspended' : ''}`}
                  >
                    <div
                      onClick={() => setEvent('DON').back1}
                      className='back casino-bl-box-item'
                    >
                      <span className='casino-box-odd'>
                        {getData('DON').back1}
                      </span>
                    </div>
                    <div
                      onClick={() => setEvent('DON', false)}
                      className='lay casino-bl-box-item'
                    >
                      <span className='casino-box-odd'>
                        {getData('DON').lay1}
                      </span>
                    </div>
                  </div>
                  <div className='casino-nation-name '>
                    {getExposureForEntity('DON')?.exposure}
                  </div>
                </div>
              </div>
              <div className='col-4'>
                <div className='casino-box-row'>
                  <div className='casino-nation-name'>
                    <b>B. Amar Akbar Anthony</b>
                  </div>
                  <div
                    className={`casino-bl-box ${isLocked ? 'suspended' : ''}`}
                  >
                    <div
                      onClick={() => setEvent('Amar Akbar Anthony')}
                      className='back casino-bl-box-item'
                    >
                      <span className='casino-box-odd'>
                        {getData('Amar Akbar Anthony').back1}
                      </span>
                    </div>
                    <div
                      onClick={() => setEvent('Amar Akbar Anthony', false)}
                      className='lay casino-bl-box-item'
                    >
                      <span className='casino-box-odd'>
                        {getData('Amar Akbar Anthony').lay1}
                      </span>
                    </div>
                  </div>
                  <div className='casino-nation-name '>
                    {getExposureForEntity('Amar Akbar Anthony')?.exposure}
                  </div>
                </div>
              </div>
              <div className='col-4'>
                <div className='casino-box-row'>
                  <div className='casino-nation-name'>
                    <b>C. Sahib Bibi Aur Ghulam</b>
                    <div className='text-right'>
                      <Popover>
                        <div id='min-max-info' className='min-max-box'>
                          <p>Min: 100</p>
                          <p>Max: 300000</p>
                        </div>
                      </Popover>
                    </div>
                  </div>
                  <div
                    className={`casino-bl-box ${isLocked ? 'suspended' : ''}`}
                  >
                    <div
                      onClick={() => setEvent('Sahib Bibi Aur Ghulam')}
                      className='back casino-bl-box-item'
                    >
                      <span className='casino-box-odd'>
                        {getData('Sahib Bibi Aur Ghulam').back1}
                      </span>
                    </div>
                    <div
                      onClick={() => setEvent('Sahib Bibi Aur Ghulam', false)}
                      className='lay casino-bl-box-item'
                    >
                      <span className='casino-box-odd'>
                        {getData('Sahib Bibi Aur Ghulam').lay1}
                      </span>
                    </div>
                  </div>
                  <div className='casino-nation-name '>
                    {getExposureForEntity('Sahib Bibi Aur Ghulam')?.exposure}
                  </div>
                </div>
              </div>
              <div className='col-4'>
                <div className='casino-box-row'>
                  <div className='casino-nation-name'>
                    <b>D. Dharam Veer</b>
                  </div>
                  <div
                    className={`casino-bl-box ${isLocked ? 'suspended' : ''}`}
                  >
                    <div
                      onClick={() => setEvent('Dharam Veer')}
                      className='back casino-bl-box-item'
                    >
                      <span className='casino-box-odd'>
                        {getData('Dharam Veer').back1}
                      </span>
                    </div>
                    <div
                      onClick={() => setEvent('Dharam Veer', false)}
                      className='lay casino-bl-box-item'
                    >
                      <span className='casino-box-odd'>
                        {getData('Dharam Veer').lay1}
                      </span>
                    </div>
                  </div>
                  <div className='casino-nation-name '>
                    {getExposureForEntity('Dharam Veer')?.exposure}
                  </div>
                </div>
              </div>
              <div className='col-4'>
                <div className='casino-box-row'>
                  <div className='casino-nation-name'>
                    <b>E. Kis Kis Ko Pyaar Karoon</b>
                  </div>
                  <div
                    className={`casino-bl-box ${isLocked ? 'suspended' : ''}`}
                  >
                    <div
                      onClick={() => setEvent('Kis KisKo Pyaar Karoon')}
                      className='back casino-bl-box-item'
                    >
                      <span className='casino-box-odd'>
                        {getData('Kis KisKo Pyaar Karoon').back1}
                      </span>
                    </div>
                    <div
                      onClick={() => setEvent('Kis KisKo Pyaar Karoon', false)}
                      className='lay casino-bl-box-item'
                    >
                      <span className='casino-box-odd'>
                        {getData('Kis KisKo Pyaar Karoon').lay1}
                      </span>
                    </div>
                  </div>
                  <div className='casino-nation-name '>
                    {getExposureForEntity('Kis KisKo Pyaar Karoon')?.exposure}
                  </div>
                </div>
              </div>
              <div className='col-4'>
                <div className='casino-box-row'>
                  <div className='casino-nation-name'>
                    <b>F. Ghulam</b>
                  </div>
                  <div
                    className={`casino-bl-box ${isLocked ? 'suspended' : ''}`}
                  >
                    <div
                      onClick={() => setEvent('Ghulam')}
                      className='back casino-bl-box-item'
                    >
                      <span className='casino-box-odd'>
                        {getData('Ghulam').back1}
                      </span>
                    </div>
                    <div
                      onClick={() => setEvent('Ghulam', false)}
                      className='lay casino-bl-box-item'
                    >
                      <span className='casino-box-odd'>
                        {getData('Ghulam').lay1}
                      </span>
                    </div>
                  </div>
                  <div className='casino-nation-name '>
                    {getExposureForEntity('Ghulam')?.exposure}
                  </div>
                </div>
              </div>
              <div className='col-4 d-none-web'>
                <div className='casino-box-row'>
                  <div className='casino-nation-name'>
                    <b>Odd</b>
                  </div>
                  <div
                    className={`casino-bl-box ${isLocked ? 'suspended' : ''}`}
                  >
                    <div
                      onClick={() => setEvent('Odd')}
                      className='back casino-bl-box-item'
                    >
                      <span className='casino-box-odd'>
                        {getData('Odd').back1}
                      </span>
                    </div>
                    <div
                      onClick={() => setEvent('Odd', false)}
                      className='lay casino-bl-box-item'
                    >
                      <span className='casino-box-odd'>
                        {getData('Odd').lay1}
                      </span>
                    </div>
                  </div>
                  <div className='casino-nation-name '>
                    {getExposureForEntity('Odd')?.exposure}
                  </div>
                </div>
              </div>
            </div>
            <div className='row row5 bottom-row'>
              <div className='col-lg-4 col-12 d-none-mobile'>
                <div className='casino-box-row'>
                  <div
                    className='casino-nation-name'
                    style={{ display: 'flex' }}
                  >
                    <div style={{ display: 'flex', marginLeft: '40%' }}>
                      <b>Odd</b>
                    </div>
                    <div
                      className='text-right'
                      style={{ float: 'right', marginLeft: '30%' }}
                    >
                      <Popover>
                        <div id='min-max-info' className='min-max-box'>
                          <p>Min: 100</p>
                          <p>Max: 100000</p>
                        </div>
                      </Popover>
                    </div>
                  </div>
                  <div
                    className={`casino-bl-box ${isLocked ? 'suspended' : ''}`}
                  >
                    <div
                      onClick={() => setEvent('Odd')}
                      className='back casino-bl-box-item'
                    >
                      <span className='casino-box-odd'>
                        {getData('Odd').back1}
                      </span>
                    </div>
                    <div
                      onClick={() => setEvent('Odd', false)}
                      className='lay casino-bl-box-item'
                    >
                      <span className='casino-box-odd'>
                        {getData('Odd').lay1}
                      </span>
                    </div>
                  </div>
                  <div className='casino-nation-name d-none'>
                    {getExposureForEntity('Odd')?.exposure}
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-6 bc-fancy'>
                <div className='casino-box-row'>
                  {/* <div className="casino-nation-name">
                    <b>{getData("Dulha Dulhan K-Q").back1}</b>
                  </div> */}
                  <div
                    className='casino-nation-name'
                    style={{ display: 'flex' }}
                  >
                    <div style={{ display: 'flex', marginLeft: '40%' }}>
                      <b>{getData('Dulha Dulhan K-Q').back1}</b>
                    </div>
                    <div
                      className='text-right'
                      style={{ float: 'right', marginLeft: '30%' }}
                    >
                      <Popover>
                        <div id='min-max-info' className='min-max-box'>
                          <p>Min: 100</p>
                          <p>Max: 100000</p>
                        </div>
                      </Popover>
                    </div>
                  </div>
                  <div
                    className={`casino-bl-box ${isLocked ? 'suspended' : ''}`}
                  >
                    <div
                      onClick={() => setEvent('Dulha Dulhan K-Q')}
                      className='btn-theme casino-bl-box-item'
                    >
                      <span className='casino-box-odd'>Dulha Dulhan K-Q</span>
                      <span className=''>
                        {getExposureForEntity('Dulha Dulhan K-Q')?.exposure}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-6 bc-fancy'>
                <div className='casino-box-row'>
                  {/* <div className="casino-nation-name">
                    <b>{getData("Barati J-A").lay1}</b>
                  </div> */}
                  <div
                    className='casino-nation-name'
                    style={{ display: 'flex' }}
                  >
                    <div style={{ display: 'flex', marginLeft: '40%' }}>
                      <b>{getData('Barati J-A').lay1}</b>
                    </div>
                    <div
                      className='text-right'
                      style={{ float: 'right', marginLeft: '30%' }}
                    >
                      <Popover>
                        <div id='min-max-info' className='min-max-box'>
                          <p>Min: 100</p>
                          <p>Max: 100000</p>
                        </div>
                      </Popover>
                    </div>
                  </div>

                  <div
                    className={`casino-bl-box ${isLocked ? 'suspended' : ''}`}
                  >
                    <div
                      className='btn-theme casino-bl-box-item'
                      onClick={() => setEvent('Barati J-A', false)}
                    >
                      <span className='casino-box-odd'>Barati J-A</span>
                      <span className=''>
                        {getExposureForEntity('Barati J-A')?.exposure}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row row5 bottom-row'>
              <div className='col-lg-6 col-12 aaa-oe'>
                <div
                  className='text-right'
                  // style={{ float: "right", marginLeft: "10%" }}
                >
                  <Popover>
                    <div id='min-max-info' className='min-max-box'>
                      <p>Min: 100</p>
                      <p>Max: 25000</p>
                    </div>
                  </Popover>
                </div>
                <div className='casino-box-row'>
                  <div className={`casino-bl-box `}>
                    <b>{getData('Dulha Dulhan K-Q').back1}</b>
                  </div>
                  <div className={`casino-bl-box `}>
                    <b>{getData('Dulha Dulhan K-Q').back1}</b>
                  </div>
                </div>

                <div className='casino-box-row'>
                  <div
                    style={{
                      width: '90% !important',
                    }}
                    onClick={() => setEvent('Black')}
                    className={`casino-bl-box ${isLocked ? 'suspended' : ''}`}
                  >
                    <Box
                      w='100% !important'
                      mr='20px'
                      className='btn-theme casino-bl-box-item casino-card-img'
                    >
                      <span>
                        <img src={ASSETS_URL + 'spade.png'} />
                        <img src={ASSETS_URL + 'club.png'} />
                      </span>
                    </Box>
                  </div>
                  <div
                    onClick={() => setEvent('Red')}
                    className={`casino-bl-box ${isLocked ? 'suspended' : ''}`}
                  >
                    <Box
                      w='90% !important'
                      className='btn-theme casino-bl-box-item casino-card-img'
                    >
                      <span>
                        <img src={ASSETS_URL + 'heart.png'} />
                        <img src={ASSETS_URL + 'diamond.png'} />
                      </span>
                    </Box>
                    {getExposureForEntity('Red')?.exposure}
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-12'>
                <div className='text-center w-100'>
                  <div className={`casino-bl-box `}>
                    <div
                      className='casino-bl-box-item'
                      style={{ display: 'flex', marginLeft: '20%' }}
                    >
                      <b>Cards 3.75</b>
                    </div>
                    <div
                      className='text-right'
                      style={{ float: 'right', marginLeft: '10%' }}
                    >
                      <Popover>
                        <div id='min-max-info' className='min-max-box'>
                          <p>Min: 100</p>
                          <p>Max: 100000</p>
                        </div>
                      </Popover>
                    </div>
                  </div>
                </div>
                <div className='casino-cards text-center mt-1'>
                  <div
                    onClick={() => setEvent('Card J')}
                    className='casino-card-item'
                  >
                    <div
                      className={`card-image ${isLocked ? 'suspended' : ''}`}
                    >
                      <img src={ASSETS_URL + 'J.png'} />
                    </div>
                    <div className='casino-book '>
                      {getExposureForEntity('Card J')?.exposure}
                    </div>
                  </div>
                  <div
                    onClick={() => setEvent('Card Q')}
                    className='casino-card-item'
                  >
                    <div
                      className={`card-image ${isLocked ? 'suspended' : ''}`}
                    >
                      <img src={ASSETS_URL + 'Q.png'} />
                    </div>
                    <div className='casino-book '>
                      {getExposureForEntity('Card Q')?.exposure}
                    </div>
                  </div>
                  <div
                    onClick={() => setEvent('card K')}
                    className='casino-card-item'
                  >
                    <div
                      className={`card-image ${isLocked ? 'suspended' : ''}`}
                    >
                      <img src={ASSETS_URL + 'K.png'} />
                    </div>
                    <div className='casino-book '>
                      {getExposureForEntity('card K')?.exposure}
                    </div>
                  </div>
                  <div
                    onClick={() => setEvent('card A')}
                    className='casino-card-item'
                  >
                    <div
                      className={`card-image ${isLocked ? 'suspended' : ''}`}
                    >
                      <img src={ASSETS_URL + 'A.png'} />
                    </div>
                    <div className='casino-book '>
                      {getExposureForEntity('card A')?.exposure}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LastResults eventId={gameName} />
      </Box>
    </Box>
  );
}
