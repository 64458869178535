// import {
//   Box,
//   Button,
//   Flex,
//   Grid,
//   GridItem,
//   Table,
//   Tbody,
//   Td,
//   Text,
//   Image,
//   Th,
//   Thead,
//   Tr,
//   useToast,
//   WrapItem,
// } from "@chakra-ui/react";
// import React, { useEffect, useState } from "react";
// import { FaLock } from "react-icons/fa";
// import { useDispatch, useSelector } from "react-redux";
// import { Flip, Flipr } from "../../../components/Flip";
// import {
//   getCasinoPlExposureAPI,
//   placeCasinoBetAPI,
// } from "../../../service/casino";
// // import { getCasinoLastTenResultsAPI } from "../../../service/casino";
// import "./css/style.css";
// import CardDrawer from "../../../components/CardDrawer/CardDrawer";
// import { ASSETS_URL } from "../../../utils/constants";
// import CasinoVideoRules from "../../../components/CasinoVideoRules";
// import { useMemo } from "react";
// import Iframe from "react-iframe";
// import IframeComponent from "../../../components/Iframe";

// export default function DragonTiger202() {
//   const odds_ = useSelector((state) => state.gameData.dragonTiger202Odds);
//   //useMemo
//   const odds = useMemo(() => odds_, [odds_]);
//   const [lastTenResult, setLastTenResult] = useState([]);
//   const [plExposure, setPlExposure] = useState(null);
//   const [selectedAmount, setSelectedAmount] = useState(0);
//   const [isLocked, setIsLocked] = useState(false);
//   const userData = useSelector((state) => state.account.userData);
//   const toast = useToast();
//   const dispatch = useDispatch();
//   const gameName = "DRAGON_TIGER_20_2";
//   const tigerCard = [
//     {
//       id: "Tiger Card 1",
//       img: "A.png",
//     },
//     {
//       id: "Tiger Card 2",
//       img: "2.png",
//     },
//     {
//       id: "Tiger Card 3",
//       img: "3.png",
//     },
//     {
//       id: "Tiger Card 4",
//       img: "4.png",
//     },
//     {
//       id: "Tiger Card 5",
//       img: "5.png",
//     },
//     {
//       id: "Tiger Card 6",
//       img: "6.png",
//     },
//     {
//       id: "Tiger Card 7",
//       img: "7.png",
//     },
//     {
//       id: "Tiger Card 8",
//       img: "8.png",
//     },
//     {
//       id: "Tiger Card 9",
//       img: "9.png",
//     },
//     {
//       id: "Tiger Card 10",
//       img: "10.png",
//     },
//     {
//       id: "Tiger Card J",
//       img: "J.png",
//     },
//     {
//       id: "Tiger Card Q",
//       img: "Q.png",
//     },
//     {
//       id: "Tiger Card K",
//       img: "K.png",
//     },
//   ];
//   const dragonCard = [
//     {
//       id: "Dragon Card 1",
//       img: "A.png",
//     },
//     {
//       id: "Dragon Card 2",
//       img: "2.png",
//     },
//     {
//       id: "Dragon Card 3",
//       img: "3.png",
//     },
//     {
//       id: "Dragon Card 4",
//       img: "4.png",
//     },
//     {
//       id: "Dragon Card 5",
//       img: "5.png",
//     },
//     {
//       id: "Dragon Card 6",
//       img: "6.png",
//     },
//     {
//       id: "Dragon Card 7",
//       img: "7.png",
//     },
//     {
//       id: "Dragon Card 8",
//       img: "8.png",
//     },
//     {
//       id: "Dragon Card 9",
//       img: "9.png",
//     },
//     {
//       id: "Dragon Card 10",
//       img: "10.png",
//     },
//     {
//       id: "Dragon Card J",
//       img: "J.png",
//     },
//     {
//       id: "Dragon Card Q",
//       img: "Q.png",
//     },
//     {
//       id: "Dragon Card K",
//       img: "K.png",
//     },
//   ];
//   const CustomButton = ({ text }) => (
//     <Button size="sm" my="2" className="casinoButton">
//       {isLocked ? <FaLock /> : text}
//     </Button>
//   );
//   const getData = (id) => {
//     let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
//     return data ? data : {};
//   };

//   const getProfit = (selectedEventForBet) => {
//     return (selectedEventForBet.back1 - 1) * selectedAmount;
//   };
//   const setEvent = (id) => {
//     if (isLocked) return;
//     if (!userData.token) {
//       dispatch({
//         type: "accountData/setLoginPopSate",
//         payload: true,
//       });
//       return;
//     }
//     let selectedEventForBet = getData(id);
//     let payload = {};
//     payload.amount = parseInt(selectedAmount);
//     payload.casinoGames = gameName;
//     payload.profit = getProfit(selectedEventForBet);
//     payload.loss = parseInt(selectedAmount);
//     payload.selectedOdd = selectedEventForBet.back1;
//     payload.selectedId = selectedEventForBet.selectionId;
//     payload.marketId = odds.marketId;
//     payload.runnerName = selectedEventForBet.runnerName;
//     payload.type = "back";

//     dispatch({
//       type: "gameData/setSelectedEventForBet",
//       payload,
//     });
//   };
//   useEffect(() => {
//     if (odds.autoTime <= 5) {
//       setIsLocked(true);
//     } else {
//       setIsLocked(false);
//     }
//   }, [odds, getData]);

//   const getExposureForEntity = (selectionName, selectionId) => {
//     if (plExposure) {
//       let data;
//       for (let i = 0; i < plExposure.length; i++) {
//         data = plExposure[i]?.casinoPLExposure?.find(
//           (item) => item.selectionName == selectionName
//         );
//         if (data) {
//           return data;
//         }
//       }

//       return data ? data : null;
//     }
//   };
//   const getPlExposure = async (id) => {
//     const { response, code } = await getCasinoPlExposureAPI(id);
//     if (code == 200) {
//       setPlExposure(response);
//     }
//   };
//   useEffect(() => {
//     let interval = setInterval(() => {
//       if (odds?.marketId) {
//         getPlExposure(odds?.marketId);
//       }
//     }, 2000);
//     return () => clearInterval(interval);
//   }, [odds?.marketId]);

//   return (
//     <Box
//       display="flex"
//       flexDirection={{ base: "column", sm: "column", lg: "row" }}
//     >
//       <Box mr="1" w={{ base: "100%", sm: "100%", lg: "70%" }}>
//         <Box className="casino-video" h="50vh" mb="2" pos="relative">
//           {odds.cards && <CardDrawer cards={odds?.cards[0]} />}
//           <div class="market-title">20-20 DRAGON TIGER 2(RULES)</div>

//           <IframeComponent
//             url={`${process.env.REACT_APP_IFRAME_URL}/route/?id=3059`}
//           />
//           <Box
//             bottom="0"
//             p="2"
//             pos="absolute"
//             right="2"
//             display="flex"
//             bg="black"
//           >
//             <Flip value={odds?.autoTime} />
//           </Box>
//           <CasinoVideoRules />
//         </Box>
//         <Grid className="grid-btn-group">
//           <Flex height="80px" className="flex-group-btn">
//             <Flex
//               className={`dt2-dragonButton `}
//               onClick={() => setEvent("Dragon")}
//               flexDirection="column"
//               alignItems="flex-start"
//               justifyContent="center"
//             >
//               <Text>Dragon</Text>
//               {isLocked ? (
//                 <FaLock />
//               ) : (
//                 <Text fontWeight="bold">{getData("Dragon").back1}</Text>
//               )}
//               {getExposureForEntity("Dragon")?.exposure}
//             </Flex>
//             <Flex
//               flexDirection="column"
//               alignItems="center"
//               justifyContent="center"
//               className={`dt2-tieButton `}
//               onClick={() => setEvent("Tie")}
//             >
//               <Text>Tie</Text>
//               {isLocked ? (
//                 <FaLock />
//               ) : (
//                 <Text fontWeight="bold">{getData("Tie").back1}</Text>
//               )}
//               {getExposureForEntity("Tie")?.exposure}
//             </Flex>
//             <Flex
//               className={`dt2-tigerButton `}
//               onClick={() => setEvent("Tiger")}
//               flexDirection="column"
//               alignItems="flex-start"
//               justifyContent="center"
//             >
//               <Text>Tiger</Text>
//               {isLocked ? (
//                 <FaLock />
//               ) : (
//                 <Text fontWeight="bold">{getData("Tiger").back1}</Text>
//               )}
//               {getExposureForEntity("Tiger")?.exposure}
//             </Flex>
//             <Flex
//               className={`dt2-pairButton `}
//               marginLeft="10px"
//               onClick={() => setEvent("Pair")}
//               flexDirection="column"
//               alignItems="flex-start"
//               justifyContent="center"
//             >
//               <Text>Pair</Text>
//               {isLocked ? (
//                 <FaLock />
//               ) : (
//                 <Text fontWeight="bold">{getData("Pair").back1}</Text>
//               )}
//               {getExposureForEntity("Pair")?.exposure}
//             </Flex>
//           </Flex>
//           <Grid
//             mt="2"
//             gap={2}
//             className="grid-mobile-size"
//             templateColumns="repeat(2,1fr)"
//             height="140px"
//           >
//             <GridItem p="2">
//               <Text fontWeight="bold" color="red" textDecoration="underline">
//                 Dragon{" "}
//               </Text>
//               <Grid
//                 gap={2}
//                 templateColumns="repeat(4,1fr)"
//                 className="dt-20-grid-odds-main"
//               >
//                 <GridItem
//                   onClick={() => setEvent("Dragon Even")}
//                   p="2"
//                   textAlign="center"
//                   className="dt-20-odds-col"
//                 >
//                   <Text fontWeight="bold">{getData("Dragon Even").back1}</Text>
//                   <CustomButton text="EVEN" />
//                   {getExposureForEntity("Dragon Even")?.exposure}
//                 </GridItem>
//                 <GridItem
//                   onClick={() => setEvent("Dragon Odd")}
//                   p="2"
//                   textAlign="center"
//                   className="dt-20-odds-col"
//                 >
//                   <Text fontWeight="bold">{getData("Dragon Odd").back1}</Text>
//                   <CustomButton text="ODD" />
//                   {getExposureForEntity("Dragon Odd")?.exposure}
//                 </GridItem>
//                 <GridItem
//                   onClick={() => setEvent("Dragon Black")}
//                   p="2"
//                   textAlign="center"
//                   className="dt-20-odds-col"
//                 >
//                   <Text fontWeight="bold">{getData("Dragon Black").back1}</Text>
//                   <CustomButton
//                     text={
//                       <div className="black-images">
//                         <img src={ASSETS_URL + "spade.png"} />
//                         <img src={ASSETS_URL + "club.png"} />
//                       </div>
//                     }
//                   />
//                   {getExposureForEntity("Dragon Black")?.exposure}
//                 </GridItem>
//                 <GridItem
//                   onClick={() => setEvent("Dragon Red")}
//                   p="2"
//                   textAlign="center"
//                   className="dt-20-odds-col"
//                 >
//                   <Text fontWeight="bold">{getData("Dragon Red").back1}</Text>
//                   <CustomButton
//                     text={
//                       <div className="black-images">
//                         <img src={ASSETS_URL + "heart.png"} />
//                         <img src={ASSETS_URL + "diamond.png"} />
//                       </div>
//                     }
//                   />
//                   {getExposureForEntity("Dragon Red")?.exposure}
//                 </GridItem>
//               </Grid>
//               <Text display={{ base: "block", md: "none" }} fontSize="sm">
//                 {getData("Dragon Card 1").back1}
//               </Text>
//               <Grid
//                 display={{ base: "grid", md: "none" }}
//                 pt="2"
//                 templateColumns="repeat(8,1fr)"
//                 className="grid-col"
//               >
//                 {dragonCard.map(({ id, img }, i) => (
//                   <GridItem
//                     display="flex"
//                     flexDirection="column"
//                     alignItems="center"
//                     justifyContent="center"
//                     pos="relative"
//                     onClick={() => setEvent(id)}
//                     p="1"
//                     textAlign="center"
//                     cursor="pointer"
//                     className={`${isLocked ? "suspended" : ""} card-col`}
//                   >
//                     <img className="cardimg" src={ASSETS_URL + img} />
//                   </GridItem>
//                 ))}
//               </Grid>
//             </GridItem>
//             <GridItem p="2" className="grid-item-two" borderLeft="1px">
//               <Text fontWeight="bold" color="yellow" textDecoration="underline">
//                 Tiger{" "}
//               </Text>
//               <Grid
//                 gap={2}
//                 templateColumns="repeat(4,1fr)"
//                 className="dt-20-grid-odds-main"
//               >
//                 <GridItem
//                   onClick={() => setEvent("Tiger Even")}
//                   p="2"
//                   textAlign="center"
//                   className="dt-20-odds-col"
//                 >
//                   <Text fontWeight="bold">{getData("Tiger Even").back1}</Text>
//                   <CustomButton text="EVEN" />
//                   {getExposureForEntity("Tiger Even")?.exposure}
//                 </GridItem>
//                 <GridItem
//                   onClick={() => setEvent("Tiger Odd")}
//                   p="2"
//                   textAlign="center"
//                   className="dt-20-odds-col"
//                 >
//                   <Text fontWeight="bold">{getData("Tiger Odd").back1}</Text>
//                   <CustomButton text="ODD" />
//                   {getExposureForEntity("Tiger Odd")?.exposure}
//                 </GridItem>
//                 <GridItem
//                   onClick={() => setEvent("Tiger Black")}
//                   p="2"
//                   textAlign="center"
//                   className="dt-20-odds-col"
//                 >
//                   <Text fontWeight="bold">{getData("Tiger Black").back1}</Text>
//                   <CustomButton
//                     text={
//                       <div className="black-images">
//                         <img src={ASSETS_URL + "spade.png"} />
//                         <img src={ASSETS_URL + "club.png"} />
//                       </div>
//                     }
//                   />
//                   {getExposureForEntity("Tiger Black")?.exposure}
//                 </GridItem>
//                 <GridItem
//                   onClick={() => setEvent("Tiger Red")}
//                   p="2"
//                   textAlign="center"
//                   className="dt-20-odds-col"
//                 >
//                   <Text fontWeight="bold">{getData("Tiger Red").back1}</Text>
//                   <CustomButton
//                     text={
//                       <div className="black-images">
//                         <img src={ASSETS_URL + "heart.png"} />
//                         <img src={ASSETS_URL + "diamond.png"} />
//                       </div>
//                     }
//                   />
//                   {getExposureForEntity("Tiger Red")?.exposure}
//                 </GridItem>
//               </Grid>
//               <Text display={{ base: "block", md: "none" }} fontSize="sm">
//                 {getData("Tiger Card 1").back1}
//               </Text>
//               <Grid
//                 display={{ base: "grid", md: "none" }}
//                 pt="2"
//                 templateColumns="repeat(8,1fr)"
//                 className="grid-col"
//               >
//                 {tigerCard.map(({ id, img }, i) => (
//                   <GridItem
//                     display="flex"
//                     flexDirection="column"
//                     alignItems="center"
//                     justifyContent="center"
//                     pos="relative"
//                     onClick={() => setEvent(id)}
//                     p="1"
//                     textAlign="center"
//                     className={`${isLocked ? "suspended" : ""} card-col`}
//                   >
//                     <img className="cardimg" src={ASSETS_URL + img} />
//                   </GridItem>
//                 ))}
//               </Grid>
//             </GridItem>
//           </Grid>
//           <Grid
//             className="card-grid-main"
//             display={{ base: "none", md: "grid" }}
//             gap={2}
//             templateColumns="repeat(2,1fr)"
//           >
//             <GridItem p="4" textAlign="center">
//               <Text fontSize="sm">{12}</Text>
//               <Box pt="2" className="grid-col">
//                 {dragonCard.map(({ id, img }) => (
//                   <Box>
//                     <Box
//                       display="flex"
//                       flexDirection="column"
//                       alignItems="center"
//                       justifyContent="center"
//                       pos="relative"
//                       onClick={() => setEvent(id)}
//                       p="1"
//                       textAlign="center"
//                       cursor="pointer"
//                       className={`${isLocked ? "suspended" : ""} card-col`}
//                     >
//                       <img className="cardimg" src={ASSETS_URL + img} />
//                     </Box>
//                     <Text className="exposure-text">
//                       {getExposureForEntity(id)?.selectionName === id &&
//                         getExposureForEntity(id)?.exposure}
//                     </Text>
//                   </Box>
//                 ))}
//               </Box>
//             </GridItem>
//             <GridItem p="4" textAlign="center" borderLeft="1px">
//               <Text fontSize="sm">{12}</Text>
//               <Box pt="2" className="grid-col">
//                 {tigerCard.map(({ id, img }) => (
//                   <Box>
//                     <Box
//                       display="flex"
//                       flexDirection="column"
//                       alignItems="center"
//                       justifyContent="center"
//                       pos="relative"
//                       onClick={() => setEvent(id)}
//                       p="1"
//                       textAlign="center"
//                       className={`${isLocked ? "suspended" : ""} card-col`}
//                     >
//                       <img className="cardimg" src={ASSETS_URL + img} />
//                     </Box>
//                     <Text className="exposure-text">
//                       {getExposureForEntity(id)?.selectionName === id &&
//                         getExposureForEntity(id)?.exposure}
//                     </Text>
//                   </Box>
//                 ))}
//               </Box>
//             </GridItem>
//           </Grid>
//         </Grid>
//       </Box>

//       <PlaceBet game="casino" eventId={gameName} />
//     </Box>
//   );
// }

import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Table,
  Tbody,
  Td,
  Text,
  Image,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Flip, Flipr } from '../../../components/Flip';
import {
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from '../../../service/casino';

import { getCasinoLastTenResultsAPI } from '../../../service/casino';
import './css/style.css';
import CardDrawer from '../../../components/CardDrawer/CardDrawer';
import { ASSETS_URL } from '../../../utils/constants';
import CasinoVideoRules from '../../../components/CasinoVideoRules';
import { useMemo } from 'react';
import Iframe from 'react-iframe';
import IframeComponent from '../../../components/Iframe';
import LastResults from '../../../components/LastResults';
import { setGameKey } from '../../../redux/features/gameSlice';
import { getService } from '../../../utils/commonFunctions';
import { useLocation } from 'react-router-dom';

export default function DragonTiger202() {
  const location = useLocation();
  const odds_ = useSelector((state) => state.gameData.dragonTiger202Odds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [lastTenResult, setLastTenResult] = useState([]);
  const [plExposure, setPlExposure] = useState(null);
  const authDetails = useSelector((state) => state.account.authDetails);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const toast = useToast();
  const dispatch = useDispatch();
  const gameName = 'DRAGON_TIGER_20_2';
  const tigerCard = [
    {
      id: 'Tiger Card A',
      img: 'A.png',
    },
    {
      id: 'Tiger Card 2',
      img: '2.png',
    },
    {
      id: 'Tiger Card 3',
      img: '3.png',
    },
    {
      id: 'Tiger Card 4',
      img: '4.png',
    },
    {
      id: 'Tiger Card 5',
      img: '5.png',
    },
    {
      id: 'Tiger Card 6',
      img: '6.png',
    },
    {
      id: 'Tiger Card 7',
      img: '7.png',
    },
    {
      id: 'Tiger Card 8',
      img: '8.png',
    },
    {
      id: 'Tiger Card 9',
      img: '9.png',
    },
    {
      id: 'Tiger Card 10',
      img: '10.png',
    },
    {
      id: 'Tiger Card J',
      img: 'J.png',
    },
    {
      id: 'Tiger Card Q',
      img: 'Q.png',
    },
    {
      id: 'Tiger Card K',
      img: 'K.png',
    },
  ];
  const dragonCard = [
    {
      id: 'Dragon Card A',
      img: 'A.png',
    },
    {
      id: 'Dragon Card 2',
      img: '2.png',
    },
    {
      id: 'Dragon Card 3',
      img: '3.png',
    },
    {
      id: 'Dragon Card 4',
      img: '4.png',
    },
    {
      id: 'Dragon Card 5',
      img: '5.png',
    },
    {
      id: 'Dragon Card 6',
      img: '6.png',
    },
    {
      id: 'Dragon Card 7',
      img: '7.png',
    },
    {
      id: 'Dragon Card 8',
      img: '8.png',
    },
    {
      id: 'Dragon Card 9',
      img: '9.png',
    },
    {
      id: 'Dragon Card 10',
      img: '10.png',
    },
    {
      id: 'Dragon Card J',
      img: 'J.png',
    },
    {
      id: 'Dragon Card Q',
      img: 'Q.png',
    },
    {
      id: 'Dragon Card K',
      img: 'K.png',
    },
  ];
  const CustomButton = ({ text }) => (
    <Button size='sm' my='2' className='casinoButton'>
      {isLocked ? <FaLock /> : text}
    </Button>
  );
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    console.log(data, 'testinggg');
    return data ? data : {};
  };

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id) => {
    if (isLocked) return;
    if (!userData.token) {
      dispatch({
        type: 'accountData/setLoginPopSate',
        payload: true,
      });
      return;
    }
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = selectedEventForBet.back1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = 'back';

    dispatch({
      type: 'gameData/setSelectedEventForBet',
      payload,
    });
  };
  useEffect(() => {
    if (odds.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);

  useEffect(() => {
    dispatch(setGameKey('dragonTiger202'));
  }, [dispatch]);

  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data;
      for (let i = 0; i < plExposure.length; i++) {
        data = plExposure[i]?.casinoPLExposure?.find(
          (item) => item.selectionName == selectionName
        );
        if (data) {
          return data;
        }
      }

      return data ? data : null;
    }
  };
  const getPlExposure = async (id) => {
    const { response, code } = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id,
      authDetails?.uid
    );
    if (code == 200) {
      setPlExposure(response);
    }
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);

  return (
    <Box
      display='flex'
      flexDirection={{ base: 'column', sm: 'column', lg: 'row' }}
    >
      <Box mr='1' w={{ base: '100%', sm: '100%', lg: '70%' }}>
        <Box className='casino-video' mb='2' pos='relative'>
          {odds.cards && <CardDrawer cards={odds?.cards[0]} />}
          <div class='market-title'>20-20 DRAGON TIGER 2 </div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3059`}
          />
          <Box
            bottom='0'
            p='2'
            pos='absolute'
            right='2'
            display='flex'
            bg='black'
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules
            image={`${ASSETS_URL}dragon-tiger-20%20%20%20%20%20%202-rules.jpg`}
          />
        </Box>
        <Grid className='grid-btn-group dt-container'>
          <div className='d-t-box m-b-10 m-t-10'>
            <div className='row'>
              <div className='col-md-9'>
                <div className='row'>
                  <div className='col-md-5 text-center'>
                    <b>{getData('Dragon').back1}</b>
                    <div className='m-t-10'>
                      <button
                        className={`text-uppercase btn-theme low-high-btn ${
                          isLocked ? 'suspended' : ''
                        }`}
                        onClick={() => setEvent('Dragon')}
                      >
                        <b>Dragon</b>
                      </button>{' '}
                      <span className='d-block m-t-5'>
                        {getExposureForEntity('Dragon')?.exposure}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-2 text-center'>
                    <b>{getData('Tie').back1}</b>
                    <div className='m-t-10'>
                      <button
                        className={`text-uppercase  btn-theme low-high-btn ${
                          isLocked ? 'suspended' : ''
                        }`}
                        onClick={() => setEvent('Tie')}
                      >
                        <b>Tie</b>
                      </button>{' '}
                      <span className='d-block m-t-5'>
                        {getExposureForEntity('Tie')?.exposure}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-5 text-center'>
                    <b>{getData('Tiger').back1}</b>
                    <div className='m-t-10'>
                      <button
                        className={`text-uppercase btn-theme low-high-btn ${
                          isLocked ? 'suspended' : ''
                        }`}
                        onClick={() => setEvent('Tiger')}
                      >
                        <b>Tiger</b>
                      </button>{' '}
                      <span className='d-block m-t-5'>
                        {getExposureForEntity('Tiger')?.exposure}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-3 pair-block  text-center'>
                <b>{getData('Pair').back1}</b>
                <div className='m-t-10'>
                  <button
                    className={`text-uppercase btn-theme low-high-btn ${
                      isLocked ? 'suspended' : ''
                    }`}
                    onClick={() => setEvent('Pair')}
                  >
                    <b>Pair</b>
                  </button>{' '}
                  <span className='d-block m-t-5'>
                    {getExposureForEntity('Pair')?.exposure}
                  </span>
                </div>
              </div>
              <div className='col-md-12 m-t-10 text-right'>
                <span className='m-r-10'>
                  <b>Min: </b>
                  <span>100</span>
                </span>{' '}
                <span>
                  <b>Max: </b>
                  <span>300000</span>
                </span>
              </div>
            </div>
          </div>
          <div className='row row5'>
            <div className='col-md-6'>
              <div className='d-t-box m-b-10'>
                <div className='row '>
                  <div className='col-12'>
                    <h4 className='m-b-0 text-center text-uppercase'>Dragon</h4>
                  </div>
                  <div
                    className='col-md-6 text-center'
                    style={{ height: '90px' }}
                  >
                    <b>{getData('Dragon Even').back1}</b>
                    <div className='m-t-10'>
                      <button
                        onClick={() => setEvent('Dragon Even')}
                        className={`text-uppercase btn-theme low-high-btn ${
                          isLocked ? 'suspended' : ''
                        }`}
                      >
                        <b>Even</b>
                      </button>{' '}
                      <span className='d-block m-t-5'>
                        {getExposureForEntity('Dragon Even')?.exposure}
                      </span>
                    </div>
                  </div>
                  <div
                    className='col-md-6 text-center'
                    style={{ height: '90px' }}
                  >
                    <b>{getData('Dragon Odd').back1}</b>
                    <div className='m-t-10'>
                      <button
                        className={`text-uppercase btn-theme low-high-btn ${
                          isLocked ? 'suspended' : ''
                        }`}
                        onClick={() => setEvent('Dragon Odd')}
                      >
                        <b>Odd</b>
                      </button>{' '}
                      <span className='d-block m-t-5'>
                        {getExposureForEntity('Dragon Odd')?.exposure}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-12 m-t-10 text-right'>
                    <span className='m-r-10'>
                      <b>Min:</b>
                      <span>100</span>
                    </span>{' '}
                    <span>
                      <b>Max: </b>
                      <span>25000</span>
                    </span>
                  </div>
                  <div
                    className='col-md-6 text-center m-t-10'
                    style={{ height: '90px' }}
                  >
                    <b>{getData('Dragon Red').back1}</b>
                    <div className='m-t-10'>
                      <button
                        onClick={() => setEvent('Dragon Red')}
                        className={`text-uppercase btn-theme low-high-btn ${
                          isLocked ? 'suspended' : ''
                        }`}
                      >
                        <div className='color-card'></div>
                        <span className='card-icon'>
                          <span className='card-red'>
                            {' '}
                            <img src={ASSETS_URL + 'heart.png'} />
                          </span>
                        </span>{' '}
                        <span className='card-icon'>
                          <span className='card-red'>
                            {' '}
                            <img src={ASSETS_URL + 'diamond.png'} />
                          </span>
                        </span>
                      </button>
                      <span className='d-block m-t-5'>
                        {getExposureForEntity('Dragon Red')?.exposure}
                      </span>
                    </div>
                  </div>
                  <div
                    className='col-md-6 text-center m-t-10'
                    style={{ height: '90px' }}
                  >
                    <b>{getData('Dragon Black').back1}</b>
                    <div className='m-t-10'>
                      <button
                        onClick={() => setEvent('Dragon Black')}
                        className={`text-uppercase btn-theme low-high-btn ${
                          isLocked ? 'suspended' : ''
                        }`}
                      >
                        <div className='color-card'></div>
                        <span className='card-icon'>
                          <span className='card-black'>
                            {' '}
                            <img src={ASSETS_URL + 'spade.png'} />
                          </span>
                        </span>{' '}
                        <span className='card-icon'>
                          <span className='card-black'>
                            {' '}
                            <img src={ASSETS_URL + 'club.png'} />
                          </span>
                        </span>
                      </button>
                      <span className='d-block m-t-5'>
                        {getExposureForEntity('Dragon Black')?.exposure}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-12 m-t-10 text-right'>
                    <span className='m-r-10'>
                      <b>Min:</b>
                      <span>100</span>
                    </span>{' '}
                    <span>
                      <b>Max: </b>
                      <span>25000</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='d-t-box m-b-10'>
                <div className='row '>
                  <div className='col-12'>
                    <h4 className='m-b-0 text-center text-uppercase'>Tiger</h4>
                  </div>
                  <div
                    className='col-md-6 text-center'
                    style={{ height: '90px' }}
                  >
                    <b>{getData('Tiger Even').back1}</b>
                    <div className='m-t-10'>
                      <button
                        onClick={() => setEvent('Tiger Even')}
                        className={`text-uppercase btn-theme low-high-btn ${
                          isLocked ? 'suspended' : ''
                        }`}
                      >
                        <b>Even</b>
                      </button>{' '}
                      <span className='d-block m-t-5'>
                        {getExposureForEntity('Tiger Even')?.exposure}
                      </span>
                    </div>
                  </div>
                  <div
                    className='col-md-6 text-center'
                    style={{ height: '90px' }}
                  >
                    <b>{getData('Tiger Odd').back1}</b>
                    <div className='m-t-10'>
                      <button
                        onClick={() => setEvent('Tiger Odd')}
                        className={`text-uppercase btn-theme low-high-btn ${
                          isLocked ? 'suspended' : ''
                        }`}
                      >
                        <b>Odd</b>
                      </button>{' '}
                      <span className='d-block m-t-5'>
                        {getExposureForEntity('Tiger Odd')?.exposure}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-12 m-t-10 text-right'>
                    <span className='m-r-10'>
                      <b>Min:</b>
                      <span>100</span>
                    </span>{' '}
                    <span>
                      <b>Max: </b>
                      <span>25000</span>
                    </span>
                  </div>
                  <div
                    className='col-md-6 text-center m-t-10'
                    style={{ height: '90px' }}
                  >
                    <b>{getData('Tiger Red').back1}</b>
                    <div className='m-t-10'>
                      <button
                        onClick={() => setEvent('Tiger Red')}
                        className={`text-uppercase btn-theme low-high-btn ${
                          isLocked ? 'suspended' : ''
                        }`}
                      >
                        <div className='color-card'></div>
                        <span className='card-icon'>
                          <span className='card-red'>
                            {' '}
                            <img src={ASSETS_URL + 'heart.png'} />
                          </span>
                        </span>{' '}
                        <span className='card-icon'>
                          <span className='card-red'>
                            {' '}
                            <img src={ASSETS_URL + 'diamond.png'} />
                          </span>
                        </span>
                      </button>
                      <span className='d-block m-t-5'>
                        {getExposureForEntity('Tiger Red')?.exposure}
                      </span>
                    </div>
                  </div>
                  <div
                    className='col-md-6 text-center m-t-10'
                    style={{ height: '90px' }}
                  >
                    <b>{getData('Tiger Black').back1}</b>
                    <div className='m-t-10'>
                      <button
                        onClick={() => setEvent('Tiger Black')}
                        className={`text-uppercase btn-theme low-high-btn ${
                          isLocked ? 'suspended' : ''
                        }`}
                      >
                        <div className='color-card'></div>
                        <span className='card-icon'>
                          <span className='card-black'>
                            {' '}
                            <img src={ASSETS_URL + 'spade.png'} />
                          </span>
                        </span>{' '}
                        <span className='card-icon'>
                          <span className='card-black'>
                            {' '}
                            <img src={ASSETS_URL + 'club.png'} />
                          </span>
                        </span>
                      </button>
                      <span className='d-block m-t-5'>
                        {getExposureForEntity('Tiger Black')?.exposure}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-12 m-t-10 text-right'>
                    <span className='m-r-10'>
                      <b>Min:</b>
                      <span>100</span>
                    </span>{' '}
                    <span>
                      <b>Max: </b>
                      <span>25000</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row row5'>
            <div className='col-md-6'>
              <div className='d-t-box m-b-10'>
                <div className='row '>
                  <div className='col-md-12'>
                    <h4 className='m-b-0 text-center text-uppercase'>
                      Dragon {getData('Dragon Card A').back1}
                    </h4>
                  </div>
                  <div className='card-dt col-md-12 text-center m-t-10 d-flex flex-wrap justify-content-center'>
                    {dragonCard.map(({ id, img }, i) => (
                      <>
                        <div
                          onClick={() => setEvent(id)}
                          className=' m-r-5 card-image d-inline-block'
                        >
                          <div className={`${isLocked ? 'suspended' : ''}`}>
                            <img src={ASSETS_URL + img} />
                          </div>
                          <div className='ubook text-center m-t-5'>
                            <b>
                              {' '}
                              {getExposureForEntity(id)?.selectionName === id &&
                                getExposureForEntity(id)?.exposure}
                            </b>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                  <div className='col-md-12 m-t-10 text-right'>
                    <span className='m-r-10'>
                      <b>Min:</b>
                      <span>100</span>
                    </span>{' '}
                    <span>
                      <b>Max: </b>
                      <span>25000</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='d-t-box m-b-10'>
                <div className='row'>
                  <div className='col-md-12'>
                    <h4 className='m-b-0 text-center text-uppercase'>
                      Tiger {getData('Tiger Card A').back1}
                    </h4>
                  </div>
                  <div className='card-dt col-md-12 text-center m-t-10 d-flex flex-wrap justify-content-center'>
                    {tigerCard.map(({ id, img }, i) => (
                      <>
                        <div
                          onClick={() => setEvent(id)}
                          className=' m-r-5 card-image d-inline-block'
                        >
                          <div className={`${isLocked ? 'suspended' : ''}`}>
                            <img src={ASSETS_URL + img} />
                          </div>
                          <div className='ubook text-center m-t-5'>
                            <b>
                              {' '}
                              {getExposureForEntity(id)?.selectionName === id &&
                                getExposureForEntity(id)?.exposure}
                            </b>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                  <div className='col-md-12 m-t-10 text-right'>
                    <span className='m-r-10'>
                      <b>Min:</b>
                      <span>100</span>
                    </span>{' '}
                    <span>
                      <b>Max: </b>
                      <span>25000</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <LastResults eventId={gameName} />
      </Box>
    </Box>
  );
}
