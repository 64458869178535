import React from "react";

export default function GameReport() {
  return (
    <div className="main">
      <div className="container-fluid">
        <div className="row">
          <div className="sidebar">
            <nav>
              <h2>Sports</h2>
              <i className="fa fa-times" />
              <ul className="mtree transit bubba">
                <li className="mtree-node mtree-closed">
                  <a href="#" style={{ cursor: "pointer" }}>
                    Football
                  </a>
                  <ul
                    id="tree_soccer"
                    className="mtree-level-1"
                    style={{
                      overflow: "hidden",
                      height: "0px",
                      display: "none",
                    }}
                  >
                    {" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32131659&marketId=1.210399556"
                        style={{ cursor: "pointer" }}
                      >
                        Norwich U21 v Middlesbrough U21
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32132982&marketId=1.210416859"
                        style={{ cursor: "pointer" }}
                      >
                        FK Kukesi v Partizani Tirana
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32132554&marketId=1.210411335"
                        style={{ cursor: "pointer" }}
                      >
                        Al Merreikh v Belouizdad
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32131760&marketId=1.210400006"
                        style={{ cursor: "pointer" }}
                      >
                        Watford U21 v Swansea U21
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32131686&marketId=1.210397203"
                        style={{ cursor: "pointer" }}
                      >
                        Al Hidd v East Riffa
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32131708&marketId=1.210397113"
                        style={{ cursor: "pointer" }}
                      >
                        Al Khalidiyah v Manama Club
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32128041&marketId=1.210342359"
                        style={{ cursor: "pointer" }}
                      >
                        NK Hrvatski Dragovoljac v Vukovar
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32125704&marketId=1.210308674"
                        style={{ cursor: "pointer" }}
                      >
                        Vojvodina v Radnik Surdulica
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32104101&marketId=1.209965181"
                        style={{ cursor: "pointer" }}
                      >
                        FC U Craiova 1948 v CS Mioveni
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32123972&marketId=1.210286557"
                        style={{ cursor: "pointer" }}
                      >
                        Sibenik v NK Istra
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32132597&marketId=1.210411155"
                        style={{ cursor: "pointer" }}
                      >
                        Coton S De Garoua v Al Hilal Omdurman
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32125706&marketId=1.210308764"
                        style={{ cursor: "pointer" }}
                      >
                        Cukaricki v Kolubara Lazarevac
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32117379&marketId=1.210181380"
                        style={{ cursor: "pointer" }}
                      >
                        Jagiellonia Bialystock v Rakow Czestochowa
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32110760&marketId=1.210088254"
                        style={{ cursor: "pointer" }}
                      >
                        Resovia Rzeszow v Gornik Leczna
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32117308&marketId=1.210179075"
                        style={{ cursor: "pointer" }}
                      >
                        Admira Wacker v SV Lafnitz
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32117307&marketId=1.210179165"
                        style={{ cursor: "pointer" }}
                      >
                        First Vienna Fc 1894 v FC Liefering
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32117306&marketId=1.210179255"
                        style={{ cursor: "pointer" }}
                      >
                        Rapid Vienna (Am) v Floridsdorfer AC
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32117305&marketId=1.210179345"
                        style={{ cursor: "pointer" }}
                      >
                        SV Horn v Grazer AK
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32119509&marketId=1.210215038"
                        style={{ cursor: "pointer" }}
                      >
                        HB Koge v Vejle
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32118447&marketId=1.210198414"
                        style={{ cursor: "pointer" }}
                      >
                        Fortuna Dusseldorf v Braunschweig
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32118455&marketId=1.210198305"
                        style={{ cursor: "pointer" }}
                      >
                        Karlsruhe v Jahn Regensburg
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32122855&marketId=1.210263948"
                        style={{ cursor: "pointer" }}
                      >
                        Fremad Amager v Hobro
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32122853&marketId=1.210263641"
                        style={{ cursor: "pointer" }}
                      >
                        FC Nordsjaelland v OB
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32123566&marketId=1.210274632"
                        style={{ cursor: "pointer" }}
                      >
                        Hallescher FC v 1860 Munich
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32114876&marketId=1.210153452"
                        style={{ cursor: "pointer" }}
                      >
                        NFC Volos v Lamia
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32122850&marketId=1.210263461"
                        style={{ cursor: "pointer" }}
                      >
                        SonderjyskE v Fredericia
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32132807&marketId=1.210412420"
                        style={{ cursor: "pointer" }}
                      >
                        Aston Villa U21 v Sunderland U21
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32123620&marketId=1.210274105"
                        style={{ cursor: "pointer" }}
                      >
                        CD Trofense v Penafiel
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32104096&marketId=1.209965937"
                        style={{ cursor: "pointer" }}
                      >
                        UTA Arad v Farul Constanta
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32117390&marketId=1.210182064"
                        style={{ cursor: "pointer" }}
                      >
                        Sparta Rotterdam v FC Utrecht
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32104030&marketId=1.209964460"
                        style={{ cursor: "pointer" }}
                      >
                        Mainz v Mgladbach
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32122770&marketId=1.210262540"
                        style={{ cursor: "pointer" }}
                      >
                        Frosinone v Parma
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32122764&marketId=1.210263170"
                        style={{ cursor: "pointer" }}
                      >
                        Pisa v Perugia
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32117377&marketId=1.210182175"
                        style={{ cursor: "pointer" }}
                      >
                        Lask Linz v SC Austria Lustenau
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32122358&marketId=1.210255113"
                        style={{ cursor: "pointer" }}
                      >
                        Zulte-Waregem v Kortrijk
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32118190&marketId=1.210193105"
                        style={{ cursor: "pointer" }}
                      >
                        Raith v Ayr
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32115596&marketId=1.210165298"
                        style={{ cursor: "pointer" }}
                      >
                        Alebrijes de Oaxaca v Correcaminos UAT
                      </a>{" "}
                    </li>{" "}
                    <li className>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=1&eventId=32122728&marketId=1.210263335"
                        style={{ cursor: "pointer" }}
                      >
                        NEC Nijmegen v FC Volendam
                      </a>{" "}
                    </li>
                  </ul>
                </li>
                <li className="mtree-node mtree-closed">
                  <a href="#" style={{ cursor: "pointer" }}>
                    Tennis
                  </a>
                  <ul
                    id="tree_tennis"
                    className="mtree-level-1"
                    style={{
                      overflow: "hidden",
                      height: "0px",
                      display: "none",
                    }}
                  >
                    {" "}
                    <li>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=2&eventId=32133094&marketId=1.210416798"
                        style={{ cursor: "pointer" }}
                      >
                        I Swiatek v C Gauff
                      </a>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=2&eventId=32133354&marketId=1.210419488"
                        style={{ cursor: "pointer" }}
                      >
                        J Rodionov v Uchida
                      </a>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=2&eventId=32133078&marketId=1.210415314"
                        style={{ cursor: "pointer" }}
                      >
                        Hurkacz v M Ymer
                      </a>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=2&eventId=32133276&marketId=1.210418200"
                        style={{ cursor: "pointer" }}
                      >
                        Lehecka v Murray
                      </a>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=2&eventId=32133422&marketId=1.210420200"
                        style={{ cursor: "pointer" }}
                      >
                        J Pegula v B Krejcikova
                      </a>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=2&eventId=32131082&marketId=1.210386419"
                        style={{ cursor: "pointer" }}
                      >
                        Moro Canas v Dom Stricker
                      </a>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=2&eventId=32133296&marketId=1.210419074"
                        style={{ cursor: "pointer" }}
                      >
                        Dimitrov v Bublik
                      </a>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=2&eventId=32133505&marketId=1.210420566"
                        style={{ cursor: "pointer" }}
                      >
                        Al Michelsen v Leshem
                      </a>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=2&eventId=32133798&marketId=1.210422448"
                        style={{ cursor: "pointer" }}
                      >
                        Hong v Kodat
                      </a>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=2&eventId=32133745&marketId=1.210421144"
                        style={{ cursor: "pointer" }}
                      >
                        Medvedev v F Auger-Aliassime
                      </a>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=2&eventId=32133401&marketId=1.210420048"
                        style={{ cursor: "pointer" }}
                      >
                        Wawrinka v Fils
                      </a>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=2&eventId=32131311&marketId=1.210387908"
                        style={{ cursor: "pointer" }}
                      >
                        Svajda v Ejupovic
                      </a>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=2&eventId=32133687&marketId=1.210420876"
                        style={{ cursor: "pointer" }}
                      >
                        Thompson v A Gray
                      </a>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=2&eventId=32133735&marketId=1.210421011"
                        style={{ cursor: "pointer" }}
                      >
                        Zeppieri v Escoffier
                      </a>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=2&eventId=32133787&marketId=1.210422247"
                        style={{ cursor: "pointer" }}
                      >
                        Bonzi v De Minaur
                      </a>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <a
                        href="/admin/events-analysis?eventType=2&eventId=32131497&marketId=1.210392948"
                        style={{ cursor: "pointer" }}
                      >
                        C Mcnally v Birrell
                      </a>{" "}
                    </li>
                  </ul>
                </li>
                <li className="mtree-node mtree-closed">
                  <a href="#" style={{ cursor: "pointer" }}>
                    Cricket
                  </a>
                  <ul
                    id="tree_cricket"
                    className="mtree-level-1"
                    style={{
                      overflow: "hidden",
                      height: "0px",
                      display: "none",
                    }}
                  >
                    {" "}
                    <li className="mtree-node mtree-closed">
                      {" "}
                      <a href="#" style={{ cursor: "pointer" }}>
                        undefined
                      </a>{" "}
                      <ul
                        className="mtree-level-2"
                        style={{
                          overflow: "hidden",
                          height: "0px",
                          display: "none",
                        }}
                      >
                        {" "}
                        <li className>
                          {" "}
                          <a
                            href="/admin/events-analysis?eventType=4&eventId=1702222200&marketId=1.170222220101"
                            style={{ cursor: "pointer" }}
                          >
                            Eastern Cape vs Garden Route Badgers{" "}
                          </a>{" "}
                        </li>{" "}
                        <li className>
                          {" "}
                          <a
                            href="/admin/events-analysis?eventType=4&eventId=32124044&marketId=1.210294046"
                            style={{ cursor: "pointer" }}
                          >
                            New Zealand v England{" "}
                          </a>{" "}
                        </li>{" "}
                        <li className>
                          {" "}
                          <a
                            href="/admin/events-analysis?eventType=4&eventId=32128372&marketId=1.210345714"
                            style={{ cursor: "pointer" }}
                          >
                            England Women v South Africa Women{" "}
                          </a>{" "}
                        </li>{" "}
                        <li className>
                          {" "}
                          <a
                            href="/admin/events-analysis?eventType=4&eventId=32124738&marketId=1.210291638"
                            style={{ cursor: "pointer" }}
                          >
                            Quetta Gladiators v Islamabad United{" "}
                          </a>{" "}
                        </li>{" "}
                        <li className>
                          {" "}
                          <a
                            href="/admin/events-analysis?eventType=4&eventId=32125039&marketId=1.210296040"
                            style={{ cursor: "pointer" }}
                          >
                            Karachi Kings v Multan Sultans{" "}
                          </a>{" "}
                        </li>{" "}
                        <li className>
                          {" "}
                          <a
                            href="/admin/events-analysis?eventType=4&eventId=32127364&marketId=1.210327307"
                            style={{ cursor: "pointer" }}
                          >
                            Lahore Qalandars v Peshawar Zalmi{" "}
                          </a>{" "}
                        </li>{" "}
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="mtree-node mtree-closed">
                  <a href="#" style={{ cursor: "pointer" }}>
                    Boxing
                  </a>
                  <ul
                    id="tree_boxing"
                    className="mtree-level-1"
                    style={{
                      overflow: "hidden",
                      height: "0px",
                      display: "none",
                    }}
                  ></ul>
                </li>
                <li className="mtree-node mtree-closed">
                  <a href="#" style={{ cursor: "pointer" }}>
                    Table Tennis
                  </a>
                  <ul
                    id="tree_table_tennis"
                    className="mtree-level-1"
                    style={{
                      overflow: "hidden",
                      height: "0px",
                      display: "none",
                    }}
                  ></ul>
                </li>
                <li className="mtree-node mtree-closed">
                  <a href="#" style={{ cursor: "pointer" }}>
                    Badminton
                  </a>
                  <ul
                    id="tree_badminton"
                    className="mtree-level-1"
                    style={{
                      overflow: "hidden",
                      height: "0px",
                      display: "none",
                    }}
                  ></ul>
                </li>
                <li className="mtree-node mtree-closed">
                  <a href="#" style={{ cursor: "pointer" }}>
                    Basketball
                  </a>
                  <ul
                    id="tree_basketball"
                    className="mtree-level-1"
                    style={{
                      overflow: "hidden",
                      height: "0px",
                      display: "none",
                    }}
                  ></ul>
                </li>
                <li className="mtree-node mtree-closed">
                  <a href="#" style={{ cursor: "pointer" }}>
                    Vollyball
                  </a>
                  <ul
                    id="tree_vollyball"
                    className="mtree-level-1"
                    style={{
                      overflow: "hidden",
                      height: "0px",
                      display: "none",
                    }}
                  ></ul>
                </li>
                <li className="mtree-node mtree-closed">
                  <a href="#" style={{ cursor: "pointer" }}>
                    Politics
                  </a>
                  <ul
                    id="tree_politics"
                    className="mtree-level-1"
                    style={{
                      overflow: "hidden",
                      height: "0px",
                      display: "none",
                    }}
                  ></ul>
                </li>
              </ul>
            </nav>
          </div>
          <div id="divLoading" className />
          <div className="col-md-12 main-container">
            <div>
              <div className="listing-grid">
                <div className="detail-row">
                  <h2 style={{color:'#102A6D'}}>Game Reports</h2>
                  <form method="post" id="form-search">
                    <div className="m-t-20">
                      <div className="row">
                        <div className="col-md-2 col-xs-12">
                          <div className="form-group">
                            <label htmlFor="search-from_date">From:</label>
                            <input
                              type="text"
                              className="form-control date"
                              name="search-from_date"
                              id="search-from_date"
                              placeholder="Choose Date"
                              autoComplete="off"
                              defaultValue="2023-02-17"
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-2 col-xs-12">
                          <div className="form-group">
                            <label htmlFor="search-to_date">To:</label>
                            <input
                              type="text"
                              className="form-control date"
                              name="search-to_date"
                              id="search-to_date"
                              placeholder="Choose Date"
                              autoComplete="off"
                              defaultValue="2023-02-24"
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-2 col-xs-12">
                          <div className="form-group">
                            <label htmlFor="search-account_type">Type:</label>
                            <select
                              className="form-control"
                              name="search-account_type"
                              id="search-account_type"
                            >
                              <option value="all">ALL</option>
                              <option value="match_odds">MATCH</option>
                              <option value="fancy">FANCY</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4 col-xs-12 pull-right">
                          <div className="form-group">
                            <label htmlFor> &nbsp;</label>
                            <div className="col-md-12 xdisplay_inputx form-group has-feedback">
                              <button
                                type="button"
                                className="btn btn-diamond"
                                id="refresh_event_list"
                                style={{ backgroundColor: "#102A6D" }}
                              >
                                Game List
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-xs-12">
                          <div className="form-group">
                            <select
                              className="form-control"
                              name="search-event"
                              id="search-event"
                            >
                              <option value="all">ALL</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4 col-xs-12 pull-right">
                          <div className="form-group">
                            <div className="col-md-12 xdisplay_inputx form-group has-feedback">
                              <button
                                type="submit"
                                className="btn btn-diamond"
                                style={{ backgroundColor: "#102A6D" }}
                              >
                                Show Game Report
                              </button>
                              &nbsp;&nbsp;
                              <button
                                type="button"
                                className="btn btn-diamond"
                                style={{ backgroundColor: "#102A6D" }}
                              >
                                Master Game Report
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="custom-table table table-striped">
                        <thead>
                          <tr className="headings" style={{backgroundColor:'#C9C9C9'}}>
                            <th>Sr. No</th>
                            <th>Name</th>
                            <th className="amount-field">Amount</th>
                            <th>Sr. No</th>
                            <th>Name</th>
                            <th className="amount-field">Amount</th>
                          </tr>
                        </thead>
                        <tbody id="tbody"></tbody>
                      </table>
                    </div>
                  </div>
                  <div id="pagination" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
