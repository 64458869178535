import { createSlice } from '@reduxjs/toolkit';

const bankSlice = createSlice({
  name: 'bank',
  initialState: {
    bankData: null,
  },
  reducers: {
    setBankData: (state, action) => {
      state.bankData = action.payload;
    },
  },
});

export const { setBankData } = bankSlice.actions;
export const selectBankData = (state) => state.bank.bankData;
export default bankSlice.reducer;
