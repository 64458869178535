import { CASINO_SERVICE_URL, getToken } from "../../utils/constants";
import store from "../../redux/store";
import { AXIOS_INSTANCE } from "..";
const { dispatch } = store;

export const placeCasinoBetAPI = async (payload, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${CASINO_SERVICE_URL}/casino/bet/place`,
      {
        ...payload,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getMyBetsCasinoAPI = async (casinoGames, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CASINO_SERVICE_URL}/casino/my-bet`,
      {
        params: {
          casinoGames,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getCasinoLastTenResultsAPI = async (casinoGames, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CASINO_SERVICE_URL}/casino/last-10-results`,
      {
        params: {
          casinoGames,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getCasinoReportHistory = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${CASINO_SERVICE_URL}/casino/casino-report-history`,
      {
        ...payload,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getIndividualResultAPI = async (marketId, casinoKey) => {
  try {
    const query = casinoKey ? `&casinoGame=${casinoKey}` : "";
    const { data } = await AXIOS_INSTANCE.get(
      `${CASINO_SERVICE_URL}/casino/individaul-result?marketId=${marketId}${query}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getCasinoPlExposureAPI = async (marketId) => {
  try {
    const token = localStorage.getItem("token");
    const { data } = await AXIOS_INSTANCE.get(
      `${CASINO_SERVICE_URL}/casino/pl-exposure?marketId=${marketId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken() || token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
