import { Box, Grid, GridItem, Spacer, Switch, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect } from "react";
import CustomInput from "../../../components/UI/CustomInput";
import { getGlobalSettingsAPI } from "../../../service/UserService";

export default function GlobalSettings({ payload, setPayload }) {
  const [userGlobalSettings, setUserGlobalSettings] = React.useState([]);
  const [globalSettign, setGlobalSetting] = useState({});
  const [bankFunctionalityEnabled, setBankFunctionalityEnabled] = useState(globalSettign.canUseBanking);
  const [userCreationEnabled, setUserCreationEnabled] = useState(globalSettign.canCreateUser);

  const [originalCanUseBanking, setOriginalCanUseBanking] = useState(globalSettign.canUseBanking);
  const [originalCanCreateUser, setOriginalCanCreateUser] = useState(globalSettign.canCreateUser);


  const serializedState = JSON.parse(localStorage.getItem('state'));
  console.log(serializedState.account.userData, "test data")
  const canParentCreateUser = serializedState?.account?.userData?.canCreateUser;
  const canParentUseBanking = serializedState?.account?.userData?.canUseBanking;

  const getUserGlobalSettings = async () => {
    const { response } = await getGlobalSettingsAPI(payload.user.uid);
    setGlobalSetting(response);
    setBankFunctionalityEnabled(response.canUseBanking);
    setUserCreationEnabled(response.canCreateUser);
    setOriginalCanUseBanking(response.canUseBanking);
    setOriginalCanCreateUser(response.canCreateUser);

    setPayload({
      ...payload,
      user: {
        ...payload.user,
        originalCanUseBanking: response.canUseBanking,
        originalCanCreateUser: response.canCreateUser,
      },
    });
  };


  useEffect(() => {
    getUserGlobalSettings();
  }, []);

  const handleBankFunctionality = () => {
    if (!canParentUseBanking) return;
    setBankFunctionalityEnabled((prevState) => {
      const newState = !prevState;
      setPayload((prevPayload) => ({
        ...prevPayload,
        user: {
          ...prevPayload.user,
          canUseBanking: newState,
        },
      }));
      return newState;
    });
  };
  
  const handleUserFunctionality = () => {
    if (!canParentCreateUser) return;
    setUserCreationEnabled((prevState) => {
      const newState = !prevState;
      setPayload((prevPayload) => ({
        ...prevPayload,
        user: {
          ...prevPayload.user,
          canCreateUser: newState,
        },
      }));
      return newState;
    });
  };
  
  console.log(payload, "payload")
  return (
    <Box>
      <Box p="5px 10px" className="title_bar">
        Commission
      </Box>
      <Grid p="1" mt="2" gap={1} templateColumns="repeat(4,1fr)">
        <GridItem>
          <Spacer />
        </GridItem>
        <GridItem>
          <Text fontSize="sm">Odds</Text>
        </GridItem>
        <GridItem>
          <Text fontSize="sm">Session</Text>
        </GridItem>
        <GridItem>
          <Text fontSize="sm">Bookmaker</Text>
        </GridItem>
        <GridItem>
          <Text fontSize="sm">Cricket</Text>
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  commissionInCricketOdds: e.target.value,
                },
              })
            }
            value={payload.user.commissionInCricketOdds}
          />
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  commissionInCricketSession: e.target.value,
                },
              })
            }
            value={payload.user.commissionInCricketSession}
          />
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  commissionInCricketBookmaker: e.target.value,
                },
              })
            }
            value={payload.user.commissionInCricketBookmaker}
          />
        </GridItem>
        <GridItem>
          <Text fontSize="sm">Soccer</Text>
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  commissionInFootballOdds: e.target.value,
                },
              })
            }
            value={payload.user.commissionInFootballOdds}
          />
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  commissionInFootballSession: e.target.value,
                },
              })
            }
            value={payload.user.commissionInFootballSession}
          />
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  commissionInFootballBookmaker: e.target.value,
                },
              })
            }
            value={payload.user.commissionInFootballBookmaker}
          />
        </GridItem>
        <GridItem>
          <Text fontSize="sm">Tennis</Text>
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  commissionInTennisOdds: e.target.value,
                },
              })
            }
            value={payload.user.commissionInTennisOdds}
          />
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  commissionInTennisSession: e.target.value,
                },
              })
            }
            value={payload.user.commissionInTennisSession}
          />
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  commissionInTennisBookmaker: e.target.value,
                },
              })
            }
            value={payload.user.commissionInTennisBookmaker}
          />
        </GridItem>
      </Grid>
      <Box p="5px 10px" className="title_bar">
        Sports Setting
      </Box>
      <Grid p="1" mt="2" gap={1} templateColumns="repeat(5,1fr)">
        <GridItem>
          <Spacer />
        </GridItem>
        <GridItem>
          <Text fontSize="sm">Min</Text>
        </GridItem>
        <GridItem>
          <Text fontSize="sm">Max</Text>
        </GridItem>
        <GridItem>
          <Text fontSize="sm">Odd Delay</Text>
        </GridItem>
        <GridItem>
          <Text fontSize="sm">Session Delay</Text>
        </GridItem>
        <GridItem>
          <Text fontSize="sm">Cricket</Text>
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  minBetCricket: e.target.value,
                },
              })
            }
            value={payload.user.minBetCricket}
          />
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  maxBetCricket: e.target.value,
                },
              })
            }
            value={payload.user.maxBetCricket}
          />
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  delayCricketOdds: e.target.value,
                },
              })
            }
            value={payload.user.delayCricketOdds}
          />
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  delayCricketSession: e.target.value,
                },
              })
            }
            value={payload.user.delayCricketSession}
          />
        </GridItem>
        <GridItem>
          <Text fontSize="sm">Football</Text>
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  minBetFootball: e.target.value,
                },
              })
            }
            value={payload.user.minBetFootball}
          />
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  maxBetFootball: e.target.value,
                },
              })
            }
            value={payload.user.maxBetFootball}
          />
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  delayFootballOdds: e.target.value,
                },
              })
            }
            value={payload.user.delayFootballOdds}
          />
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  delayFootballSession: e.target.value,
                },
              })
            }
            value={payload.user.delayFootballSession}
          />
        </GridItem>
        <GridItem>
          <Text fontSize="sm">Tennis</Text>
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  minBetTennis: e.target.value,
                },
              })
            }
            value={payload.user.minBetTennis}
          />
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  maxBetTennis: e.target.value,
                },
              })
            }
            value={payload.user.maxBetTennis}
          />
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  delayTennisOdds: e.target.value,
                },
              })
            }
            value={payload.user.delayTennisOdds}
          />
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  delayTennisSession: e.target.value,
                },
              })
            }
            value={payload.user.delayTennisSession}
          />
        </GridItem>
      </Grid>
      <Box p="5px 10px" className="title_bar">
        Session and Bookmaker Limit
      </Box>
      <Grid p="1" mt="2" gap={1} templateColumns="repeat(2,1fr)">
        <GridItem>
          <Text fontSize="sm">Session Min Bet</Text>
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  sessionMinBet: e.target.value,
                },
              })
            }
            value={payload.user.sessionMinBet}
          />
        </GridItem>
        <GridItem>
          <Text fontSize="sm">Session Max Bet</Text>
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  sessionMaxBet: e.target.value,
                },
              })
            }
            value={payload.user.sessionMaxBet}
          />
        </GridItem>
        <GridItem>
          <Text fontSize="sm">Bookmaker Max Bet</Text>
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  bookmakerMaxBet: e.target.value,
                },
              })
            }
            value={payload.user.bookmakerMaxBet}
          />
        </GridItem>
        <GridItem>
          <Text fontSize="sm">Casino Delay</Text>
        </GridItem>
        <GridItem>
          <CustomInput
            onChange={(e) =>
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  delayCasino: e.target.value,
                },
              })
            }
            value={payload.user.delayCasino}
          />
        </GridItem>

        <GridItem>
          <Text fontSize="sm">Can Use Banking</Text>
        </GridItem>
        <GridItem>
          <Switch
            value={bankFunctionalityEnabled}
            isChecked={bankFunctionalityEnabled}
            isDisabled={!canParentUseBanking }
            onChange={handleBankFunctionality}
          />
        </GridItem>

        <GridItem>
          <Text fontSize="sm">Can Create User</Text>
        </GridItem>
        <GridItem>
          <Switch
            value={userCreationEnabled}
            isChecked={userCreationEnabled}
            isDisabled={!canParentCreateUser }
            onChange={handleUserFunctionality}
          />
        </GridItem>


        <GridItem>
          <Text>Transaction Password</Text>
        </GridItem>
        <GridItem>
          <CustomInput
            type="password"
            onChange={(e) => {
              setPayload({
                ...payload,
                user: {
                  ...payload.user,
                  transactionPassword: e.target.value,
                },
              });
            }}
          />
        </GridItem>
      </Grid>
    </Box>
  );
}
