import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordAPI } from "../service/AuthService";
import { Encrypt } from "../utils/constants";
import CustomDataModal from "../components/UI/CustomDataModal";
import "./changePassword.css";

export default function ChangePassword({ internal }) {
  const ui = useSelector((state) => state.account.ui);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [oldPassword, setOldPassword] = React.useState("");
  const userData = useSelector((state) => state.account.userData);
  const toast = useToast();
  const dispatch = useDispatch();
  const changePassword = async () => {
    if (password === "" || confirmPassword === "" || oldPassword === "") {
      toast({
        title: "Error",
        description: "Please fill all the fields",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    if (password !== confirmPassword) {
      toast({
        title: "Error",
        description: "Password and confirm password does not match",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    if (password === oldPassword) {
      toast({
        title: "Error",
        description: "New password and old password cannot be same",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z!@#$%^&*]{8,}$/.test(
        password
      )
    ) {
      toast({
        title: "Error",
        description:
          "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    var { data, code, message, response, error } = await changePasswordAPI(
      {
        newPassword: Encrypt(password),
        password: Encrypt(oldPassword),
        resetRequired: false,
        uid: userData.id,
      },
      userData.token
    );
    if (code === 200) {
      toast({
        title: "Success",
        description: "Password changed successfully",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      setIsPopUpOpen(true);
      dispatch({
        type: "accountData/openPopUp",
        payload: {
          modalOpen: true,
          modalHeader: "",
          modalBody: `Success! Your Password has been updated successfully ${
            internal
              ? ""
              : "Transaction Password:" + userData?.transactionPassword
          }`,
          modalContent:
            "Please remember your transaction password, from now all transaction of the website can be done only with this password and keep one thing in mind, do not share this password with anyone.",
          action: "logout",
        },
      });
      dispatch({
        type: "gameData/logOut",
        payload: {},
      });
    } else {
      toast({
        title: "Error",
        description: "Wrong Password",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };
  return (
    <Box p="5" bgColor={"white"}>
      <Modal
        scrollBehavior="inside"
        isCentered
        size={"4xl"}
        isOpen={isPopUpOpen}
        onClose={() => {
          setIsPopUpOpen(false);
          dispatch({
            type: "accountData/closePopUp",
            payload: {
              modalOpen: false,
            },
          });
        }}
      >
        <ModalOverlay />
        <ModalContent maxH={"90vh"}>
          <ModalHeader color="white" bg="#2B2B2B">
            Password Changed
          </ModalHeader>
          <ModalCloseButton
            onClick={() => {
              setIsPopUpOpen(false);
              dispatch({
                type: "accountData/closePopUp",
                payload: {
                  modalOpen: false,
                },
              });
            }}
            color="white"
          />
          <ModalBody>
            <Box>
              Success! Your Password has been updated successfully{" "}
              {`${
                userData?.transactionPassword &&
                "Transaction Password:" + userData?.transactionPassword
              }`}
            </Box>
            <Button
              onClick={() => {
                setIsPopUpOpen(false);
                dispatch({
                  type: "accountData/closePopUp",
                  payload: {
                    modalOpen: false,
                  },
                });
                dispatch({
                  type: "accountData/logOut",
                  payload: {},
                });
                window.localStorage.clear();
                window.location.href = "/";
              }}
              style={{
                backgroundColor: "#102A6D",
                color: "white",
                marginTop: "10px",
              }}
            >
              Close{" "}
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Box
        bg="#C9C9C9"
        p="2"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        fontWeight="bold"
        mb="5"
        color="#4E4E4E"
      >
        <Text>Change Passoword</Text>
      </Box>
      <Box w="40%">
        <Input
          type="password"
          placeholder="Old Password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          mb="4"
        />
        <Input
          type="password"
          placeholder="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          mb="4"
        />
        <Input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          mb="4"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </Box>
      <Button color="white" bg="#102A6D" onClick={changePassword}>
        Change Password
      </Button>
    </Box>
  );
}
