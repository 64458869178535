import { Alert, Box, Button, Flex, Image, Input, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { loginAPI } from '../service/AuthService';
import { Encrypt } from '../utils/constants';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
export default function Login() {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      login();
    }
  };

  const login = async () => {
    const { response, message, code } = await loginAPI(
      userName,
      Encrypt(password)
    );
    if (userName === '' || password === '') {
      toast.error('Please enter username and password');
      return;
    }
    if (code == 200) {
      dispatch({
        type: 'accountData/setUserData',
        payload: response,
      });

      window.location.href = '/admin/market-analysis';
      console.log(response, "login response")
      // localStorage.setItem('state', serializedState);
    } else {
      toast.error(response);
    }
  };
  return (
    <Flex direction='column' className='login_container'>
      <Box mb='20px' className='login_logo'>
        <img src={require('../assets/img/logo.png')} />
      </Box>
      <Flex
        direction='column'
        className='login_box'
        style={{ backgroundColor: 'white' }}
      >
        <h2
          style={{
            fontFamily: "'Inter',Helvetica",
            fontSize: '18px',
            fontWeight: 600,
            color: '#102A6D',
            marginTop: '20px',
          }}
        >
          Login
        </h2>

        <Text
          style={{
            fontFamily: "'Inter',Helvetica",
            fontSize: '14px',
            fontWeight: 500,
            color: '#4E4E4E',
          }}
        >
          Username
        </Text>
        <Input
          onKeyDown={handleKeyDown}
          onChange={(e) => setUserName(e.target.value)}
          placeholder='Username'
          style={{
            border: '1px solid #C8C8C8',
            backgroundColor: '#E9E9E9',
            borderRadius: '5px',
            height: '40px',
            fontFamily: "'Inter',Helvetica",
            fontSize: '12px',
            fontWeight: 500,
            color: '#AEAEAE',
          }}
        />

        <Text
          style={{
            fontFamily: "'Inter',Helvetica",
            fontSize: '14px',
            fontWeight: 500,
            color: '#4E4E4E',
          }}
        >
          Password
        </Text>
        <Input
          onKeyDown={handleKeyDown}
          onChange={(e) => setPassword(e.target.value)}
          placeholder='Password'
          type='password'
          style={{
            border: '1px solid #C8C8C8',
            backgroundColor: '#E9E9E9',
            borderRadius: '5px',
            height: '40px',
            fontFamily: "'Inter',Helvetica",
            fontSize: '12px',
            fontWeight: 500,
            color: '#AEAEAE',
          }}
        />
        <Button
          onKeyDown={handleKeyDown}
          onClick={login}
          style={{ backgroundColor: '#102A6D', marginTop: '30px' }}
        >
          <Text
            style={{
              fontFamily: "'Inter',Helvetica",
              fontSize: '16px',
              fontWeight: 600,
              color: '#fff',
            }}
          >
            Login
          </Text>
        </Button>
      </Flex>
    </Flex>
  );
}
