import React from "react";
import { Link, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Input,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Text,
} from "@chakra-ui/react";
import Header from "../components/Header";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllCricketMatchListAPI } from "../service/cricket";
import { getAllMatchListAPI } from "../service/AuthService";
import { sidebarCasinoList } from "../utils/constants";

const PrivateOutlet = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [cricketMatchList, setCricketMatchList] = React.useState({});
  const [soccerMatchList, setSoccerMatchList] = React.useState({});
  const [tennisMatchList, setTennisMatchList] = React.useState({});
  const btnRef = React.useRef();
  const getAllMatchList = async () => {
    const { response } = await getAllMatchListAPI();
    setCricketMatchList(response?.cricket);
    setSoccerMatchList(response?.soccer);
    setTennisMatchList(response?.tennis);
  };
  const userData = useSelector((state) => state.account.userData);
  useEffect(() => {
    getAllMatchList();
  }, []);
  if (!userData.token) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Box minH="100vh">
        <Header onOpen={onOpen} drawerButtonRef={btnRef} />
        {props.children}
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Sports</DrawerHeader>
            <DrawerBody>
              <Accordion allowMultiple allowToggle>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box color="black" as="span" flex="1" textAlign="left">
                        Football
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    {soccerMatchList &&
                      Object.keys(soccerMatchList).map((game) => (
                        <Accordion allowToggle allowMultiple>
                          <AccordionItem>
                            <h2>
                              <AccordionButton>
                                <Box
                                  color="black"
                                  as="span"
                                  flex="1"
                                  textAlign="left"
                                >
                                  {game}
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel>
                              {soccerMatchList[game].map((match) => (
                                <Link to={`/admin/game/soccer/${match.id}`}>
                                  <Text color="black" fontSize="14px">
                                    {match.eventName}
                                  </Text>
                                </Link>
                              ))}
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      ))}
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box color="black" as="span" flex="1" textAlign="left">
                        Cricket
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    {cricketMatchList &&
                      Object.keys(cricketMatchList).map((game) => (
                        <Accordion allowToggle allowMultiple>
                          <AccordionItem>
                            <h2>
                              <AccordionButton>
                                <Box
                                  color="black"
                                  as="span"
                                  flex="1"
                                  textAlign="left"
                                >
                                  {game}
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel>
                              {cricketMatchList[game].map((match) => (
                                <Link to={`/admin/game/cricket/${match.id}`}>
                                  <Text color="black" fontSize="14px">
                                    {match.eventName}
                                  </Text>
                                </Link>
                              ))}
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      ))}
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box color="black" as="span" flex="1" textAlign="left">
                        Tennis
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    {tennisMatchList &&
                      Object.keys(tennisMatchList).map((game) => (
                        <Accordion allowToggle allowMultiple>
                          <AccordionItem>
                            <h2>
                              <AccordionButton>
                                <Box
                                  color="black"
                                  as="span"
                                  flex="1"
                                  textAlign="left"
                                >
                                  {game}
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel>
                              {tennisMatchList[game].map((match) => (
                                <Link to={`/admin/game/tennis/${match.id}`}>
                                  <Text color="black" fontSize="14px">
                                    {match.eventName}
                                  </Text>
                                </Link>
                              ))}
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      ))}
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box color="black" as="span" flex="1" textAlign="left">
                        Live Casino
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    {sidebarCasinoList.map((game) => (
                      <Accordion allowToggle allowMultiple>
                        <AccordionItem>
                          <a href={`/live-casino/${game.path}`}>
                            <Text color="black" fontSize="14px">
                              {game.name}
                            </Text>
                          </a>
                        </AccordionItem>
                      </Accordion>
                    ))}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </>
  );
};

export default PrivateOutlet;
