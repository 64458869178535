import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import PrivateOutlet from './layout/PrivateOutlet';
import PublicOutlet from './layout/PublicOutlet';
import AddClient from './pages/Client/Add';
import ListClient from './pages/Client/List';
import Game from './pages/Game/Game';
import Home from './pages/Home';
import Login from './pages/Login';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import store, { saveState } from './redux/store';
import ChangePassword from './pages/ChangePassword';
import CustomModal from './components/UI/CustomModal';
import { createSocket } from './utils/constants';
import AccountStatement from './pages/Reports/AccountStatement';
import GeneralReport from './pages/Reports/GeneralReport';
import ProfitLoss from './pages/Reports/ProfitLoss';
import CasinoResult from './pages/Reports/CasinoResult';
import CurrentBets from './pages/Reports/CurrentBets';
import GameReport from './pages/Reports/GameReport';
import MyProfitLoss from './pages/MyProfitLoss';
import ActivityLog from './pages/Client/Activity';
import BetHistory from './pages/Reports/BetHistory';
import CasinoList from './pages/casino/CasinoList';
import DragonTiger202 from './pages/casino/games/DragonTiger202';
import TeenPatti from './pages/casino/games/TeenPatti';
import Cards32A from './pages/casino/games/32Cards-A';
import Cards32B from './pages/casino/games/32Cards-B';
import Race2020 from './pages/casino/games/Race2020';
import CasinoQueen from './pages/casino/games/CasinoQueen';
import DragonTiger201 from './pages/casino/games/DragonTiger201';
import DragonTiger1Day from './pages/casino/games/DragonTiger1Day';
import Lucky7A from './pages/casino/games/Lucky7A';
import Lucky7B from './pages/casino/games/Lucky7B';
import AndarBahar from './pages/casino/games/AndarBahar';
import AndarBahar2 from './pages/casino/games/AndarBahar-2';
import JudgementCards3 from './pages/casino/games/3CardsJudgement';
import BollywoodCasino from './pages/casino/games/BollywoodCasino';
import AmarAkbarAnthony from './pages/casino/games/AmabAkbarAnthony';
import TeenPatti1Day from './pages/casino/games/Teenpatti1Day';
import Poker20 from './pages/casino/games/Poker20-20';
import InstantWorli from './pages/casino/games/InstantWorli';
import CasinoMeter from './pages/casino/games/CasinoMeter';
import DragonTigerLion from './pages/casino/games/DragonTigerLion';
import TeenPattiTest from './pages/casino/games/TeenPattiTest';
import TeenPattiOpen from './pages/casino/games/TeenPattiOpen';
import CasinoWar from './pages/casino/games/CasinoWar';
import FiveCricket from './pages/casino/games/Five-5Cricket';
import Cricket2020 from './pages/casino/games/Cricket20-20';
import Poker1Day from './pages/casino/games/Poker1Day';
import Pokerr6Players from './pages/casino/games/Poker6Players';
import Baccart from './pages/casino/games/Baccart';
import Baccart2 from './pages/casino/games/Baccarat2';
import Worli from './pages/casino/games/WorliMatka';
import SuperOver from './pages/casino/games/SuperOver';
import { getService } from './utils/commonFunctions';
import PaymentSnap from './pages/PaymentSolution/PaymentSnap';
import BankDetails from './pages/PaymentSolution/BankDetails';

export default function App() {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.account.userData);
  const ui = useSelector((state) => state.account.ui);
  const dispatch = useDispatch();
  const location = useLocation();
  const { gameKey } = useSelector((state) => state.gameData);

  useEffect(() => {
    if (location.pathname.includes('live-casino')) {
      createSocket(
        'casino',
        dispatch,
        gameKey,
        getService(location.pathname).port,
        getService(location.pathname).service
      );
    } else if (userData?.token && userData?.resetRequired) {
      dispatch({
        type: 'accountData/setChangePopSate',
        payload: true,
      });
    } else {
      const gameId_ = location.pathname.split('/')[4];
      const gameType = location.pathname.split('/')[3];
      createSocket(gameType, dispatch, gameId_);
    }
  }, [location.pathname, gameKey]);
  useEffect(() => {
    store.subscribe(() => {
      saveState(store.getState());
    });
  }, [0]);

  useEffect(() => {
    if (!userData.token) {
      navigate('/admin');
    }
  }, [userData.token]);
  return (
    <>
      {userData.token && !userData.resetRequired ? (
        <PrivateOutlet>
          <Routes>
            <Route path='/admin' element={<Home />} />
            <Route path='/admin/manage-client/list' element={<ListClient />} />
            <Route
              path='/admin/manage-client/list/:id'
              element={<ListClient />}
            />
            <Route path='/admin/manage-client/add' element={<AddClient />} />
            <Route path='/admin/game/:game/:id' element={<Game />} />
            <Route
              path='/admin/reports/account-statement'
              element={<AccountStatement />}
            />
            <Route
              path='/admin/reports/general-report'
              element={<GeneralReport />}
            />
            <Route path='/admin/reports/profit-loss' element={<ProfitLoss />} />
            <Route
              path='/admin/reports/casino-results'
              element={<CasinoResult />}
            />
            <Route
              path='/admin/reports/current-bet'
              element={<CurrentBets />}
            />
            <Route path='/admin/reports/bet-history' element={<BetHistory />} />
            <Route path='/admin/reports/game-report' element={<GameReport />} />
            <Route
              path='/admin/change-password'
              element={<ChangePassword internal={true} />}
            />
            <Route path='/admin/market-analysis' element={<MyProfitLoss />} />
            <Route path='/admin/payment-snap' element={<PaymentSnap />} />
            <Route path='/admin/bank-details' element={<BankDetails />} />
            <Route
              path='/admin/activity-log/:username'
              element={<ActivityLog />}
            />
            <Route path='/live-casino' element={<CasinoList />} />
            <Route
              path='/live-casino/teenpatti/20-20'
              element={<TeenPatti />}
            />
            <Route
              path='/live-casino/teenpatti/1-day'
              element={<TeenPatti1Day />}
            />
            <Route
              path='/live-casino/teenpatti/test'
              element={<TeenPattiTest />}
            />
            <Route
              path='/live-casino/teenpatti/open'
              element={<TeenPattiOpen />}
            />
            <Route
              path='/live-casino/dragon-tiger/20-20'
              element={<DragonTiger201 />}
            />

            <Route
              path='/live-casino/dragon-tiger/20-20/2'
              element={<DragonTiger202 />}
            />

            <Route path='/live-casino/card-32/a' element={<Cards32A />} />
            <Route path='/live-casino/card-32/b' element={<Cards32B />} />
            <Route path='/live-casino/race/20-20' element={<Race2020 />} />
            <Route path='/live-casino/cn/queen' element={<CasinoQueen />} />

            <Route
              path='/live-casino/dragon-tiger/1-day'
              element={<DragonTiger1Day />}
            />
            <Route path='/live-casino/lucky7/a' element={<Lucky7A />} />
            <Route path='/live-casino/lucky7/b' element={<Lucky7B />} />
            <Route path='/live-casino/andar-bahar' element={<AndarBahar />} />
            <Route
              path='/live-casino/andar-bahar/2'
              element={<AndarBahar2 />}
            />
            <Route
              path='/live-casino/3cardsjudgement'
              element={<JudgementCards3 />}
            />
            <Route
              path='/live-casino/cn/bollywood-table'
              element={<BollywoodCasino />}
            />
            <Route
              path='/live-casino/andar-bahar/aaa'
              element={<AmarAkbarAnthony />}
            />

            <Route path='/live-casino/poker/20-20' element={<Poker20 />} />
            <Route path='/live-casino/worli/2' element={<InstantWorli />} />
            <Route path='/live-casino/cn/meter' element={<CasinoMeter />} />
            <Route
              path='/live-casino/dragon-tiger/dtl'
              element={<DragonTigerLion />}
            />

            <Route path='/live-casino/cn/war' element={<CasinoWar />} />

            <Route path='/live-casino/cricket/five' element={<FiveCricket />} />
            <Route
              path='/live-casino/cricket/20-20'
              element={<Cricket2020 />}
            />
            <Route path='/live-casino/poker' element={<Poker1Day />} />
            <Route
              path='/live-casino/poker/poker-6-player'
              element={<Pokerr6Players />}
            />
            <Route path='/live-casino/baccarat' element={<Baccart />} />
            <Route path='/live-casino/baccarat/2' element={<Baccart2 />} />
            <Route path='/live-casino/worli' element={<Worli />} />
            <Route
              path='/live-casino/cricket/super-over'
              element={<SuperOver />}
            />
          </Routes>
        </PrivateOutlet>
      ) : userData.token && userData.resetRequired ? (
        <PrivateOutlet>
          <Routes>
            <Route path='*' element={<ChangePassword internal={true} />} />
            <Route path='/' element={<ChangePassword internal={true} />} />
          </Routes>
          <CustomModal header={ui?.modalHeader} isOpen={ui?.modalOpen} />
        </PrivateOutlet>
      ) : (
        <PublicOutlet>
          <Routes>
            <Route>
              <Route path='/admin' element={<Login />} />
              <Route path='*' element={<Login />} />
            </Route>
          </Routes>
        </PublicOutlet>
      )}
      <CustomModal header={ui?.modalHeader} isOpen={ui?.modalOpen} />
    </>
  );
}
