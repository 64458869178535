import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Text,
  Image,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Flip, Flipr } from '../../../components/Flip';

import CardDrawer from '../../../components/CardDrawer/CardDrawer';
import { ASSETS_URL } from '../../../utils/constants';
import { useMemo } from 'react';
import Iframe from 'react-iframe';
import IframeComponent from '../../../components/Iframe';
import CasinoVideoRules from '../../../components/CasinoVideoRules';
import { getCasinoPlExposureAPI } from '../../../service/casino';
import LastResults from '../../../components/LastResults';
import Popover from '../../../components/Popover';
import { setGameKey } from '../../../redux/features/gameSlice';
import { getService } from '../../../utils/commonFunctions';
import { useLocation } from 'react-router-dom';

export default function AmarAkbarAnthony() {
  const location = useLocation();
  const odds_ = useSelector((state) => state.gameData.aaaOdds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [plExposure, setPlExposure] = useState(null);
  const [isLocked, setIsLocked] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const authDetails = useSelector((state) => state.account.authDetails);
  const toast = useToast();
  const dispatch = useDispatch();
  const gameName = 'AAA';
  const allCard = [
    {
      id: 'Card A',
      img: require('../../../assets/img/casino/1 - Copy.png'),
    },
    {
      id: 'Card 2',
      img: require('../../../assets/img/casino/2.png'),
    },
    {
      id: 'Card 3',
      img: require('../../../assets/img/casino/3.png'),
    },
    {
      id: 'Card 4',
      img: require('../../../assets/img/casino/4.png'),
    },
    {
      id: 'Card 5',
      img: require('../../../assets/img/casino/5.png'),
    },
    {
      id: 'Card 6',
      img: require('../../../assets/img/casino/6.png'),
    },
    {
      id: 'Card 7',
      img: require('../../../assets/img/casino/7.png'),
    },
    {
      id: 'Card 8',
      img: require('../../../assets/img/casino/8.png'),
    },
    {
      id: 'Card 9',
      img: require('../../../assets/img/casino/9.png'),
    },
    {
      id: 'Card 10',
      img: require('../../../assets/img/casino/10.png'),
    },
    {
      id: 'Card J',
      img: require('../../../assets/img/casino/11.png'),
    },
    {
      id: 'Card Q',
      img: require('../../../assets/img/casino/12.png'),
    },
    {
      id: 'Card K',
      img: require('../../../assets/img/casino/13.png'),
    },
  ];
  const CustomButton = ({ text, isBack = true, children }) => (
    <Button
      size='sm'
      my='2'
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      className={isBack ? 'casinoButton' : ' casinoButton casinoButton-pink'}
    >
      {isLocked ? <FaLock /> : text}
      {!isLocked && <Text className='expos-text'>{children}</Text>}
    </Button>
  );
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id) => {
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = selectedEventForBet.back1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = 'back';

    dispatch({
      type: 'gameData/setSelectedEventForBet',
      payload,
    });
  };
  useEffect(() => {
    if (odds?.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);

  useEffect(() => {
    dispatch(setGameKey('aaa'));
  }, [dispatch]);

  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id,
      authDetails?.uid
    );
    if (res && res?.code == 200) {
      setPlExposure(res.response);
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);

  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data;
      for (let i = 0; i < plExposure.length; i++) {
        let data = plExposure[i]?.casinoPLExposure?.find(
          (item) => item.selectionName == selectionName
        );
        if (data) {
          return data;
        }
      }

      return data ? data : null;
    }
  };

  return (
    <Box
      display='flex'
      flexDirection={{ base: 'column', sm: 'column', lg: 'row' }}
    >
      <Box mt='1' mr='1' w={{ base: '100%', sm: '100%', lg: '70%' }}>
        <Box h='60vh' mb='2' pos='relative' className='casino-video'>
          {odds?.cards && <CardDrawer cards={[odds?.cards[0]]} />}
          <div className='market-title'>Amar Akbar Anthony</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3056`}
          />
          <Box
            bottom='0'
            p='2'
            pos='absolute'
            right='2'
            display='flex'
            bg='black'
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules image={`${ASSETS_URL}aaa-rules.jpg`} />
        </Box>

        <div>
          <div className='card-content aaa-content m-t-10'>
            <div className='row'>
              <div className='col-12 text-right'>
                <div className='info-block'>
                  <div className='text-right'>
                    <Popover>
                      <div id='min-max-info' className='min-max-box'>
                        <p>Min: 100</p>
                        <p>Max: 300000</p>
                      </div>
                    </Popover>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-4 text-center'>
                <div>
                  <span className='d-block'>
                    <b>
                      <span className='text-danger'>A.</span>
                      Amar
                    </b>
                  </span>
                </div>
                <div
                  className={`aaa-button clearfix ${
                    isLocked ? 'suspended ' : ''
                  }`}
                >
                  <button
                    className='back'
                    onClick={() => setEvent('Amar', 'back1')}
                  >
                    <span className='odd' style={{ color: 'black' }}>
                      {getData('Amar').back1}
                    </span>
                  </button>{' '}
                  <button
                    className='lay'
                    onClick={() => setEvent('Amar', 'lay1')}
                  >
                    <span className='odd' style={{ color: 'black' }}>
                      {getData('Amar').lay1}
                    </span>
                  </button>
                </div>
                <div>{getExposureForEntity('Amar')?.exposure || 0}</div>
              </div>
              <div className='col-4 text-center'>
                <div>
                  <span className='d-block'>
                    <b>
                      <span className='text-danger'>B.</span>
                      Akbar
                    </b>
                  </span>
                </div>
                <div
                  className={`aaa-button clearfix ${
                    isLocked ? 'suspended ' : ''
                  }`}
                >
                  <button
                    className='back'
                    onClick={() => setEvent('Akbar', 'back1')}
                  >
                    <span className='odd' style={{ color: 'black' }}>
                      {getData('Akbar').back1}
                    </span>
                  </button>{' '}
                  <button
                    className='lay'
                    onClick={() => setEvent('Akbar', 'lay1')}
                  >
                    <span className='odd' style={{ color: 'black' }}>
                      {getData('Akbar').lay1}
                    </span>
                  </button>
                </div>
                <div>{getExposureForEntity('Akbar')?.exposure || 0}</div>
              </div>
              <div className='col-4 text-center'>
                <div>
                  <span className='d-block'>
                    <b>
                      <span className='text-danger'>C.</span>
                      Anthony
                    </b>
                  </span>
                </div>
                <div
                  className={`aaa-button clearfix ${
                    isLocked ? 'suspended ' : ''
                  }`}
                >
                  <button
                    className='back'
                    onClick={() => setEvent('Anthony', 'back1')}
                  >
                    <span className='odd' style={{ color: 'black' }}>
                      {getData('Anthony').back1}
                    </span>
                  </button>{' '}
                  <button
                    className='lay'
                    onClick={() => setEvent('Anthony', 'lay1')}
                  >
                    <span className='odd' style={{ color: 'black' }}>
                      {getData('Anthony').lay1}
                    </span>
                  </button>
                </div>
                <div>{getExposureForEntity('Anthony')?.exposure || 0}</div>
              </div>
            </div>
          </div>
          <div className='row row5 m-t-10'>
            <div className='col-4'>
              <div className='aaa-content'>
                <div className='text-right'>
                  <div className='info-block'>
                    <div className='text-right'>
                      <Popover>
                        <div id='min-max-info' className='min-max-box'>
                          <p>Min: 100</p>
                          <p>Max: 25000</p>
                        </div>
                      </Popover>
                    </div>
                  </div>
                </div>
                <div className='text-center'>
                  <div>
                    <b>{getData('Even').back1}</b>
                  </div>
                  <div className='m-b-5 m-t-5'>
                    <button
                      className={`text-uppercase btn-theme ${
                        isLocked ? 'suspended ' : ''
                      }`}
                      onClick={() => setEvent('Even')}
                    >
                      <b>Even</b>
                    </button>
                  </div>
                  <div>{getExposureForEntity('Even')?.exposure || 0}</div>
                </div>
                <div className='text-center m-t-10'>
                  <div>
                    <b>{getData('Odd').back1}</b>
                  </div>
                  <div className='m-b-5 m-t-5'>
                    <button
                      className={`text-uppercase btn-theme ${
                        isLocked ? 'suspended ' : ''
                      }`}
                      onClick={() => setEvent('Odd')}
                    >
                      <b>Odd</b>
                    </button>
                  </div>
                  <div>{getExposureForEntity('Odd')?.exposure || 0}</div>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='aaa-content'>
                <div className='text-right'>
                  <div className='info-block'>
                    <div className='text-right'>
                      <Popover>
                        <div id='min-max-info' className='min-max-box'>
                          <p>Min: 100</p>
                          <p>Max: 25000</p>
                        </div>
                      </Popover>
                    </div>
                  </div>
                </div>
                <div className='text-center'>
                  <div>
                    <b>{getData('Red').back1}</b>
                  </div>
                  <div className='m-b-5 m-t-5'>
                    <button
                      className={`text-uppercase btn-theme card-red ${
                        isLocked ? 'suspended ' : ''
                      }`}
                      onClick={() => setEvent('Red')}
                    >
                      <div className='card-icon'>
                        <Box display='flex'>
                          <Image
                            boxSize='25px'
                            src={require('../../../assets/img/casino/diamond.png')}
                          />
                          <Image
                            boxSize='25px'
                            src={require('../../../assets/img/casino/heart.png')}
                          />
                        </Box>
                      </div>
                    </button>
                  </div>
                  <div>{getExposureForEntity('Red')?.exposure || 0}</div>
                </div>
                <div className='text-center m-t-10'>
                  <div>
                    <b>{getData('Black').back1}</b>
                  </div>
                  <div className='m-b-5 m-t-5'>
                    <button
                      className={`text-uppercase btn-theme card-black text-center ${
                        isLocked ? 'suspended ' : ''
                      }`}
                      onClick={() => setEvent('Black')}
                    >
                      <div className='card-icon'>
                        <Box display='flex'>
                          <Image
                            boxSize='25px'
                            src={require('../../../assets/img/casino/club.png')}
                          />
                          <Image
                            boxSize='25px'
                            src={require('../../../assets/img/casino/spade.png')}
                          />
                        </Box>
                      </div>
                    </button>
                  </div>
                  <div>{getExposureForEntity('Black')?.exposure || 0}</div>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='aaa-content'>
                <div className='text-right'>
                  <div className='info-block'>
                    <div className='text-right'>
                      <Popover>
                        <div id='min-max-info' className='min-max-box'>
                          <p>Min: 100</p>
                          <p>Max: 100000</p>
                        </div>
                      </Popover>
                    </div>
                  </div>
                </div>
                <div className='text-center'>
                  <div>
                    <b>{getData('Under 7').back1}</b>
                  </div>
                  <div className='m-b-5 m-t-5'>
                    <button
                      className={`text-uppercase btn-theme ${
                        isLocked ? 'suspended ' : ''
                      }`}
                      onClick={() => setEvent('Under 7')}
                    >
                      <b>Under 7</b>
                    </button>
                  </div>
                  <div>{getExposureForEntity('UNDER 7')?.exposure || 0}</div>
                </div>
                <div className='text-center m-t-10'>
                  <div>
                    <b>{getData('Over 7').back1}</b>
                  </div>
                  <div className='m-b-5 m-t-5'>
                    <button
                      className={`text-uppercase btn-theme ${
                        isLocked ? 'suspended ' : ''
                      }`}
                      onClick={() => setEvent('Over 7')}
                    >
                      <b>Over 7</b>
                    </button>
                  </div>
                  <div>{getExposureForEntity('OVER 7')?.exposure || 0}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='card-content aaa-content m-t-10'>
            <div className='row'>
              <div className='col-12'>
                <div className='text-right'>
                  <div className='info-block'>
                    <div className='text-right'>
                      <Popover>
                        <div id='min-max-info' className='min-max-box'>
                          <p>Min: 100</p>
                          <p>Max: 50000</p>
                        </div>
                      </Popover>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 text-center'>
                <b>12.00</b>
              </div>
            </div>
            <div className='row m-t-10'>
              <div className='col-12 text-center' style={{ display: 'flex' }}>
                {allCard.map(({ id, img }) => (
                  <Box
                    onClick={() => setEvent(id)}
                    textAlign='center'
                    cursor='pointer'
                    mx='2'
                    className={`aaa-grid-item ${isLocked ? 'suspended ' : ''}`}
                  >
                    {/*  {isLocked && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      bg="blackAlpha.800"
                      w="42px"
                      h="70%"
                      top="-2px"
                      position="absolute"
                    >
                      <FaLock color="white" />
                    </Box>
                  )} */}
                    <img className='cardimg' src={img} />
                    {!isLocked && (getExposureForEntity(id)?.exposure || 0)}
                  </Box>
                ))}
              </div>
            </div>
          </div>
        </div>
        <LastResults eventId={gameName} />
      </Box>
    </Box>
  );
}
