import { CRICKET_SERVICE_URL, getToken } from '../../utils/constants';
import { AXIOS_INSTANCE } from '..';
export const getAllCricketMatchListAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/all-matches/list`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getAdminUserBookCricketAPI = async (cricketId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/admin/user-book?cricketId=${cricketId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getAdminBookMakerCricketAPI = async (cricketId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/admin/bookmaker-book?cricketId=${cricketId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getAdminPlExposureCricketAPI = async (cricketId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/admin/admin-pl?cricketId=${cricketId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getClientBetsCricketAPI = async (cricketId, betStatus) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/admin/client-bets/${cricketId}`,
      {
        params: {
          eventId: cricketId,
          betStatus,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const betAndFancyLocksCricketAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${CRICKET_SERVICE_URL}/cricket/admin/update-status`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getBetLockUsersCricketAPI = async (cricketId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/admin/bet-lock-users`,
      {
        params: {
          cricketId,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};

export const getMatchDetailsCricketAPI = async (eventId, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/match-details`,
      {
        params: {
          eventId,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const handleBetAPI = async (betId, status) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${CRICKET_SERVICE_URL}/cricket/admin/client-bets/${betId}/status`,
      {},
      {
        params: {
          betStatus: status,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getBookMakerBookCricketAPI = async (cricketId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/admin/bookmaker-book?cricketId=${cricketId}`,
      {
        params: {
          cricketId,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getUserBookCricketAPI = async (cricketId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/admin/user-book?cricketId=${cricketId}`,
      {
        params: {
          cricketId,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getBetActiveDeactiveCricketAPI = async (cricketId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/admin/bet-active-deactive?cricketId=${cricketId}`,
      {
        params: {
          cricketId,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
