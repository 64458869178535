export const getService = (path) => {
  let service;
  let port;

  if (path.includes("teenpatti")) {
    service = "s1";
    port = 9002;
  }
  if (path.includes("dragon-tiger")) {
    service = "s2";
    port = 9003;
  }
  if (path.includes("card-32")) {
    service = "s3";
    port = 9004;
  }
  if (path.includes("lucky7")) {
    service = "s3";
    port = 9004;
  }
  if (path.includes("andar-bahar")) {
    service = "s4";
    port = 9005;
  }
  if (path.includes("race")) {
    service = "s4";
    port = 9005;
  }

  if (path.includes("poker")) {
    service = "s5";
    port = 9006;
  }
  if (path.includes("baccarat")) {
    service = "s5";
    port = 9006;
  }
  if (path.includes("cricket")) {
    service = "s6";
    port = 9007;
  }
  if (path.includes("worli")) {
    service = "s6";
    port = 9007;
  }
  if (path.includes("cn")) {
    service = "s7";
    port = 9008;
  }

  return { service, port };
};
