import { AXIOS_INSTANCE } from '.';
import { BASE_URL_BANKING } from '../utils/constants';

export const getSignedUrlAPI = async (key) => {
  try {
    const res = await AXIOS_INSTANCE.get(`${BASE_URL_BANKING}/acc/signed-url`, {
      params: {
        fileKey: key,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getBankDetailsAPI = async (page, size) => {
  try {
    const data = await AXIOS_INSTANCE.get(
      `${BASE_URL_BANKING}/admin/payment-details?pageNumber=${page}&size=${size}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const uploadDocApi = async (payload) => {
  try {
    const data = await AXIOS_INSTANCE.post(
      `${BASE_URL_BANKING}/acc/upload-doc`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const addPaymentDetails = async (formData) => {
  try {
    const data = await AXIOS_INSTANCE.post(
      `${BASE_URL_BANKING}/admin/payment-details`,
      formData
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const updatePaymentDetails = async (paymentDetailsId, payload) => {
  try {
    const data = AXIOS_INSTANCE.put(
      `${BASE_URL_BANKING}/admin/payment-details/${paymentDetailsId}`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
