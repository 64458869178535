import { Box, Button, Grid, GridItem, Text, WrapItem } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import CustomInput from "../../components/UI/CustomInput";
import CustomSelect from "../../components/UI/CustomSelect";
import CustomTable from "../../components/UI/Table";
import { generalReportAPI } from "../../service/AuthService";

export default function GeneralReport() {
  const [reportType, setReportType] = React.useState("GENERAL_REPORT");
  const [generalReportData, setGeneralReportData] = React.useState([]);
  const [data, setData] = React.useState([{ leftHalf: {}, rightHalf: {} }]);
  const getGeneralReport = async () => {
    const { response, message, code } = await generalReportAPI(reportType);
    if (code == 200) {
      setGeneralReportData(response);
      let data_ = [{ leftHalf: {}, rightHalf: {} }];
      response?.generalReportAPI?.forEach((item, index) => {
        if (index % 2 == 0) {
          data_.push({
            leftHalf: item,
          });
        } else {
          data_.push({
            rightHalf: item,
          });
        }
      });
      setData(data_);
    }
  };
  useEffect(() => {
    getGeneralReport();
  }, []);
  return (
    <div className="main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 main-container">
            <div>
              <div className="listing-grid">
                <div className="detail-row">
                  <h2>General Reports</h2>
                  <div id="form-search">
                    <div className="m-t-20">
                      <div className="row">
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="search-report_type">
                              Select Type:
                            </label>
                            <select
                              className="form-control"
                              name="search-report_type"
                              id="search-report_type"
                              value={reportType}
                              onChange={(e) => setReportType(e.target.value)}
                            >
                              <option value="GENERAL_REPORT">
                                General Report
                              </option>
                              <option value="CREDIT_REFERENCE_REPORT">
                                Credit Reference Report
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <label style={{ width: "100%" }}>&nbsp;</label>
                          <button
                            type="submit"
                            className="btn btn-diamond"
                            id="loaddata"
                            onClick={getGeneralReport}
                            style={{backgroundColor:'#102A6D'}}
                          >
                            Load
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive data-table">
                  <table
                    id="example"
                    className="custom-table table table-striped"
                  >
                    <thead>
                      <tr style={{backgroundColor:'#C9C9C9'}}>
                        <th>Sr. No</th>
                        <th>Name</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody id="table_body">
                      {generalReportData?.generalReportData?.map(
                        (item, index) => (
                          <tr style={{backgroundColor:'#FFFFFF'}}>
                            <td>{index + 1}</td>
                            <td>{item.userName}</td>
                            <td className="amount-field">{item.amount}</td>
                          </tr>
                        )
                      )}

                      <tr>
                        <td colSpan="100%" />
                      </tr>
                      <tr>
                        <td />
                        <td>
                          <b>
                            Upper Level Total
                            <b />
                          </b>
                        </td>
                        <td className="amount-field">
                          <b>{generalReportData?.upperLevelTotalAmount}</b>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="100%" />
                      </tr>
                      <tr>
                        <th />
                        <th>General Total</th>
                        <th className="amount-field">
                          {generalReportData?.totalAmount}
                        </th>
                        <th />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  /* return (
    <Box p="5">
      <Text fontSize="2xl">General Report</Text>
      <WrapItem m="2" alignItems="flex-end">
        <CustomSelect
          placeholder="Select"
          label="Select Type"
          size="md"
          options={[
            { label: "General Report", value: "creditRef" },
            { label: "Credit Refrance Report", value: "creditRef" },
          ]}
        />
        <Button ml="5">Load</Button>
      </WrapItem>
      <Grid gap={2} templateColumns="repeat(2,1fr)">
        <GridItem>
          <CustomTable
            columns={[
              {
                name: "Sr.No",
                id: "gameName",
              },
              {
                name: "Name",
                id: "gameType",
              },
              {
                name: "Amount",
                id: "profitLoss",
              },
            ]}
            rows={[]}
          />
        </GridItem>
        <GridItem>
          <CustomTable
            columns={[
              {
                name: "Sr.No",
                id: "gameName",
              },
              {
                name: "Name",
                id: "gameType",
              },
              {
                name: "Amount",
                id: "profitLoss",
              },
            ]}
            rows={[]}
          />
        </GridItem>
      </Grid>
    </Box>
  ); */
}
