import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Flip, Flipr } from '../../../components/Flip';
import {
  getCasinoLastTenResultsAPI,
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from '../../../service/casino';

import CardDrawer from '../../../components/CardDrawer/CardDrawer';
import CasinoVideoRules from '../../../components/CasinoVideoRules';
import { useMemo } from 'react';
import Iframe from 'react-iframe';
import IframeComponent from '../../../components/Iframe';
import LastResults from '../../../components/LastResults';
import { setGameKey } from '../../../redux/features/gameSlice';
import { getService } from '../../../utils/commonFunctions';
import { useLocation } from 'react-router-dom';

export default function TeenPatti() {
  const location = useLocation();
  const odds_ = useSelector((state) => state.gameData.teenPatti20Odds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [selectedEventForBet, setSelectedEventForBet] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const [lastTenResult, setLastTenResult] = useState([]);
  const userData = useSelector((state) => state.account.userData);
  const authDetails = useSelector((state) => state.account.authDetails);
  const [plExposure, setPlExposure] = useState(null);
  const toast = useToast();
  const gameName = 'TEEN_20';
  const dispatch = useDispatch();
  const CustomButton = ({ text, item, ml, size, children, ...rest }) => (
    <Button
      size={'lg'}
      my='0'
      ml={ml}
      display='flex'
      justifyContent='center'
      alignItems='center'
      className='pattiButton'
      onClick={isLocked ? null : rest.onClick}
      flexDirection='column'
    >
      {isLocked ? <FaLock /> : item ? item : text}
      {!isLocked && <Text className='expos-text'>{children}</Text>}
    </Button>
  );
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };
  const placeBet = async () => {
    try {
      let payload = {};
      payload.amount = parseInt(selectedAmount);
      payload.casinoGames = gameName;
      payload.profit = getProfit();
      payload.loss = parseInt(selectedAmount);
      payload.back = selectedEventForBet.back1;
      payload.selectionId = selectedEventForBet.selectionId;
      payload.marketId = odds.marketId;
      payload.runnerName = selectedEventForBet.runnerName;
      const { response, code } = await placeCasinoBetAPI(
        payload,
        userData.token
      );
      if (code == 200) {
        toast({
          title: 'Bet Placed',
          description: 'Your bet has been placed successfully',
          status: 'success',
        });
      } else {
        toast({
          description: response,
          status: 'error',
        });
      }
    } catch (e) {
      toast({
        description: e,
        status: 'error',
      });
    }
  };
  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const getEntity = (entity) => {
    return entity == '1' ? 'A' : 'B';
  };
  const setEvent = (id) => {
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = selectedEventForBet.back1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = 'back';

    dispatch({
      type: 'gameData/setSelectedEventForBet',
      payload,
    });
  };

  useEffect(() => {
    dispatch(setGameKey('teenPatti20'));
  }, [dispatch]);

  useEffect(() => {
    if (odds?.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);

  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id
    );
    if (res && res.code == 200) {
      setPlExposure(res.response);
    }
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data = plExposure?.find((item) =>
        item?.casinoPLExposure?.find(
          (exposure) => exposure.selectionName == selectionName
        )
      );

      return data ? data : null;
    }
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);
  return (
    <Box
      w='100%'
      display='flex'
      flexDirection={{
        base: 'column',
        md: 'row',
      }}
    >
      <Box
        w={{
          base: '100%',
          md: '70%',
        }}
      >
        <Box h='60vh' mb='2' pos='relative' bg='black'>
          {odds?.cards && (
            <CardDrawer
              multiLevel={true}
              cards={[odds?.cards[0], odds?.cards[1]]}
              labels={['PLAYER A', 'PLAYER B']}
            />
          )}

          <div className='market-title'>20-20 TEENPATTI</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3030`}
          />
          <Box
            bottom='0'
            p='2'
            pos='absolute'
            right='2'
            display='flex'
            bg='black'
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules
            custom={
              // <div className="rules-body">
              //   <div className="rules-section snipcss0-2-2-4">
              //     <ul className="pl-2 pr-2 list-style snipcss0-3-4-5">
              //       <li className="snipcss0-4-5-6">
              //         The game is played with a regular 52 cards single deck,
              //         between 2 players A and B.
              //       </li>
              //       <li className="snipcss0-4-5-7">
              //         Each player will receive 3 cards.
              //       </li>
              //       <li className="snipcss0-4-5-8">
              //         <b className="snipcss0-5-8-9">
              //           Rules of regular teenpatti winner
              //         </b>
              //       </li>
              //     </ul>
              //     <div className="snipcss0-3-4-10">
              //       <img
              //         src="https://sitethemedata.com/casino-new-rules-images/teen20b.jpg"
              //         className="snipcss0-4-10-11"
              //       />
              //     </div>
              //   </div>

              //   <div className="snipcss0-1-1-12">
              //     <div className="rules-section snipcss0-2-12-13">
              //       <h6 className="rules-highlight snipcss0-3-13-14">
              //         Rules of 3 baccarat
              //       </h6>
              //       <p className="snipcss0-3-13-15">
              //         There are 3 criteria for winning the 3 Baccarat .
              //       </p>
              //       <h7 className="rules-sub-highlight snipcss0-3-13-16">
              //         First criteria:
              //       </h7>
              //       <ul className="pl-2 pr-2 list-style snipcss0-3-13-17">
              //         <li className="snipcss0-4-17-18">
              //           Game having trio will win,
              //         </li>
              //         <li className="snipcss0-4-17-19">
              //           If both game has trio then higher trio will win.
              //         </li>
              //         <li className="snipcss0-4-17-20">
              //           Ranking of trio from high to low.
              //           <div className="pl-2 pr-2 snipcss0-5-20-21">1,1,1</div>
              //           <div className="pl-2 pr-2 snipcss0-5-20-22">K,K,K</div>
              //           <div className="pl-2 pr-2 snipcss0-5-20-23">Q,Q,Q</div>
              //           <div className="pl-2 pr-2 snipcss0-5-20-24">J,J,J</div>
              //           <div className="pl-2 pr-2 snipcss0-5-20-25">
              //             10,10,10
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-20-26">9,9,9</div>
              //           <div className="pl-2 pr-2 snipcss0-5-20-27">8,8,8</div>
              //           <div className="pl-2 pr-2 snipcss0-5-20-28">7,7,7</div>
              //           <div className="pl-2 pr-2 snipcss0-5-20-29">6,6,6</div>
              //           <div className="pl-2 pr-2 snipcss0-5-20-30">5,5,5</div>
              //           <div className="pl-2 pr-2 snipcss0-5-20-31">4,4,4</div>
              //           <div className="pl-2 pr-2 snipcss0-5-20-32">3,3,3</div>
              //           <div className="pl-2 pr-2 snipcss0-5-20-33">2,2,2</div>
              //         </li>
              //         <li className="snipcss0-4-17-34">
              //           If none of the game have got trio then second criteria
              //           will apply.
              //         </li>
              //       </ul>
              //       <h7 className="rules-sub-highlight snipcss0-3-13-35">
              //         Second criteria:
              //       </h7>
              //       <ul className="pl-2 pr-2 list-style snipcss0-3-13-36">
              //         <li className="snipcss0-4-36-37">
              //           Game having all the three face card will win.
              //         </li>
              //         <li className="snipcss0-4-36-38">
              //           Here JACK, QUEEN AND KING are named face card.
              //         </li>
              //         <li className="snipcss0-4-36-39">
              //           if both the game have all three face cards then game
              //           having highest face card will win.
              //         </li>
              //         <li className="snipcss0-4-36-40">
              //           Ranking of face card from High to low :
              //           <div className="pl-2 pr-2 snipcss0-5-40-41">
              //             Spade King
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-40-42">
              //             Heart King
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-40-43">
              //             Club King
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-40-44">
              //             Diamond King
              //           </div>
              //         </li>
              //         <li className="snipcss0-4-36-45">
              //           Same order will apply for Queen (Q) and Jack (J) also .
              //         </li>
              //         <li className="snipcss0-4-36-46">
              //           If second criteria is also not applicable, then 3rd
              //           criteria will apply .
              //         </li>
              //       </ul>
              //       <h7 className="rules-sub-highlight snipcss0-3-13-47">
              //         3rd criteria:
              //       </h7>
              //       <ul className="pl-2 pr-2 list-style snipcss0-3-13-48">
              //         <li className="snipcss0-4-48-49">
              //           Game having higher baccarat value will win .
              //         </li>
              //         <li className="snipcss0-4-48-50">
              //           For deciding baccarat value we will add point value of
              //           all the three cards
              //         </li>
              //         <li className="snipcss0-4-48-51">
              //           Point value of all the cards :
              //           <div className="pl-2 pr-2 snipcss0-5-51-52">1 = 1</div>
              //           <div className="pl-2 pr-2 snipcss0-5-51-53">2 = 2</div>
              //           <div className="pl-2 pr-2 snipcss0-5-51-54">To</div>
              //           <div className="pl-2 pr-2 snipcss0-5-51-55">9 = 9</div>
              //           <div className="pl-2 pr-2 snipcss0-5-51-56">
              //             10, J ,Q, K has zero (0) point value .
              //           </div>
              //         </li>
              //       </ul>
              //       <p className="snipcss0-3-13-57">
              //         <b className="snipcss0-4-57-58">Example 1st:</b>
              //       </p>
              //       <ul className="pl-2 pr-2 list-style snipcss0-3-13-59">
              //         <li className="snipcss0-4-59-60">
              //           Last digit of total will be considered as baccarat value
              //           <div className="pl-2 pr-2 snipcss0-5-60-61">
              //             2,5,8 =
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-60-62">
              //             2+5+8 =15 here last digit of total is 5 , So baccarat
              //             value is 5.
              //           </div>
              //         </li>
              //       </ul>
              //       <p className="snipcss0-3-13-63">
              //         <b className="snipcss0-4-63-64">Example 2nd :</b>
              //       </p>
              //       <ul className="pl-2 pr-2 list-style snipcss0-3-13-65">
              //         <li className="snipcss0-4-65-66">1,3,K</li>
              //         <li className="snipcss0-4-65-67">
              //           1+3+0 = 4 here total is in single digit so we will take
              //           this single digit 4 as baccarat value
              //         </li>
              //       </ul>
              //       <p className="snipcss0-3-13-68">
              //         <b className="snipcss0-4-68-69">
              //           If baccarat value of both the game is equal then
              //           Following condition will apply :
              //         </b>
              //       </p>
              //       <p className="snipcss0-3-13-70">
              //         <b className="snipcss0-4-70-71">Condition 1 :</b>
              //       </p>
              //       <ul className="pl-2 pr-2 list-style snipcss0-3-13-72">
              //         <li className="snipcss0-4-72-73">
              //           Game having more face card will win.
              //         </li>
              //         <li className="snipcss0-4-72-74">
              //           Example : Game A has 3,4,k and B has 7,J,Q then game B
              //           will win as it has more face card then game A .
              //         </li>
              //       </ul>
              //       <p className="snipcss0-3-13-75">
              //         <b className="snipcss0-4-75-76">Condition 2 :</b>
              //       </p>
              //       <ul className="pl-2 pr-2 list-style snipcss0-3-13-77">
              //         <li className="snipcss0-4-77-78">
              //           If Number of face card of both the game are equal then
              //           higher value face card game will win.
              //         </li>
              //         <li className="snipcss0-4-77-79">
              //           Example : Game A has 4,5,K (K Spade ) and Game B has
              //           9,10,K ( K Heart ) here baccarat value of both the game
              //           is equal (9 ) and both the game have same number of face
              //           card so game A will win because It has got higher value
              //           face card then Game B .
              //         </li>
              //       </ul>
              //       <p className="snipcss0-3-13-80">
              //         <b className="snipcss0-4-80-81">Condition 3 :</b>
              //       </p>
              //       <ul className="pl-2 pr-2 list-style snipcss0-3-13-82">
              //         <li className="snipcss0-4-82-83">
              //           If baccarat value of both the game is equal and none of
              //           game has got face card then in this case Game having
              //           highest value point card will win .
              //         </li>
              //         <li className="snipcss0-4-82-84">
              //           Value of Point Cards :
              //           <div className="pl-2 pr-2 snipcss0-5-84-85">
              //             Ace = 1
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-84-86">2 = 2</div>
              //           <div className="pl-2 pr-2 snipcss0-5-84-87">3 = 3</div>
              //           <div className="pl-2 pr-2 snipcss0-5-84-88">4 = 4</div>
              //           <div className="pl-2 pr-2 snipcss0-5-84-89">5 = 5</div>
              //           <div className="pl-2 pr-2 snipcss0-5-84-90">6 = 6</div>
              //           <div className="pl-2 pr-2 snipcss0-5-84-91">7 = 7</div>
              //           <div className="pl-2 pr-2 snipcss0-5-84-92">8 = 8</div>
              //           <div className="pl-2 pr-2 snipcss0-5-84-93">9 = 9</div>
              //           <div className="pl-2 pr-2 snipcss0-5-84-94">
              //             10 = 0 (Zero )
              //           </div>
              //         </li>
              //         <li className="snipcss0-4-82-95">
              //           Example : GameA: 1,6,10 And GameB: 7,10,10
              //         </li>
              //         <li className="snipcss0-4-82-96">
              //           here both the game have same baccarat value . But game B
              //           will win as it has higher value point card i.e. 7 .
              //         </li>
              //       </ul>
              //       <p className="snipcss0-3-13-97">
              //         <b className="snipcss0-4-97-98">Condition 4 :</b>
              //       </p>
              //       <ul className="pl-2 pr-2 list-style snipcss0-3-13-99">
              //         <li className="snipcss0-4-99-100">
              //           If baccarat value of both game is equal and none of game
              //           has got face card and high point card of both the game
              //           is of equal point value , then suits of both high card
              //           will be compared
              //         </li>
              //         <li className="snipcss0-4-99-101">
              //           Example :
              //           <div className="pl-2 pr-2 snipcss0-5-101-102">
              //             Game A : 1(Heart) ,2(Heart) ,5(Heart)
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-101-103">
              //             Game B : 10 (Heart) , 3 (Diamond ) , 5 (Spade )
              //           </div>
              //         </li>
              //         <li className="snipcss0-4-99-104">
              //           Here Baccarat value of both the game (8) is equal . and
              //           none of game has got face card and point value of both
              //           game's high card is equal so by comparing suits of both
              //           the high card ( A 5 of Heart , B 5 of spade ) game B is
              //           declared 3 Baccarat winner .
              //         </li>
              //         <li className="snipcss0-4-99-105">
              //           Ranking of suits from High to low :
              //           <div className="pl-2 pr-2 snipcss0-5-105-106">
              //             Spade
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-105-107">
              //             Heart
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-105-108">Club</div>
              //           <div className="pl-2 pr-2 snipcss0-5-105-109">
              //             Diamond
              //           </div>
              //         </li>
              //       </ul>
              //     </div>
              //   </div>
              //   <div className="snipcss0-1-1-110">
              //     <div className="rules-section snipcss0-2-110-111">
              //       <h6 className="rules-highlight snipcss0-3-111-112">
              //         Rules of Total :
              //       </h6>
              //       <ul className="pl-2 pr-2 list-style snipcss0-3-111-113">
              //         <li className="snipcss0-4-113-114">
              //           It is a comparison of total of all three cards of both
              //           the games.
              //         </li>
              //         <li className="snipcss0-4-113-115">
              //           Point value of all the cards for the bet of total
              //           <div className="pl-2 pr-2 snipcss0-5-115-116">
              //             Ace = 1
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-115-117">
              //             2 = 2
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-115-118">
              //             3 = 3
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-115-119">
              //             4 = 4
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-115-120">
              //             5 = 5
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-115-121">
              //             6 = 6
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-115-122">
              //             7 = 7
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-115-123">
              //             8 = 8
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-115-124">
              //             9 = 9
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-115-125">
              //             10 = 10
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-115-126">
              //             Jack = 11
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-115-127">
              //             Queen = 12
              //           </div>
              //           <div className="pl-2 pr-2 snipcss0-5-115-128">
              //             King = 13
              //           </div>
              //         </li>
              //         <li className="snipcss0-4-113-129">
              //           suits doesn't matter
              //         </li>
              //         <li className="snipcss0-4-113-130">
              //           If total of both the game is equal , it is a Tie .
              //         </li>
              //         <li className="snipcss0-4-113-131">
              //           If total of both the game is equal then half of your bet
              //           amount will returned.
              //         </li>
              //       </ul>
              //     </div>
              //   </div>
              //   <div className="snipcss0-1-1-132">
              //     <div className="rules-section snipcss0-2-132-133">
              //       <h6 className="rules-highlight snipcss0-3-133-134">
              //         Rules of Pair Plus :
              //       </h6>
              //       <ul className="pl-2 pr-2 list-style snipcss0-3-133-135">
              //         <li className="snipcss0-4-135-136">
              //           This bet provides multiple option to win a price .
              //         </li>
              //         <li className="snipcss0-4-135-137">Option 1 : Pair</li>
              //         <li className="snipcss0-4-135-138">
              //           If you got pair you will get equal value return of your
              //           betting amount .
              //         </li>
              //         <li className="snipcss0-4-135-139">Option 2 : Flush</li>
              //         <li className="snipcss0-4-135-140">
              //           If you have all three cards of same suits you will get 4
              //           times return of your betting amount .
              //         </li>
              //         <li className="snipcss0-4-135-141">
              //           Option 3 : Straight
              //         </li>
              //         <li className="snipcss0-4-135-142">
              //           If you have straight ( three cards in sequence eg :
              //           4,5,6 eg: J,Q,K ) (but king ,Ace ,2 is not a straight )
              //           you will get six times return of your betting amount .
              //         </li>
              //         <li className="snipcss0-4-135-143">Option 4 : Trio</li>
              //         <li className="snipcss0-4-135-144">
              //           If you have got all the cards of same rank ( eg: 4,4,4
              //           J,J,J ) you will get 30 times return of your betting
              //           amount .
              //         </li>
              //         <li className="snipcss0-4-135-145">
              //           Option 5 : Straight Flush
              //         </li>
              //         <li className="snipcss0-4-135-146">
              //           If you have straight of all three cards of same suits (
              //           Three cards in sequence eg: 4,5,6 ) ( but King ,Ace ,2
              //           is not straight ) you will get 40 times return of your
              //           betting amount .
              //         </li>
              //         <li className="snipcss0-4-135-147">
              //           Note : If you have trio then you will receive price of
              //           trio only , In this case you will not receive price of
              //           pair .
              //         </li>
              //         <li className="snipcss0-4-135-148">
              //           If you have straight flush you will receive price of
              //           straight flush only , In this case you will not receive
              //           price of straigh and flush .
              //         </li>
              //         <li className="snipcss0-4-135-149">
              //           It means you will receive only one price whichever is
              //           higher .
              //         </li>
              //       </ul>
              //     </div>
              //   </div>
              //   <div className="snipcss0-1-1-150">
              //     <div className="rules-section snipcss0-2-150-151">
              //       <h6 className="rules-highlight snipcss0-3-151-152">
              //         Rules of Color :
              //       </h6>
              //       <ul className="pl-2 pr-2 list-style snipcss0-3-151-153">
              //         <li className="snipcss0-4-153-154">
              //           This is a bet for having more cards of red or Black
              //           (Heart and Diamond are named RED , Spade and Club are
              //           named BLACK ).
              //         </li>
              //         <li className="snipcss0-4-153-155">
              //           <b className="snipcss0-5-155-156">NOTE :</b>
              //           For side bets you can place bets on either or both the
              //           players .
              //         </li>
              //         <li className="snipcss0-4-153-157">
              //           <b className="snipcss0-5-157-158">NOTE :</b>
              //           In case of a tie between the player A and Player B bets
              //           placed on player A and Player B (Main Bets ) will be
              //           returned ( Pushed ).
              //         </li>
              //       </ul>
              //     </div>
              //   </div>
              // </div>
              <div className='rules-body'>
                <img
                  src='https://sitethemedata.com/casino-new-rules-images/teen20b.jpg'
                  className='rules-body'
                />
              </div>
            }
          />
        </Box>
        <div>
          <div className='main-market m-t-0'>
            {/* removed "table-responsive" from this class */}
            <table className='table coupon-table table table-bordered '>
              <thead>
                <tr>
                  <th className='box-5'></th>
                  <th className='box-2 back-color'>BACK</th>
                  <th className='box-3 back-color'></th>
                </tr>
              </thead>
              <tbody>
                <tr className='bet-info'>
                  <td className='box-5'>
                    <b>Player A</b>
                  </td>
                  <td
                    className={`text-center box-2 back ${
                      isLocked ? 'suspendedtd' : ''
                    }`}
                  >
                    <button
                      onClick={() => setEvent('Player A')}
                      className='back'
                    >
                      <span className='d-block text-bold odd'>
                        <b>{getData('Player A').back1}</b>
                      </span>{' '}
                      <span className='d-block'>
                        {getExposureForEntity('Player A')?.exposure}
                      </span>
                    </button>
                  </td>
                  <td
                    className={`text-center box-3 back ${
                      isLocked ? 'suspendedtd' : ''
                    }`}
                  >
                    <button
                      onClick={() => setEvent('Pair plus A')}
                      className='back'
                    >
                      <span className='d-block text-bold odd'>
                        <b>Pair plus A</b>
                      </span>{' '}
                      <span className='d-block'>
                        {getData('Pair plus A').back1}
                      </span>
                    </button>
                  </td>
                </tr>
                <tr className='bet-info'>
                  <td className='box-5'>
                    <b>Player B</b>
                  </td>
                  <td
                    className={`text-center back box-2 ${
                      isLocked ? 'suspendedtd' : ''
                    }`}
                  >
                    <button
                      onClick={() => setEvent('Player B')}
                      className='back'
                    >
                      <span className='d-block text-bold odd'>
                        <b>{getData('Player B').back1}</b>
                      </span>{' '}
                      <span className='d-block'>
                        {getExposureForEntity('Player B')?.exposure}
                      </span>
                    </button>
                  </td>
                  <td
                    className={`text-center box-3 back  ${
                      isLocked ? 'suspendedtd' : ''
                    }`}
                  >
                    <button
                      onClick={() => setEvent('Pair plus B')}
                      className='back'
                    >
                      <span className='d-block text-bold odd'>
                        <b>Pair plus B</b>
                      </span>{' '}
                      <span className='d-block'>
                        {getData('Pair plus B').back1}
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* <Grid
          className="teenpatti-bg"
          p="2"
          gap={2}
          templateColumns="repeat(2,1fr)"
          display={{ base: "none", md: "grid" }}
        >
          <GridItem colSpan={1}>
            <Text color="red">Player A</Text>
          </GridItem>
          <GridItem colSpan={1} borderLeft="1px">
            <Text color="yellow" paddingLeft="5px">
              Player B
            </Text>
          </GridItem>
          <Grid fontSize="sm" gap={1} templateColumns="repeat(4,1fr)">
            <GridItem textAlign="center">
              <Text fontSize="small" className="pp-text">
                PLAYER A
              </Text>
              <CustomButton
                onClick={() => setEvent("Player A")}
                text={getData("Player A").back1}
              >
                {getExposureForEntity("Player A")?.exposure}
              </CustomButton>
            </GridItem>
            <GridItem textAlign="center">
              <Text fontSize="small" className="pp-text">
                3 BACCARAT A
              </Text>
              <CustomButton
                onClick={() => setEvent("3 Baccarat A")}
                text={getData("3 Baccarat A").back1}
              >
                {getExposureForEntity("3 Baccarat A")?.exposure}
              </CustomButton>
            </GridItem>
            <GridItem textAlign="center">
              <Text fontSize="small" className="pp-text">
                TOTAL A
              </Text>
              <CustomButton
                onClick={() => setEvent("Total A")}
                text={getData("Total A").back1}
              >
                {getExposureForEntity("Total A")?.exposure}
              </CustomButton>
            </GridItem>
            <GridItem textAlign="center">
              <Text fontSize="small" className="pp-text">
                PAIR PLUS A
              </Text>
              <CustomButton onClick={() => setEvent("Pair plus A")} text={"A"}>
                {getExposureForEntity("Pair plus A")?.exposure}
              </CustomButton>
            </GridItem>
            <GridItem colSpan={2} textAlign="center">
              <CustomButton
                size="md"
                onClick={() => setEvent("Black A")}
                item={
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Box display="flex">
                      <Image
                        boxSize="30px"
                        src={require("../../../assets/img/casino/spade.png")}
                      />
                      <Image
                        boxSize="30px"
                        src={require("../../../assets/img/casino/club.png")}
                      />
                    </Box>
                    <Text>{getData("Black A").back1}</Text>
                  </Box>
                }
              >
                {getExposureForEntity("Black A")?.exposure}
              </CustomButton>
            </GridItem>
            <GridItem colSpan={2} textAlign="center">
              <CustomButton
                size="md"
                onClick={() => setEvent("Red A")}
                item={
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Box display="flex">
                      <Image
                        boxSize="30px"
                        src={require("../../../assets/img/casino/diamond.png")}
                      />
                      <Image
                        boxSize="30px"
                        src={require("../../../assets/img/casino/heart.png")}
                      />
                    </Box>
                    <Text>{getData("Red A").back1}</Text>
                  </Box>
                }
              >
                {getExposureForEntity("Red A")?.exposure}
              </CustomButton>
            </GridItem>
          </Grid>
          <Grid
            fontSize="sm"
            gap={1}
            templateColumns="repeat(4,1fr)"
            borderLeft="1px"
            paddingLeft="10px"
          >
            <GridItem textAlign="center">
              <Text fontSize="small" className="pp-text">
                PLAYER B
              </Text>
              <CustomButton
                onClick={() => setEvent("Player B")}
                text={getData("Player B").back1}
              >
                {getExposureForEntity("Player B")?.exposure}
              </CustomButton>
            </GridItem>
            <GridItem textAlign="center">
              <Text fontSize="small" className="pp-text">
                3 BACCARAT B
              </Text>
              <CustomButton
                onClick={() => setEvent("3 Baccarat B")}
                text={getData("3 Baccarat B").back1}
              >
                {getExposureForEntity("3 Baccarat B")?.exposure}
              </CustomButton>
            </GridItem>
            <GridItem textAlign="center">
              <Text fontSize="small" className="pp-text">
                TOTAL B
              </Text>
              <CustomButton
                onClick={() => setEvent("Total B")}
                text={getData("Total B").back1}
              >
                {getExposureForEntity("Total B")?.exposure}
              </CustomButton>
            </GridItem>
            <GridItem textAlign="center">
              <Text fontSize="small" className="pp-text">
                PAIR PLUS B
              </Text>
              <CustomButton onClick={() => setEvent("Pair plus B")} text={"B"}>
                {getExposureForEntity("Pair plus B")?.exposure}
              </CustomButton>
            </GridItem>
            <GridItem colSpan={2} textAlign="center">
              <CustomButton
                size="md"
                onClick={() => setEvent("Black B")}
                item={
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Box display="flex">
                      <Image
                        boxSize="30px"
                        src={require("../../../assets/img/casino/spade.png")}
                      />
                      <Image
                        boxSize="30px"
                        src={require("../../../assets/img/casino/club.png")}
                      />
                    </Box>
                    <Text>{getData("Black B").back1}</Text>
                  </Box>
                }
              >
                {" "}
                {getExposureForEntity("Black B")?.exposure}
              </CustomButton>
            </GridItem>
            <GridItem colSpan={2} textAlign="center" paddingLeft="5px">
              <CustomButton
                onClick={() => setEvent("Red B")}
                size="md"
                item={
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Box display="flex">
                      <Image
                        boxSize="30px"
                        src={require("../../../assets/img/casino/heart.png")}
                      />
                      <Image
                        boxSize="30px"
                        src={require("../../../assets/img/casino/diamond.png")}
                      />
                    </Box>
                    <Text>{getData("Red B").back1}</Text>
                  </Box>
                }
              >
                {getExposureForEntity("Red B")?.exposure}
              </CustomButton>
            </GridItem>
          </Grid>
        </Grid> */}

        <Grid
          className='teenpatti-bg'
          paddingRight={2}
          templateRows='repeat(6,1fr)'
          display={{ base: 'block', md: 'none' }}
        >
          <Grid templateColumns={'repeat(3,1fr)'} mb={2}>
            <GridItem>
              <Text color='red'></Text>
            </GridItem>
            <GridItem textAlign='center'>
              <Text color='red'>Player A</Text>
            </GridItem>
            <GridItem textAlign='center' borderLeft='0px'>
              <Text color='yellow'>Player B</Text>
            </GridItem>
          </Grid>
          <Grid templateColumns={'repeat(3,1fr)'} mb={2}>
            <GridItem
              textAlign='left'
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-start'}
              bg='#00000047'
            >
              <Text fontSize='small' ml='1'>
                PLAYER
              </Text>
            </GridItem>
            <CustomButton
              onClick={() => setEvent('Player A')}
              text={getData('Player A').back1}
            />
            <CustomButton
              onClick={() => setEvent('Player B')}
              text={getData('Player B').back1}
              ml='2'
            />
          </Grid>
          <Grid templateColumns={'repeat(3,1fr)'} mb={2}>
            <GridItem
              textAlign='left'
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-start'}
              bg='#00000047'
            >
              <Text fontSize='small' ml='1'>
                3 Baccarat
              </Text>
            </GridItem>
            <CustomButton
              onClick={() => setEvent('3 Baccarat A')}
              text={getData('3 Baccarat A').back1}
            />
            <CustomButton
              onClick={() => setEvent('3 Baccarat B')}
              text={getData('3 Baccarat B').back1}
              ml='2'
            />
          </Grid>
          <Grid templateColumns={'repeat(3,1fr)'} mb={2}>
            <GridItem
              textAlign='left'
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-start'}
              bg='#00000047'
            >
              <Text fontSize='small' ml='1'>
                TOTAL
              </Text>
            </GridItem>
            <CustomButton
              onClick={() => setEvent('TOTAL A')}
              text={getData('TOTAL A').back1}
            />
            <CustomButton
              onClick={() => setEvent('TOTAL B')}
              text={getData('TOTAL B').back1}
              ml='2'
            />
          </Grid>
          <Grid templateColumns={'repeat(3,1fr)'} mb={2}>
            <GridItem
              textAlign='left'
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-start'}
              bg='#00000047'
            >
              <Text fontSize='small' ml='1'>
                Pair plus
              </Text>
            </GridItem>
            <CustomButton
              onClick={() => setEvent('Pair plus A')}
              text={getData('Pair plus A').back1}
            />
            <CustomButton
              onClick={() => setEvent('Pair plus B')}
              text={getData('Pair plus B').back1}
              ml='2'
            />
          </Grid>
          <Grid templateColumns={'repeat(3,1fr)'} mb={2}>
            <GridItem
              textAlign='center'
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              bg='#00000047'
            >
              <Box display='flex'>
                <Image
                  boxSize='30px'
                  src={require('../../../assets/img/casino/spade.png')}
                />
                <Image
                  boxSize='30px'
                  src={require('../../../assets/img/casino/club.png')}
                />
              </Box>
            </GridItem>
            <CustomButton
              onClick={() => setEvent('Black A')}
              text={getData('Black A').back1}
            />
            <CustomButton
              onClick={() => setEvent('Black B')}
              text={getData('Black B').back1}
              ml='2'
            />
          </Grid>
          <Grid templateColumns={'repeat(3,1fr)'} mb={2}>
            <GridItem
              textAlign='center'
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              bg='#00000047'
            >
              <Box display='flex'>
                <Image
                  boxSize='30px'
                  src={require('../../../assets/img/casino/diamond.png')}
                />
                <Image
                  boxSize='30px'
                  src={require('../../../assets/img/casino/heart.png')}
                />
              </Box>
            </GridItem>
            <CustomButton
              onClick={() => setEvent('Red A')}
              text={getData('Red A').back1}
            />
            <CustomButton
              onClick={() => setEvent('Red B')}
              text={getData('Red B').back1}
              ml='2'
            />
          </Grid>
        </Grid>
        <LastResults eventId={gameName} />
      </Box>
    </Box>
  );
}
