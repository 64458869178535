//entrypoint
import axios from "axios";
import { getToken } from "../utils/constants";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import store from "../redux/store";
const { dispatch } = store;
const generateFingerprint = async () => {
  const fingerprint = await FingerprintJS.load();
  const result = await fingerprint.get();
  const visitorId = result.visitorId;
  return visitorId;
};
export const AXIOS_INSTANCE_WITHOUT_INTERCEPTOR = axios.create();

AXIOS_INSTANCE_WITHOUT_INTERCEPTOR.interceptors.request.use(async (config) => {
  const token = getToken();
  config.headers["x-origin"] = "e1e5c80343ae5a0777eeb6488269cade";
  config.headers["x-browser-fingerprint"] = await generateFingerprint();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
AXIOS_INSTANCE_WITHOUT_INTERCEPTOR.interceptors.response.use(
  (response) => {
    const token = getToken();
    if (response.headers.authorization && token) {
      dispatch({
        type: "accountData/setToken",
        payload: response?.headers?.authorization
          .replace("Bearer", "")
          .slice(1),
      });
    }
    /*  console.log(response?.headers?.authorization.replace("Bearer", "")); */
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      dispatch({ type: "accountData/logOut" });
    }
    return error;
  }
);

export const AXIOS_INSTANCE = AXIOS_INSTANCE_WITHOUT_INTERCEPTOR;
