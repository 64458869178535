import React from "react";
// import "./index.css";
import moment from "moment";
import { getIndividualResultAPI } from "../../service/casino";
import { useEffect } from "react";
import { ASSETS_URL } from "../../utils/constants";
import { useCallback } from "react";
export default function CasinoResultModal({
  marketId,
  onClose,
  gameName,
  casinoKey,
}) {
  const [data, setData] = React.useState({});
  const getIndividualResult = useCallback(
    async (marketId) => {
      if (marketId) {
        const res = await getIndividualResultAPI(marketId, casinoKey);
        if (res.code === 200) {
          setData(res.response);
        }
      }
    },
    [casinoKey]
  );
  const gamesWhiteList = ["SUPEROVER", "CRICKET_V3"];

  useEffect(() => {
    getIndividualResult(marketId);
  }, [getIndividualResult, marketId]);

  return (
    <div
      id="__BVID__1590___BV_modal_content_"
      tabIndex={-1}
      className="modal-content snipcss-KKWcA"
    >
      <header id="__BVID__1590___BV_modal_header_" className="modal-header">
        <h5 id="__BVID__1590___BV_modal_title_" className="modal-title">
          {gameName}
        </h5>
        <button
          onClick={() => onClose(false)}
          type="button"
          aria-label="Close"
          className="close"
        >
          ×
        </button>
      </header>
      <div id="__BVID__1590___BV_modal_body_" className="modal-body">
        <div>
          <div className="casino-result-round">
            <div>Round-Id: {marketId}</div>
            <div>
              Match Time:
              <span>
                {moment(data?.casinoIndividualResultDTO?.createdAt).format(
                  "DD/MM/YYYY hh:mm"
                )}
              </span>
            </div>
          </div>
          {gamesWhiteList?.find((item) => item == casinoKey) ? (
            <div class="col-12 snipcss-cMDDd">
              <div class="text-right mt-3 score-head">
                {data?.casinoIndividualResultDTO?.casinoIndividualResultMaps?.map(
                  (game) => (
                    <span>
                      {game?.resultFor}:
                      {data?.casinoIndividualResultDTO?.casinoIndividualResultMaps
                        ?.find((item) => item.resultFor == game?.resultFor)
                        ?.result?.map((item) => String(item) + "|")}
                    </span>
                  )
                )}
              </div>
              <div class="mt-2">
                <h4>First Inning</h4>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>ENG</th>
                        <th class="text-center">1</th>
                        <th class="text-center">2</th>
                        <th class="text-center">3</th>
                        <th class="text-center">4</th>
                        <th class="text-center">5</th>
                        <th class="text-center">6</th>
                        <th class="text-center">Run/Over</th>
                        <th class="text-center">Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.casinoCricketResultDTO?.team1?.map((item) => (
                        <tr>
                          <td>{item.over}</td>
                          <td class="text-center">
                            <span class="">{item.run1}</span>
                          </td>
                          <td class="text-center">
                            <span class="">{item.run2}</span>
                          </td>
                          <td class="text-center">
                            <span class="">{item.run3}</span>
                          </td>
                          <td class="text-center">
                            <span class="">{item.run4}</span>
                          </td>
                          <td class="text-center">
                            <span class="">{item.run5}</span>
                          </td>
                          <td class="text-center">
                            <span class="">{item.run6}</span>
                          </td>
                          <td class="text-center">{item.runsPerOver}</td>
                          <td class="text-center">{item.score}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="mt-2">
                <h4>Second Inning</h4>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>RSA</th>
                        <th class="text-center">1</th>
                        <th class="text-center">2</th>
                        <th class="text-center">3</th>
                        <th class="text-center">4</th>
                        <th class="text-center">5</th>
                        <th class="text-center">6</th>
                        <th class="text-center">Run/Over</th>
                        <th class="text-center">Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.casinoCricketResultDTO?.team2?.map((item) => (
                        <tr>
                          <td>{item.over}</td>
                          <td class="text-center">
                            <span class="">{item.run1}</span>
                          </td>
                          <td class="text-center">
                            <span class="">{item.run2}</span>
                          </td>
                          <td class="text-center">
                            <span class="">{item.run3}</span>
                          </td>
                          <td class="text-center">
                            <span class="">{item.run4}</span>
                          </td>
                          <td class="text-center">
                            <span class="">{item.run5}</span>
                          </td>
                          <td class="text-center">
                            <span class="">{item.run6}</span>
                          </td>
                          <td class="text-center">{item.runsPerOver}</td>
                          <td class="text-center">{item.score}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="row row5">
              <div className="col-12 col-lg-7">
                <div className="casino-result-content">
                  {data?.casinoIndividualResultDTO?.casinoCardResultMaps?.map(
                    (game) => (
                      <>
                        <div className="casino-result-content-item text-center">
                          <div className="casino-result-cards">
                            {data?.casinoIndividualResultDTO?.casinoCardResultMaps?.find(
                              (winner) => winner?.resultFor == game?.resultFor
                            )?.isWinning && (
                              <div className="casino-result-cards-item">
                                {/* <img
                                  src="https://sitethemedata.com/v120/static/front/img/winner.png"
                                  className="winner-icon"
                                /> */}
                              </div>
                            )}
                            <div className="d-inline-block">
                              {/* <h4>{game?.resultFor}</h4> */}
                              {data?.casinoIndividualResultDTO?.casinoCardResultMaps
                                ?.find(
                                  (winner) =>
                                    winner?.resultFor == game?.resultFor
                                )
                                ?.result?.map(
                                  (card) =>
                                    card != "" && (
                                      <div className="casino-result-cards-item">
                                        <img src={ASSETS_URL + card + ".png"} />
                                      </div>
                                    )
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="casino-result-content-diveder"></div>
                      </>
                    )
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-5">
                <div className="casino-result-desc">
                  {data?.casinoIndividualResultDTO?.casinoIndividualResultMaps?.map(
                    (game) => (
                      <div className="casino-result-desc-item">
                        <div>{game?.resultFor}</div>
                        <div>
                          {data?.casinoIndividualResultDTO?.casinoIndividualResultMaps
                            ?.find((item) => item.resultFor == game?.resultFor)
                            ?.result?.map((item) => String(item) + " ")}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
