import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaLock,
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Flip, Flipr } from '../../../components/Flip';
import {
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from '../../../service/casino';
import CardDrawer from '../../../components/CardDrawer/CardDrawer';
import { ArrowLeftIcon } from '@chakra-ui/icons';
import './css/abj.css';
import { useMemo } from 'react';
import Iframe from 'react-iframe';
import IframeComponent from '../../../components/Iframe';
import CasinoVideoRules from '../../../components/CasinoVideoRules';
import { ASSETS_URL } from '../../../utils/constants';
import LastResults from '../../../components/LastResults';
import { setGameKey } from '../../../redux/features/gameSlice';
import { getService } from '../../../utils/commonFunctions';
import { useLocation } from 'react-router-dom';
export default function AndarBahar() {
  const location = useLocation();
  const odds_ = useSelector((state) => state.gameData.andarBahar20Odds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const [plExposure, setPlExposure] = useState(null);
  const authDetails = useSelector((state) => state.account.authDetails);
  const scrollRefPlayerA = React.useRef();
  const scrollRefPlayerB = React.useRef();
  const dispatch = useDispatch();
  const gameName = 'AB_20';
  const AndarCards = [
    {
      id: 'Ander A',
      img: require('../../../assets/img/casino/A.png'),
    },
    {
      id: 'Ander 2',
      img: require('../../../assets/img/casino/2.png'),
    },
    {
      id: 'Ander 3',
      img: require('../../../assets/img/casino/3.png'),
    },
    {
      id: 'Ander 4',
      img: require('../../../assets/img/casino/4.png'),
    },
    {
      id: 'Ander 5',
      img: require('../../../assets/img/casino/5.png'),
    },
    {
      id: 'Ander 6',
      img: require('../../../assets/img/casino/6.png'),
    },
    {
      id: 'Ander 7',
      img: require('../../../assets/img/casino/7.png'),
    },
    {
      id: 'Ander 8',
      img: require('../../../assets/img/casino/8.png'),
    },
    {
      id: 'Ander 9',
      img: require('../../../assets/img/casino/9.png'),
    },
    {
      id: 'Ander 10',
      img: require('../../../assets/img/casino/10.png'),
    },
    {
      id: 'Ander J',
      img: require('../../../assets/img/casino/11.png'),
    },
    {
      id: 'Ander Q',
      img: require('../../../assets/img/casino/12.png'),
    },
    {
      id: 'Ander K',
      img: require('../../../assets/img/casino/13.png'),
    },
  ];

  const BaharCards = [
    {
      id: 'Bahar A',
      img: require('../../../assets/img/casino/A.png'),
    },
    {
      id: 'Bahar 2',
      img: require('../../../assets/img/casino/2.png'),
    },
    {
      id: 'Bahar 3',
      img: require('../../../assets/img/casino/3.png'),
    },
    {
      id: 'Bahar 4',
      img: require('../../../assets/img/casino/4.png'),
    },
    {
      id: 'Bahar 5',
      img: require('../../../assets/img/casino/5.png'),
    },
    {
      id: 'Bahar 6',
      img: require('../../../assets/img/casino/6.png'),
    },
    {
      id: 'Bahar 7',
      img: require('../../../assets/img/casino/7.png'),
    },
    {
      id: 'Bahar 8',
      img: require('../../../assets/img/casino/8.png'),
    },
    {
      id: 'Bahar 9',
      img: require('../../../assets/img/casino/9.png'),
    },
    {
      id: 'Bahar 10',
      img: require('../../../assets/img/casino/10.png'),
    },
    {
      id: 'Bahar J',
      img: require('../../../assets/img/casino/11.png'),
    },
    {
      id: 'Bahar Q',
      img: require('../../../assets/img/casino/12.png'),
    },
    {
      id: 'Bahar K',
      img: require('../../../assets/img/casino/13.png'),
    },
  ];

  const handlePlayerClick = (toLeft = false, isPlayerA = true) => {
    let ref = isPlayerA ? scrollRefPlayerA : scrollRefPlayerB;
    if (toLeft) {
      ref.current.scrollLeft -= 100;
    } else {
      ref.current.scrollLeft += 100;
    }
  };
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id) => {
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = selectedEventForBet.back1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = 'back';

    dispatch({
      type: 'gameData/setSelectedEventForBet',
      payload,
    });
  };

  useEffect(() => {
    dispatch(setGameKey('andarBahar20'));
  }, [dispatch]);

  useEffect(() => {
    if (odds.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);
  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id,
      authDetails?.uid
    );
    if (res && res?.code == 200) {
      setPlExposure(res.response);
    }
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data;
      for (let i = 0; i < plExposure.length; i++) {
        let data = plExposure[i]?.casinoPLExposure?.find(
          (item) => item.selectionName == selectionName
        );
        if (data) {
          return data;
        }
      }

      return data ? data : null;
    }
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);
  return (
    <Box
      display='flex'
      flexDirection={{ base: 'column', sm: 'column', lg: 'row' }}
    >
      <Box mt='1' mr='1' w={{ base: '100%', sm: '100%', lg: '70%' }}>
        <Box h='60vh' mb='2' pos='relative' className='casino-video'>
          {odds?.cards && (
            <CardDrawer
              custom={
                odds?.cards.length > 0 && (
                  <Box>
                    <Flex alignItems='center'>
                      <Text mr='1'>A</Text>
                      <FaArrowAltCircleLeft
                        onClick={() => handlePlayerClick(true, true)}
                      />
                      {odds?.cards[0]?.length >= 1 && (
                        <div
                          ref={scrollRefPlayerA}
                          className='scroll-container'
                        >
                          {odds?.cards[0]?.map((item, index) => (
                            <div className='scroll-inner-wrapper'>
                              {item && (
                                <img
                                  className='casino-card-img'
                                  src={require(`../../../assets/img/casino/${item}.png`)}
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                      <FaArrowAltCircleRight
                        onClick={() => handlePlayerClick(false, true)}
                      />
                    </Flex>
                    <Flex alignItems='center'>
                      <Text mr='1'>B</Text>
                      <FaArrowAltCircleLeft
                        onClick={() => handlePlayerClick(true, false)}
                      />
                      {odds?.cards[1]?.length >= 1 && (
                        <div
                          ref={scrollRefPlayerB}
                          className='scroll-container'
                        >
                          {odds?.cards[1]?.map((item, index) => (
                            <div className='scroll-inner-wrapper'>
                              {item && (
                                <img
                                  className='casino-card-img'
                                  src={require(`../../../assets/img/casino/${item}.png`)}
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                      <FaArrowAltCircleRight
                        onClick={() => handlePlayerClick(false, false)}
                      />
                    </Flex>
                  </Box>
                )
              }
            />
          )}
          <div className='market-title'>Andar Bahar</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3053`}
          />
          <Box
            bottom='0'
            p='2'
            pos='absolute'
            right='2'
            display='flex'
            bg='black'
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules image={`${ASSETS_URL}abj-rules.jpg`} />
        </Box>
        <Box display={{ base: 'none', md: 'block' }}>
          <div className='andarbahar-module'>
            <div className='card-content m-t-0'>
              <table className='table table-bordered m-b-0 andar-bahar'>
                <tbody>
                  <tr className='andarbg'>
                    <td className='box-1 text-center'>
                      <b>Andar</b>
                    </td>
                    <td
                      id='andar-box'
                      className='box-9 andarbahartd text-center'
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        {AndarCards?.map(({ id, img }) => (
                          <div
                            key={id} // Make sure to add a unique key for each card
                            onClick={() => setEvent(id)}
                            className={`andar-grid-item ${
                              isLocked ? 'suspended' : ''
                            }`}
                            style={{ marginRight: '10px', cursor: 'pointer' }} // Add margin or spacing between cards
                          >
                            <img
                              className='cardimg'
                              src={img}
                              alt={`Card ${id}`}
                            />
                            {!isLocked && (
                              <p style={{ height: 10 }}>
                                {getExposureForEntity(id)?.exposure}
                              </p>
                            )}
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                  <tr className='baharbg'>
                    <td className='box-1 text-center'>
                      <b>Bahar</b>
                    </td>
                    <td
                      id='bahar-box'
                      className='box-9 andarbahartd text-center'
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        {BaharCards?.map(({ id, img }) => (
                          <div
                            key={id} // Make sure to add a unique key for each card
                            onClick={() => setEvent(id)}
                            className={`andar-grid-item ${
                              isLocked ? 'suspended' : ''
                            }`}
                            style={{ marginRight: '10px', cursor: 'pointer' }} // Add margin or spacing between cards
                          >
                            <img
                              className='cardimg'
                              src={img}
                              alt={`Card ${id}`}
                            />
                            {!isLocked && (
                              <p style={{ height: 10 }}>
                                {getExposureForEntity(id)?.exposure}
                              </p>
                            )}
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className='casino-remark mt-1'>
              <div className='remark-icon'>
                <img
                  src='https://sitethemedata.com/v120/static/front/img/icons/remark.png'
                  alt='image'
                />
              </div>{' '}
              <marquee>
                Payout : Bahar 1st Card 25% and All Other Andar-Bahar Cards
                100%.
              </marquee>
            </div>
          </div>
        </Box>

        <Box display={{ base: 'block', md: 'none' }}>
          <div class='casino-container andar-bahar'>
            <div class='andar-bx'>
              <div class='ab-title text-center'>Andar</div>
              <div class='text-center ab-content'>
                <div
                  id='andar_div'
                  class='owl-carousel owl-theme ab-slider owl-loaded owl-drag'
                >
                  <div class='owl-stage-outer'>
                    <div class='owl-stage'>
                      {AndarCards?.map(({ id, img }) => (
                        <div
                          class='owl-item active'
                          onClick={() => setEvent(id)}
                        >
                          <div>
                            <img src={img} class='andar-bahar-image' />
                            {!isLocked && (
                              <p class='mb-0'>
                                {getExposureForEntity(id)?.exposure}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class='bahar-bx mt-2'>
              <div class='ab-title text-center'>Bahar</div>
              <div class='text-center ab-content'>
                <div
                  id='andar_div'
                  class='owl-carousel owl-theme ab-slider owl-loaded owl-drag'
                >
                  <div class='owl-stage-outer'>
                    <div class='owl-stage'>
                      {BaharCards?.map(({ id, img }) => (
                        <div
                          class='owl-item active'
                          onClick={() => setEvent(id)}
                        >
                          <div>
                            <img src={img} class='andar-bahar-image' />
                            {!isLocked && (
                              <p class='mb-0'>
                                {getExposureForEntity(id)?.exposure}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class='remark text-right pr-2'>
              <marquee scrollamount='3'>
                <p class='mb-0'>
                  Payout : Bahar 1st Card 25% and All Other Andar-Bahar Cards
                  100%.
                </p>
              </marquee>
            </div>
          </div>
        </Box>

        <LastResults eventId={gameName} />
      </Box>
    </Box>
  );
}
