import { Grid, GridItem, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomInput from "../../../components/UI/CustomInput";

export default function DepositAndWithdraw({
  isWithdraw,
  payload,
  setPayload,
  balance,
  myAvailableBalance,
  userName,
}) {
  return (
    <Grid gap={1} fontSize="sm" templateColumns="repeat(3,1fr)" >
      <GridItem style={{paddingTop:"15px"}}>
        <Text>{userName ? userName : "S_SUPADMIN"}</Text>
      </GridItem>
      <GridItem>
        <CustomInput disabled value={myAvailableBalance} />
      </GridItem>
      <GridItem>
        <CustomInput disabled />
      </GridItem>
      <GridItem style={{paddingTop:"15px"}}>
        {!userName && <Text>{userName ? userName : "S_MASTER"}</Text>}
      </GridItem>
      <GridItem>
        <CustomInput disabled value={payload?.user?.balance} />
      </GridItem>
      <GridItem>{!userName && <CustomInput disabled />}</GridItem>
      <GridItem style={{paddingTop:"15px"}}>
        <Text>Amount</Text>
      </GridItem>
      <GridItem colSpan={2}>
        <CustomInput
          onChange={(e) => {
            setPayload({
              ...payload,
              amount: parseInt(e.target.value),
            });
          }}
        />
      </GridItem>
      <GridItem style={{paddingTop:"15px"}}>
        <Text>Remark</Text>
      </GridItem>
      <GridItem colSpan={2}>
        <CustomInput
          onChange={(e) => {
            setPayload({
              ...payload,
              remarks: e.target.value,
            });
          }}
        />
      </GridItem>
      <GridItem style={{paddingTop:"15px"}}>
        <Text>Transaction Password</Text>
      </GridItem>
      <GridItem colSpan={2}>
        <CustomInput
          type="password"
          value={payload?.transactionPassword}
          onChange={(e) =>
            setPayload({ ...payload, transactionPassword: e.target.value })
          }
        />
      </GridItem>
    </Grid>
  );
}
