import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Flip, Flipr } from '../../../components/Flip';
import { placeCasinoBetAPI } from '../../../service/casino';

import './css/cm.css';
import CardDrawer from '../../../components/CardDrawer/CardDrawer';
import { ASSETS_URL } from '../../../utils/constants';
import { useMemo } from 'react';
import Iframe from 'react-iframe';
import IframeComponent from '../../../components/Iframe';
import CasinoVideoRules from '../../../components/CasinoVideoRules';
import LastResults from '../../../components/LastResults';
import { setGameKey } from '../../../redux/features/gameSlice';
export default function Cricket2020() {
  const odds_ = useSelector((state) => state.gameData.cmatch20Odds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const selectedEventForBet = useSelector(
    (state) => state.gameData.selectedEventForBet
  );
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const toast = useToast();
  const dispatch = useDispatch();
  const gameName = 'CRICKET_MATCH_20';
  const CustomButton = ({ text }) => (
    <Button size='sm' my='2' className='casinoButton'>
      {isLocked ? <FaLock /> : text}
    </Button>
  );
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id, isBack = true) => {
    let selectedEventForBet = getData(id);

    let payload = {};
    payload.amount = selectedAmount;
    payload.casinoGames = gameName;
    payload.profit = getProfit(
      isBack ? selectedEventForBet.back1 : selectedEventForBet.lay1
    )
      ? getProfit(isBack ? selectedEventForBet.back1 : selectedEventForBet.lay1)
      : 0;
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = isBack
      ? selectedEventForBet.back1
      : selectedEventForBet.lay1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = isBack ? 'back' : 'lay';

    dispatch({
      type: 'gameData/setSelectedEventForBet',
      payload,
    });
  };
  useEffect(() => {
    if (odds.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);

  useEffect(() => {
    dispatch(setGameKey('cmatch20'));
  }, [dispatch]);

  return (
    <Box
      display='flex'
      flexDirection={{ base: 'column', sm: 'column', lg: 'row' }}
    >
      <Box mt='1' mr='1' w={{ base: '100%', sm: '100%', lg: '70%' }}>
        <Box
          h='60vh'
          overflow='hidden'
          mb='2'
          pos='relative'
          className='casino-video'
        >
          {odds.cards && (
            <CardDrawer
              labels={'DEALER'}
              multiLevel={true}
              cards={[odds?.cards[0][0]]}
            />
          )}
          <div className='market-title'>Cricket Match 20-20</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3045`}
          />
          <Box
            bottom='0'
            p='2'
            pos='absolute'
            right='2'
            display='flex'
            bg='black'
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules
            custom={
              <div className='rules-body'>
                <img
                  src='https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/rules/cmatch20-rules.jpg'
                  className='rules-body'
                />
              </div>
            }
          />
        </Box>

        <div className='row row5'>
          {/* <div className="col-6">
            <div class={`score-box btn-theme ${isLocked ? "suspended" : ""}`}>
              <img
                src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/ball2.png"
                className="img-fluid ball-image"
              />{" "}
              <img
                src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/score-bat-icon.png"
                className="score-img"
              />
              <div className="team-score">
                <div>
                  <span>Team A</span> <span className="ml-1">230/7</span>{" "}
                  <span className="ml-1">20 Over</span>
                </div>
                <div>
                  <span>Team B</span> <span className="ml-1">218/6</span>{" "}
                  <span>19.5 Over</span>
                </div>
              </div>
              <div className="min-max">
                <span>
                  Min:<span>100</span>
                  Max:<span>300K</span>
                </span>
              </div>
              <div className="back backbox">
                <span className="odds d-block">0.00</span>
              </div>
              <div className="lay laybox">
                <span className="odds d-block">0.00</span>
              </div>
            </div>
            <div class={`score-box btn-theme ${isLocked ? "suspended" : ""}`}>
              <img
                src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/ball3.png"
                className="img-fluid ball-image"
              />{" "}
              <img
                src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/score-bat-icon.png"
                className="score-img"
              />
              <div className="team-score">
                <div>
                  <span>Team A</span> <span className="ml-1">230/7</span>{" "}
                  <span className="ml-1">20 Over</span>
                </div>
                <div>
                  <span>Team B</span> <span className="ml-1">218/6</span>{" "}
                  <span>19.5 Over</span>
                </div>
              </div>
              <div className="min-max">
                <span>
                  Min:<span>100</span>
                  Max:<span>300K</span>
                </span>
              </div>
              <div className="back backbox">
                <span className="odds d-block">0.00</span>
              </div>
              <div className="lay laybox">
                <span className="odds d-block">0.00</span>
              </div>
            </div>
            <div class={`score-box btn-theme ${isLocked ? "suspended" : ""}`}>
              <img
                src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/ball4.png"
                className="img-fluid ball-image"
              />{" "}
              <img
                src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/score-bat-icon.png"
                className="score-img"
              />
              <div className="team-score">
                <div>
                  <span>Team A</span> <span className="ml-1">230/7</span>{" "}
                  <span className="ml-1">20 Over</span>
                </div>
                <div>
                  <span>Team B</span> <span className="ml-1">218/6</span>{" "}
                  <span>19.5 Over</span>
                </div>
              </div>
              <div className="min-max">
                <span>
                  Min:<span>100</span>
                  Max:<span>300K</span>
                </span>
              </div>
              <div className="back backbox">
                <span className="odds d-block">0.00</span>
              </div>
              <div className="lay laybox">
                <span className="odds d-block">0.00</span>
              </div>
            </div>
            <div class={`score-box btn-theme ${isLocked ? "suspended" : ""}`}>
              <img
                src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/ball5.png"
                className="img-fluid ball-image"
              />{" "}
              <img
                src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/score-bat-icon.png"
                className="score-img"
              />
              <div className="team-score">
                <div>
                  <span>Team A</span> <span className="ml-1">230/7</span>{" "}
                  <span className="ml-1">20 Over</span>
                </div>
                <div>
                  <span>Team B</span> <span className="ml-1">218/6</span>{" "}
                  <span>19.5 Over</span>
                </div>
              </div>
              <div className="min-max">
                <span>
                  Min:<span>100</span>
                  Max:<span>300K</span>
                </span>
              </div>
              <div className="back backbox">
                <span className="odds d-block">0.00</span>
              </div>
              <div className="lay laybox">
                <span className="odds d-block">0.00</span>
              </div>
            </div>
            <div class={`score-box btn-theme ${isLocked ? "suspended" : ""}`}>
              <img
                src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/ball6.png"
                className="img-fluid ball-image"
              />{" "}
              <img
                src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/score-bat-icon.png"
                className="score-img"
              />
              <div className="team-score">
                <div>
                  <span>Team A</span> <span className="ml-1">230/7</span>{" "}
                  <span className="ml-1">20 Over</span>
                </div>
                <div>
                  <span>Team B</span> <span className="ml-1">218/6</span>{" "}
                  <span>19.5 Over</span>
                </div>
              </div>
              <div className="min-max">
                <span>
                  Min:<span>100</span>
                  Max:<span>300K</span>
                </span>
              </div>
              <div className="back backbox">
                <span className="odds d-block">0.00</span>
              </div>
              <div className="lay laybox">
                <span className="odds d-block">0.00</span>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div class={`score-box btn-theme ${isLocked ? "suspended" : ""}`}>
              <img
                src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/ball7.png"
                className="img-fluid ball-image"
              />{" "}
              <img
                src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/score-bat-icon.png"
                className="score-img"
              />
              <div className="team-score">
                <div>
                  <span>Team A</span> <span className="ml-1">230/7</span>{" "}
                  <span className="ml-1">20 Over</span>
                </div>
                <div>
                  <span>Team B</span> <span className="ml-1">218/6</span>{" "}
                  <span>19.5 Over</span>
                </div>
              </div>
              <div className="min-max">
                <span>
                  Min:<span>100</span>
                  Max:<span>300K</span>
                </span>
              </div>
              <div className="back backbox">
                <span className="odds d-block">0.00</span>
              </div>
              <div className="lay laybox">
                <span className="odds d-block">0.00</span>
              </div>
            </div>
            <div class={`score-box btn-theme ${isLocked ? "suspended" : ""}`}>
              <img
                src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/ball8.png"
                className="img-fluid ball-image"
              />{" "}
              <img
                src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/score-bat-icon.png"
                className="score-img"
              />
              <div className="team-score">
                <div>
                  <span>Team A</span> <span className="ml-1">230/7</span>{" "}
                  <span className="ml-1">20 Over</span>
                </div>
                <div>
                  <span>Team B</span> <span className="ml-1">218/6</span>{" "}
                  <span>19.5 Over</span>
                </div>
              </div>
              <div className="min-max">
                <span>
                  Min:<span>100</span>
                  Max:<span>300K</span>
                </span>
              </div>
              <div className="back backbox">
                <span className="odds d-block">0.00</span>
              </div>
              <div className="lay laybox">
                <span className="odds d-block">0.00</span>
              </div>
            </div>
            <div class={`score-box btn-theme ${isLocked ? "suspended" : ""}`}>
              <img
                src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/ball9.png"
                className="img-fluid ball-image"
              />{" "}
              <img
                src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/score-bat-icon.png"
                className="score-img"
              />
              <div className="team-score">
                <div>
                  <span>Team A</span> <span className="ml-1">230/7</span>{" "}
                  <span className="ml-1">20 Over</span>
                </div>
                <div>
                  <span>Team B</span> <span className="ml-1">218/6</span>{" "}
                  <span>19.5 Over</span>
                </div>
              </div>
              <div className="min-max">
                <span>
                  Min:<span>100</span>
                  Max:<span>300K</span>
                </span>
              </div>
              <div className="back backbox">
                <span className="odds d-block">0.00</span>
              </div>
              <div className="lay laybox">
                <span className="odds d-block">0.00</span>
              </div>
            </div>
            <div class={`score-box btn-theme ${isLocked ? "suspended" : ""}`}>
              <img
                src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/ball10.png"
                className="img-fluid ball-image"
              />{" "}
              <img
                src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/score-bat-icon.png"
                className="score-img"
              />
              <div className="team-score">
                <div>
                  <span>Team A</span> <span className="ml-1">230/7</span>{" "}
                  <span className="ml-1">20 Over</span>
                </div>
                <div>
                  <span>Team B</span> <span className="ml-1">218/6</span>{" "}
                  <span>19.5 Over</span>
                </div>
              </div>
              <div className="min-max">
                <span>
                  Min:<span>100</span>
                  Max:<span>300K</span>
                </span>
              </div>
              <div className="back backbox">
                <span className="odds d-block">0.00</span>
              </div>
              <div className="lay laybox">
                <span className="odds d-block">0.00</span>
              </div>
            </div>
          </div> */}

          <div className='col-6 full-mobile'>
            {[2, 3, 4, 5, 6].map((item) => (
              <div class={`score-box btn-theme ${isLocked ? 'suspended' : ''}`}>
                <img
                  src='https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/ball2.png'
                  className='img-fluid ball-image'
                />{' '}
                <img
                  src='https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/score-bat-icon.png'
                  className='score-img'
                />
                <div className='team-score'>
                  <div>
                    <span>Team A</span>{' '}
                    <span className='ml-1'>
                      {' '}
                      {odds?.cards?.length > 1 && (
                        <div className='text-center'>
                          <span className='ml-1'>
                            {odds.cards[1][0]}/{odds.cards[1][1]}
                          </span>
                          <span className='ml-1'>{odds.cards[1][2]} Overs</span>
                        </div>
                      )}
                    </span>
                  </div>
                  <div>
                    <span>Team B</span>{' '}
                    <span className='ml-1'>
                      {' '}
                      {odds?.cards?.length > 1 && (
                        <div className='text-center'>
                          <span className='ml-1'>
                            {odds.cards[2][0]}/{odds.cards[2][1]}
                          </span>
                          <span className='ml-1'>{odds.cards[2][2]} Overs</span>
                        </div>
                      )}
                    </span>
                  </div>
                </div>
                <div className='min-max'>
                  <span>
                    Min:<span>100</span>
                    Max:<span>300K</span>
                  </span>
                </div>
                <div className='back backbox'>
                  <span className='odds d-block'>0.00</span>
                </div>
                <div className='lay laybox'>
                  <span className='odds d-block'>0.00</span>
                </div>
              </div>
            ))}
          </div>
          <div className='col-6 full-mobile'>
            {[7, 8, 9, 10].map((item) => (
              <div class={`score-box btn-theme ${isLocked ? 'suspended' : ''}`}>
                <img
                  src='https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/ball2.png'
                  className='img-fluid ball-image'
                />{' '}
                <img
                  src='https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/score-bat-icon.png'
                  className='score-img'
                />
                <div className='team-score'>
                  <div>
                    <span>Team A</span>{' '}
                    <span className='ml-1'>
                      {' '}
                      {odds?.cards?.length > 1 && (
                        <div className='text-center'>
                          <span className='ml-1'>
                            {odds.cards[1][0]}/{odds.cards[1][1]}
                          </span>
                          <span className='ml-1'>{odds.cards[1][2]} Overs</span>
                        </div>
                      )}
                    </span>
                  </div>
                  <div>
                    <span>Team B</span>{' '}
                    <span className='ml-1'>
                      {' '}
                      {odds?.cards?.length > 1 && (
                        <div className='text-center'>
                          <span className='ml-1'>
                            {odds.cards[2][0]}/{odds.cards[2][1]}
                          </span>
                          <span className='ml-1'>{odds.cards[2][2]} Overs</span>
                        </div>
                      )}
                    </span>
                  </div>
                </div>
                <div className='min-max'>
                  <span>
                    Min:<span>100</span>
                    Max:<span>300K</span>
                  </span>
                </div>
                <div className='back backbox'>
                  <span className='odds d-block'>0.00</span>
                </div>
                <div className='lay laybox'>
                  <span className='odds d-block'>0.00</span>
                </div>
              </div>
            ))}
          </div>

          <div className='casino-remark snipcss-iPU8f'>
            <div className='remark-icon'>
              <img
                src='https://sitethemedata.com/v128/static/front/img/icons/remark.png'
                alt='Remark'
              />
            </div>
            <marquee>
              Team B Need 12 Runs to WIN Match. If The Score is Tie Team B will
              WIN.
            </marquee>
          </div>
        </div>
        <LastResults eventId={gameName} />
      </Box>
    </Box>
  );
}
