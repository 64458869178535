/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

const IframeComponent = ({ url, width, height = "100%", ...rest }) => {
  return (
    <>
      <div className="video-box-container">
        <div className="vidio-box">
          <iframe
            {...rest}
            style={{
              width: "100%",
              height: height,
            }}
            src={url}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default IframeComponent;
