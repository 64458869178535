import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Select,
  Td,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import CustomDataModal from "../../components/UI/CustomDataModal";
import CustomModal from "../../components/UI/CustomModal";
import CustomTable from "../../components/UI/Table";

import { betHistoryAPI } from "../../service/AuthService";
import {
  getCasinoReportHistory,
  getIndividualResultAPI,
} from "../../service/casino";
import { getCurrentDateTimeLocal } from "../../utils/constants";
/* import {
  getCasinoReportHistory,
  getIndividualResultAPI,
} from "../../service/casino"; */

export default function CasinoResult() {
  const [from, setFrom] = React.useState(getCurrentDateTimeLocal());
  const [sport, setSport] = React.useState("DRAGON_TIGER_20");
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [selectedMarket, setSelectedMarket] = React.useState({});
  const [marketData, setMarketData] = React.useState({});
  const getCasinoResult = async () => {
    if (!sport || !from) return alert("Please select casino and date");
    const {
      response: { content },
    } = await getCasinoReportHistory({
      casinoGames: sport,
      date: new Date(from),
      page,
      size,
    });
    setData(content);
  };
  const getIndividualResult = async (marketId) => {
    if (marketId) {
      const { response } = await getIndividualResultAPI(marketId);
      setMarketData(response);
    }
  };
  const internalSearch = (e) => {
    if (e.target.value.length == 0) {
      getCasinoResult();
    }
    if (e.target.value.length > 3) {
      let response_ = data.filter((item) => {
        return (
          item.marketId == e.target.value ||
          item?.winner
            ?.toLowerCase()
            .includes(e?.target?.value.toLowerCase()) ||
          item?.name?.toLowerCase().includes(e?.target?.value.toLowerCase())
        );
      });
      setData(response_);
    }
  };
  useEffect(() => {
    getCasinoResult();
  }, []);
  useEffect(() => {
    getIndividualResult(selectedMarket.marketId);
  }, [selectedMarket]);
  return (
    <div className="main">
      <CustomDataModal
        headerBg="#680062"
        title="20-20 Dragon Tiger 2 Result"
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        size="3xl"
      >
        <Box p="2" display="flex" w="100%" justifyContent="flex-end">
          <Text>
            <b>Round Id:</b> {marketData?.mid}
          </Text>
        </Box>
        <Box p="2" display="flex" w="100%" justifyContent="center">
          <Flex>
            {marketData?.cards &&
              marketData?.cards
                ?.split(",")
                .map((card, index) => (
                  <Image
                    m="1"
                    h="50px"
                    src={require(`../../assets/img/casino/${card}.png`)}
                  />
                ))}
          </Flex>
        </Box>
        <Flex
          display="flex"
          flexDirection="column"
          w="100%"
          justifyContent="center"
          alignItems="center"
          fontSize="sm"
        >
          <Flex>
            <Text color="green.500">Result:</Text>
            <Text>{marketData?.desc?.split("*")[0]}</Text>
          </Flex>
          <Flex>
            <Text color="green.500">Dragon:</Text>
            <Text>{marketData?.desc?.split("*")[1]}</Text>
          </Flex>
          <Flex>
            <Text color="green.500">Tiger:</Text>
            <Text>{marketData?.desc?.split("*")[2]}</Text>
          </Flex>
        </Flex>
      </CustomDataModal>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 main-container">
            <div>
              <div className="listing-grid">
                <div className="detail-row">
                  <h2>Casino Results</h2>
                  <form method="post" id="form-search">
                    <div className="m-t-20">
                      <div className="row">
                        <div className="col-md-2">
                          <div className="form-group">
                            <input
                              name="search-date"
                              id="search-date"
                              className="form-control"
                              value={from}
                              type="date"
                              onChange={(e) => {
                                setFrom(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <select
                              name="search-type"
                              id="search-type"
                              className="form-control"
                              onChange={(e) => {
                                setSport(e.target.value);
                              }}
                            >
                              <option value="TEEN">1 Day Teenpatti</option>
                              <option value="TEEN_9">Test Teenpatti</option>
                              <option value="TEEN_20">20-20 Teenpatti</option>
                              <option value="TEEN_8">Open Teenpatti</option>
                              <option value="poker">1 Day Poker </option>
                              <option value="POKER_20">20-20 Poker</option>
                              <option value="POKER_9">6 Player Poker</option>
                              <option value="AB_20">Andar Bahar Casino</option>
                              <option value="WORLI1">Worli Matka</option>
                              <option value="WORLI2">Instant Worli</option>
                              <option value="3cardj">3 Cards Judgement</option>
                              <option value="card32">32 Cards - A</option>
                              <option value="DRAGON_TIGER_20">
                                20-20 Dragon Tiger
                              </option>
                              <option value="dt6">1 Day Dragon Tiger</option>
                              <option value="aaa">Amar Akbar Anthoni</option>
                              <option value="btable">Bollywood Table</option>
                              <option value="card32eu">Card 32 - B</option>
                              <option value="war">War</option>
                              <option value="dtl20">DTL 20</option>
                              <option value="cmeter">Casino Meter</option>
                              <option value="cmatch20">
                                20-20 Casino Match
                              </option>
                              <option value="TEEN_6">Teenpatti 2.0</option>
                              <option value="lucky7">Lucky 7 - A</option>
                              <option value="lucky7eu">Lucky 7 - B</option>
                              <option value="baccarat">Baccarat</option>
                              <option value="baccarat2">Baccarat 2</option>
                              <option value="DRAGON_TIGER_20_2">
                                20-20 Dragon Tiger - 2
                              </option>
                              <option value="abj">Andar Bahar 2</option>
                              <option value="cricketv3">5Five Cricket</option>
                              <option value="queen">Queen</option>
                              <option value="race20">Race 20-20</option>
                              <option value="superover">Super Over</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              getCasinoResult();
                            }}
                            className="btn btn-diamond"
                            id="loaddata"
                            style={{backgroundColor:'#102A6D'}}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ display: "none" }}>
                      <div className="col-md-12">
                        <div className="col-md-2" style={{ float: "right" }}>
                          Search:
                          <div className="form-group">
                            <input
                              type="text"
                              name="search-round_id"
                              id="search-round_id"
                              className="form-control"
                              onChange={internalSearch}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  id="account_history_table_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    className="dataTables_length"
                    id="account_history_table_length"
                  >
                    <label>
                      Show{" "}
                      <select
                        name="account_history_table_length"
                        aria-controls="account_history_table"
                        className
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>{" "}
                      entries
                    </label>
                  </div>
                  <div
                    id="account_history_table_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      Search:
                      <input
                        type="search"
                        className
                        placeholder
                        aria-controls="account_history_table"
                      />
                    </label>
                  </div>
                  <table
                    id="account_history_table"
                    className="table table-striped table-bordered datatable no-footer dataTable"
                    style={{ width: "100%" }}
                    role="grid"
                    aria-describedby="account_history_table_info"
                  >
                    <thead>
                      <tr role="row" style={{backgroundColor:'#C9C9C9'}}>
                        <th>S.No.</th>
                        <th
                          className="sorting_asc"
                          tabIndex={0}
                          aria-controls="account_history_table"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Round ID: activate to sort column descending"
                          style={{ width: "831px" }}
                          aria-sort="ascending"
                        >
                          Round ID
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="account_history_table"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Winner: activate to sort column ascending"
                          style={{ width: "590px" }}
                        >
                          Winner
                        </th>
                      </tr>
                    </thead>
                    <tbody id="bet_tbody">
                      {data.map((match, index) => (
                        <tr role="row" className="odd" style={{backgroundColor:'#FFFFFF'}}>
                          <td>{index + 1}</td>
                          <td className="sorting_1">
                            <span
                              onClick={() => {
                                setSelectedMarket({
                                  marketId: match?.marketId,
                                });

                                setModalOpen(true);
                              }}
                              className="btn_modal_result"
                            >
                              {match.marketId}
                            </span>
                          </td>
                          <td> {match.winner}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div
                    className="dataTables_info"
                    id="account_history_table_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing 1 to 10 of 64 entries
                  </div>
                  <div
                    className="dataTables_paginate paging_full_numbers"
                    id="account_history_table_paginate"
                  >
                    <a
                      className="first disabled button btn btn-diamond"
                      aria-controls="account_history_table"
                      data-dt-idx={0}
                      tabIndex={-1}
                      id="account_history_table_first"
                      style={{backgroundColor:'#102A6D'}}
                    >
                      First
                    </a>
                    <a
                      className="previous disabled button btn btn-diamond"
                      aria-controls="account_history_table"
                      data-dt-idx={1}
                      tabIndex={-1}
                      id="account_history_table_previous"
                      style={{backgroundColor:'#102A6D'}}
                    >
                      Previous
                    </a>
                    <span>
                      <a
                        className="current button btn btn-diamond"
                        aria-controls="account_history_table"
                        data-dt-idx={2}
                        tabIndex={0}
                        style={{backgroundColor:'#102A6D'}}
                      >
                        1
                      </a>
                      <a
                        className="button btn btn-diamond"
                        aria-controls="account_history_table"
                        data-dt-idx={3}
                        tabIndex={0}
                        style={{backgroundColor:'#102A6D'}}
                      >
                        2
                      </a>
                      <a
                        className="button btn btn-diamond"
                        aria-controls="account_history_table"
                        data-dt-idx={4}
                        tabIndex={0}
                        style={{backgroundColor:'#102A6D'}}
                      >
                        3
                      </a>
                      <a
                        className="button btn btn-diamond"
                        aria-controls="account_history_table"
                        data-dt-idx={5}
                        tabIndex={0}
                        style={{backgroundColor:'#102A6D'}}
                      >
                        4
                      </a>
                      <a
                        className="button btn btn-diamond"
                        aria-controls="account_history_table"
                        data-dt-idx={6}
                        tabIndex={0}
                        style={{backgroundColor:'#102A6D'}}
                      >
                        5
                      </a>
                      <a
                        className="button btn btn-diamond"
                        aria-controls="account_history_table"
                        data-dt-idx={7}
                        tabIndex={0}
                        style={{backgroundColor:'#102A6D'}}
                      >
                        6
                      </a>
                      <a
                        className="button btn btn-diamond"
                        aria-controls="account_history_table"
                        data-dt-idx={8}
                        tabIndex={0}
                        style={{backgroundColor:'#102A6D'}}
                      >
                        7
                      </a>
                    </span>
                    <a
                      className="next button btn btn-diamond"
                      aria-controls="account_history_table"
                      data-dt-idx={9}
                      tabIndex={0}
                      id="account_history_table_next"
                      style={{backgroundColor:'#102A6D'}}
                    >
                      Next
                    </a>
                    <a
                      className="last button btn btn-diamond"
                      aria-controls="account_history_table"
                      data-dt-idx={10}
                      tabIndex={0}
                      id="account_history_table_last"
                      style={{backgroundColor:'#102A6D'}}
                    >
                      Last
                    </a>
                  </div>
                </div>
                <div className="row m-t-10 m-b-10">
                  <div className="col-md-6"></div>
                  <div className="col-md-6" id="pagination" />
                </div>
              </div>
            </div>
          </div>
          {/* The Modal */}
        </div>
      </div>
    </div>
  );
  /* return (
    <Box>
      <Box
        p="2"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        fontWeight="bold"
      >
        <Text fontSize="2xl">Casino Result</Text>
      </Box>
      <Box mt="5" display="flex" p="2" w="60%">
        <Input
          onChange={(e) => {
            setFrom(e.target.value);
          }}
          value={from}
          mx="1"
          type="datetime-local"
        />
        <Select
          onChange={(e) => {
            setSport(e.target.value);
          }}
          mx="1"
          value={sport}
        >
          <option value={null}>Select Casino</option>
          <option value="DRAGON_TIGER_20">Dragon Tiger 2020</option>
          <option value="TEEN_20">Teen Patti</option>
        </Select>

        <button
          style={{
            backgroundColor: "#0079FB",
            color: "white",
            paddingLeft: "15px",
            paddingRight: "15px",
            borderRadius: "5px",
            marginLeft: "10px",
          }}
          onClick={() => getCasinoResult()}
        >
          Submit
        </button>
      </Box>
      <CustomTable
        columns={[
          {
            name: "Round Id",
            id: "round_id",
            element: (row) => (
              <Td>
                <Text
                  onClick={() => {
                    setSelectedMarket({
                      marketId: row?.marketId,
                    });

                    setModalOpen(true);
                  }}
                  color="blue.400"
                >
                  {row?.marketId}
                </Text>
              </Td>
            ),
          },
          {
            name: "Winner",
            id: "winner",
          },
        ]}
        rows={data}
        currentPage_={page}
        sizePerPage_={size}
        totalSize_={data.length}
        rowsPerPage_={20}
      />
      <CustomDataModal
        headerBg="#680062"
        title="20-20 Dragon Tiger 2 Result"
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        size="3xl"
      >
        <Box p="2" display="flex" w="100%" justifyContent="flex-end">
          <Text>
            <b>Round Id:</b> {marketData?.mid}
          </Text>
        </Box>
        <Box p="2" display="flex" w="100%" justifyContent="center">
          <Flex>
            {marketData.cards &&
              marketData?.cards
                ?.split(",")
                .map((card, index) => (
                  <Image
                    m="1"
                    h="50px"
                    src={require(`../../assets/img/casino/${card}.png`)}
                  />
                ))}
          </Flex>
        </Box>
        <Flex
          display="flex"
          flexDirection="column"
          w="100%"
          justifyContent="center"
          alignItems="center"
          fontSize="sm"
        >
          <Flex>
            <Text color="green.500">Result:</Text>
            <Text>{marketData?.desc?.split("*")[0]}</Text>
          </Flex>
          <Flex>
            <Text color="green.500">Dragon:</Text>
            <Text>{marketData?.desc?.split("*")[1]}</Text>
          </Flex>
          <Flex>
            <Text color="green.500">Tiger:</Text>
            <Text>{marketData?.desc?.split("*")[2]}</Text>
          </Flex>
        </Flex>
      </CustomDataModal>
    </Box>
  ); */
}
