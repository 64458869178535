import React, { useState } from "react";
import { ASSETS_URL } from "../../utils/constants";
import { Image, Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { getCasinoLastTenResultsAPI } from "../../service/casino";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import CasinoResultModal from "../CasinoResultModal/CasinoResultModal";

const imageMap = {
  S: "spade.png",
  H: "heart.png",
  D: "diamond.png",
  C: "club.png",
};

const LastResults = ({ isImage, eventId }) => {
  const userData = useSelector((state) => state.account.userData);
  const [lastTenResult, setLastTenResult] = useState([]);
  const [lastResultId, setLastResultId] = useState("");
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);

  const handleCloseResultModal = () => setIsResultModalOpen(false);
  const handleOpenResultModal = (id) => {
    setLastResultId(id);
    setIsResultModalOpen(true);
  };

  const getCasinoLastResults = useCallback(async () => {
    try {
      const { data } = await getCasinoLastTenResultsAPI(
        eventId,
        userData.token
      );
      setLastTenResult(data);
    } catch (error) {
      console.log(error.message, "errorMessage");
    }
  }, [eventId]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    let interval;
    if (token) {
      interval = setInterval(() => {
        getCasinoLastResults();
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [getCasinoLastResults, eventId]);

  console.log(
    lastTenResult?.casinoMarketHistoryResponseDTOS,
    "lastTenResult?.casinoMarketHistoryResponseDTOS"
  );
  return (
    <>
      <div className="fancy-marker-title">
        <h4>
          Last Result{" "}
          <a href="#" className="result-view-all">
            View All
          </a>
        </h4>
      </div>
      <div className="m-b-10">
        <p id="last-result" className="text-right">
          {lastTenResult?.casinoMarketHistoryResponseDTOS?.map((result) => (
            <>
              <span>
                <span
                  className={`ball-runs  last-result ${
                    result.mainWinner == "D"
                      ? "playera"
                      : result.mainWinner == "T"
                      ? "playerb"
                      : ""
                  }`}
                  onClick={() => handleOpenResultModal(result?.marketId)}
                >
                  {eventId == "RACE20" ? (
                    <Image src={ASSETS_URL + imageMap[result.mainWinner]} />
                  ) : (
                    result.mainWinner
                  )}
                </span>
              </span>
            </>
          ))}
        </p>
      </div>

      <Modal
        size={"5xl"}
        isOpen={isResultModalOpen}
        onClose={handleCloseResultModal}
      >
        <ModalOverlay />
        <ModalContent>
          <CasinoResultModal
            marketId={lastResultId}
            onClose={handleCloseResultModal}
            isCustom={true}
            casinoKey={eventId}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default LastResults;
