import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Image,
  useToast,
  WrapItem,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Flip, Flipr } from '../../../components/Flip';
import {
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from '../../../service/casino';

import CardDrawer from '../../../components/CardDrawer/CardDrawer';
import { ASSETS_URL } from '../../../utils/constants';
import { useMemo } from 'react';
import Iframe from 'react-iframe';
import IframeComponent from '../../../components/Iframe';
import CasinoVideoRules from '../../../components/CasinoVideoRules';
import LastResults from '../../../components/LastResults';
import { setGameKey } from '../../../redux/features/gameSlice';
import { getService } from '../../../utils/commonFunctions';
import { useLocation } from 'react-router-dom';

export default function Lucky7B() {
  const location = useLocation();
  const odds_ = useSelector((state) => state.gameData.lucky7euOdds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const [plExposure, setPlExposure] = useState(null);
  const authDetails = useSelector((state) => state.account.authDetails);
  const toast = useToast();
  const dispatch = useDispatch();
  const gameName = 'LUCKY7EU';
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id) => {
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = selectedEventForBet.back1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = 'back';

    dispatch({
      type: 'gameData/setSelectedEventForBet',
      payload,
    });
  };

  useEffect(() => {
    dispatch(setGameKey('lucky7eu'));
  }, [dispatch]);

  useEffect(() => {
    if (odds?.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);
  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id
    );
    if (res && res?.code == 200) {
      setPlExposure(res.response);
    }
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data;
      for (let i = 0; i < plExposure.length; i++) {
        let data = plExposure[i]?.casinoPLExposure?.find(
          (item) => item.selectionName == selectionName
        );
        if (data) {
          return data;
        }
      }

      return data ? data : null;
    }
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);
  return (
    <Box
      display='flex'
      flexDirection={{ base: 'column', sm: 'column', lg: 'row' }}
    >
      <Box mt='1' mr='1' w={{ base: '100%', sm: '100%', lg: '70%' }}>
        <Box h='60vh' mb='2' pos='relative' className='casino-video'>
          {odds?.cards && <CardDrawer cards={[odds?.cards[0][0]]} />}
          <div class='market-title'>Lucky 7 - B</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3032`}
          />
          <Box
            bottom='0'
            p='2'
            pos='absolute'
            right='2'
            display='flex'
            bg='black'
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules image={`${ASSETS_URL}lucky7-rules.jpg`} />
        </Box>
        <Box
          background={'#3C444B85 !important'}
          backgroundColor={'#3C444B85 !important'}
          /*  style={{
            backgroundColor: "#2E3439 !important",
            background: "#2E3439 !important",
          }} */
          className='casino-detail '
        >
          <Flex flexDirection='column' style={{ width: '100%' }}>
            <div className='card-content lucky-seven-content m-t-10'>
              <div className='row'>
                <div className='col-5 text-center'>
                  <b>{getData('LOW Card').back1}</b>
                </div>
                <div className='col-2'></div>
                <div className='col-5 text-center'>
                  <b>{getData('HIGH Card').back1}</b>
                </div>
              </div>
              <div className='row m-t-10'>
                <div className='col-5 text-center'>
                  <button
                    className={`text-uppercase btn-theme ${
                      isLocked ? 'suspended' : ''
                    }`}
                  >
                    <b onClick={() => setEvent('LOW Card')}>LOW Card</b>
                  </button>
                  <div className='m-t-5'>
                    {getExposureForEntity('LOW Card')?.exposure}
                  </div>
                </div>
                <div
                  className='col-2 text-center card-seven'
                  style={{ paddingLeft: '5%' }}
                >
                  <img src={require(`../../../assets/img/casino/7.png`)} />
                </div>
                <div className='col-5 text-center'>
                  <button
                    className={`text-uppercase btn-theme ${
                      isLocked ? 'suspended' : ''
                    }`}
                  >
                    <b onClick={() => setEvent('HIGH Card')}>HIGH Card</b>
                  </button>
                  <div className='m-t-5'>
                    {getExposureForEntity('HIGH Card')?.exposure}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 text-right'>
                  <span className='m-r-5'>
                    <b>Min:</b>
                    <span>100</span>
                  </span>{' '}
                  <span className='m-r-5'>
                    <b>Max:</b>
                    <span>300000</span>
                  </span>
                </div>
              </div>
            </div>

            <div className='row row5'>
              <div className='col-6'>
                <div className='lucky-seven-content m-t-10'>
                  <div className='row'>
                    <div className='col-6 text-center'>
                      <b>{getData('Even').back1}</b>
                    </div>
                    <div className='col-6 text-center'>
                      <b>{getData('Odd').back1}</b>
                    </div>
                  </div>
                  <div className='row m-t-10'>
                    <div className='col-6 text-center'>
                      <button
                        className={`text-uppercase btn-theme ${
                          isLocked ? 'suspended' : ''
                        }`}
                      >
                        <b onClick={() => setEvent('Even')}>Even</b>
                      </button>
                    </div>
                    <div className='col-6 text-center'>
                      <button
                        className={`text-uppercase btn-theme ${
                          isLocked ? 'suspended' : ''
                        }`}
                      >
                        <b onClick={() => setEvent('Odd')}>Odd</b>
                      </button>
                    </div>
                  </div>
                  <div className='row' style={{ height: '35px' }}>
                    <div className='col-6 text-center'>
                      <div className='m-t-5'>
                        {getExposureForEntity('Even')?.exposure}
                      </div>
                    </div>
                    <div className='col-6 text-center'>
                      <div className='m-t-5'>
                        {' '}
                        {getExposureForEntity('Odd')?.exposure}
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 text-right'>
                      <span className='m-r-5'>
                        <b>Min:</b>
                        <span>100</span>
                      </span>{' '}
                      <span className='m-r-5'>
                        <b>Max:</b>
                        <span>25000</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-6'>
                <div className='lucky-seven-content m-t-10'>
                  <div className='row'>
                    <div className='col-6 text-center'>
                      <b>{getData('Red').back1}</b>
                    </div>
                    <div className='col-6 text-center'>
                      <b>{getData('Black').back1}</b>
                    </div>
                  </div>
                  <div className='row m-t-10'>
                    <div className='col-6 text-center'>
                      <button
                        className={`text-uppercase btn-theme ${
                          isLocked ? 'suspended' : ''
                        }`}
                        onClick={() => setEvent('Red')}
                      >
                        <div className='casino-card-item'>
                          <Box display='flex' style={{ paddingLeft: '30%' }}>
                            <Image
                              boxSize='25px'
                              src={require('../../../assets/img/casino/diamond.png')}
                            />
                            <Image
                              boxSize='25px'
                              src={require('../../../assets/img/casino/heart.png')}
                            />
                          </Box>
                        </div>
                      </button>
                    </div>
                    <div className='col-6 text-center'>
                      <button
                        className={`text-uppercase btn-theme ${
                          isLocked ? 'suspended' : ''
                        }`}
                        onClick={() => setEvent('Black')}
                      >
                        <div className='casino-card-item'>
                          <Box display='flex' style={{ paddingLeft: '30%' }}>
                            <Image
                              boxSize='25px'
                              src={require('../../../assets/img/casino/club.png')}
                            />
                            <Image
                              boxSize='25px'
                              src={require('../../../assets/img/casino/spade.png')}
                            />
                          </Box>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className='row' style={{ height: '30px' }}>
                    <div className='col-6 text-center'>
                      <div className='m-t-5'>
                        {getExposureForEntity('Red')?.exposure}
                      </div>
                    </div>
                    <div className='col-6 text-center'>
                      <div className='m-t-5'>
                        {getExposureForEntity('Black')?.exposure}
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 text-right'>
                      <span className='m-r-5'>
                        <b>Min:</b>
                        <span>100</span>
                      </span>{' '}
                      <span className='m-r-5'>
                        <b>Max:</b>
                        <span>25000</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='card-content lucky-seven-content m-t-10'>
              <div className='row'>
                <div className='col-12 text-center'>
                  <b>12.00</b>
                </div>
              </div>
              <div className='casino-cards text-center mt-1'>
                {['A', 2, 3, 4, 5, 6, 7, 8, 9, 10, 'J', 'Q', 'K'].map(
                  (card) => (
                    <div className='casino-card-item'>
                      <div
                        onClick={() => setEvent(`Card ${card}`)}
                        className={`card-image ${isLocked ? 'suspended' : ''}`}
                      >
                        <img src={ASSETS_URL + card + '.png'} />
                      </div>
                      <div className='casino-book d-none'>
                        {getData(`Card ${card}`).back1}
                      </div>
                      <div className='m-t-5'>
                        {getExposureForEntity(`Card ${card}`)?.exposure}
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className='row'>
                <div className='col-12 text-right'>
                  <span className='m-r-5'>
                    <b>Min:</b>
                    <span>100</span>
                  </span>{' '}
                  <span className='m-r-5'>
                    <b>Max:</b>
                    <span>25000</span>
                  </span>
                </div>
              </div>
            </div>
          </Flex>
        </Box>
        <LastResults eventId={gameName} />
      </Box>
    </Box>
  );
}
