import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Flip, Flipr } from '../../../components/Flip';
import { placeCasinoBetAPI } from '../../../service/casino';

import Iframe from 'react-iframe';
import IframeComponent from '../../../components/Iframe';
import LastResults from '../../../components/LastResults';

export default function JudgementCards3() {
  const odds_ = useSelector((state) => state.gameData.casinoOdds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const gameName = 'THREE_CARD';
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const toast = useToast();
  const dispatch = useDispatch();
  const allCard1 = [
    {
      id: 'Tiger Card 1',
      img: require('../../../assets/img/casino/1 - Copy.png'),
    },
    {
      id: 'Tiger Card 2',
      img: require('../../../assets/img/casino/2.png'),
    },
    {
      id: 'Tiger Card 3',
      img: require('../../../assets/img/casino/3.png'),
    },
    {
      id: 'Tiger Card 4',
      img: require('../../../assets/img/casino/4.png'),
    },
    {
      id: 'Tiger Card 5',
      img: require('../../../assets/img/casino/5.png'),
    },
    {
      id: 'Tiger Card 6',
      img: require('../../../assets/img/casino/6.png'),
    },
    {
      id: 'Tiger Card 7',
      img: require('../../../assets/img/casino/7.png'),
    },
    {
      id: 'Tiger Card 8',
      img: require('../../../assets/img/casino/8.png'),
    },
    {
      id: 'Tiger Card 9',
      img: require('../../../assets/img/casino/9.png'),
    },
    {
      id: 'Tiger Card 10',
      img: require('../../../assets/img/casino/10.png'),
    },
    {
      id: 'Tiger Card J',
      img: require('../../../assets/img/casino/11.png'),
    },
    {
      id: 'Tiger Card Q',
      img: require('../../../assets/img/casino/12.png'),
    },
    {
      id: 'Tiger Card K',
      img: require('../../../assets/img/casino/13.png'),
    },
  ];
  const allCard2 = [
    {
      id: 'Tiger Card 1',
      img: require('../../../assets/img/casino/1 - Copy.png'),
    },
    {
      id: 'Tiger Card 2',
      img: require('../../../assets/img/casino/2.png'),
    },
    {
      id: 'Tiger Card 3',
      img: require('../../../assets/img/casino/3.png'),
    },
    {
      id: 'Tiger Card 4',
      img: require('../../../assets/img/casino/4.png'),
    },
    {
      id: 'Tiger Card 5',
      img: require('../../../assets/img/casino/5.png'),
    },
    {
      id: 'Tiger Card 6',
      img: require('../../../assets/img/casino/6.png'),
    },
    {
      id: 'Tiger Card 7',
      img: require('../../../assets/img/casino/7.png'),
    },
    {
      id: 'Tiger Card 8',
      img: require('../../../assets/img/casino/8.png'),
    },
    {
      id: 'Tiger Card 9',
      img: require('../../../assets/img/casino/9.png'),
    },
    {
      id: 'Tiger Card 10',
      img: require('../../../assets/img/casino/10.png'),
    },
    {
      id: 'Tiger Card J',
      img: require('../../../assets/img/casino/11.png'),
    },
    {
      id: 'Tiger Card Q',
      img: require('../../../assets/img/casino/12.png'),
    },
    {
      id: 'Tiger Card K',
      img: require('../../../assets/img/casino/13.png'),
    },
  ];
  const CustomButton = ({ text }) => (
    <Button size='sm' my='2' className='casinoButton'>
      {isLocked ? <FaLock /> : text}
    </Button>
  );

  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id) => {
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = 'DRAGON_TIGER_20';
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = selectedEventForBet.back1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = 'back';

    dispatch({
      type: 'gameData/setSelectedEventForBet',
      payload,
    });
  };
  useEffect(() => {
    if (odds.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);

  return (
    <Box
      display='flex'
      flexDirection={{ base: 'column', sm: 'column', lg: 'row' }}
      mt='-12'
    >
      <Box mt='1' mr='1' w={{ base: '100%', sm: '100%', lg: '70%' }}>
        <Box h='60vh' mb='2' pos='relative' bg='black'>
          {odds.cards && (
            <Box top='8' p='2' pos='absolute' display='flex' bg='black'>
              <img
                style={{
                  marginRight: '5px',
                }}
                className='cardimg'
                src={require(`../../../assets/img/casino/${odds?.cards[0][0]}.png`)}
              />
              <img
                className='cardimg'
                src={require(`../../../assets/img/casino/${odds?.cards[0][1]}.png`)}
              />
            </Box>
          )}
          <div className='market-title'>3 Cards Judgement</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3039`}
          />
          <Box
            bottom='0'
            p='2'
            pos='absolute'
            right='2'
            display='flex'
            bg='black'
          >
            <Flip value={odds?.autoTime} />
          </Box>
        </Box>

        <Grid templateColumns='repeat(2,1fr)' bgColor={'#3C444B85'}>
          <Grid>
            <div className='andar-text'>Yes 0</div>
            <Grid className='three-card-left'>
              {allCard1?.map(({ id, img }) => (
                <GridItem
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                  justifyContent='center'
                  pos='relative'
                  onClick={() => setEvent(id)}
                  p='1'
                  textAlign='center'
                  cursor='pointer'
                >
                  {isLocked && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      alignItems='center'
                      justifyContent='center'
                      bg='blackAlpha.800'
                      w='42px'
                      h='70%'
                      top='-2px'
                      position='absolute'
                    >
                      <FaLock color='white' />
                    </Box>
                  )}
                  <img className='cardimg' src={img} />
                </GridItem>
              ))}
            </Grid>
          </Grid>
          <Grid>
            <div className='bahar-text'>No 0</div>
            <Grid className='three-card-right'>
              {allCard2?.map(({ id, img }) => (
                <GridItem
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                  justifyContent='center'
                  pos='relative'
                  onClick={() => setEvent(id)}
                  p='1'
                  textAlign='center'
                  cursor='pointer'
                >
                  {isLocked && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      alignItems='center'
                      justifyContent='center'
                      bg='blackAlpha.800'
                      w='42px'
                      h='70%'
                      top='-2px'
                      position='absolute'
                    >
                      <FaLock color='white' />
                    </Box>
                  )}
                  <img className='cardimg' src={img} />
                </GridItem>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <LastResults eventId='THREE_CARD' />
      </Box>
    </Box>
  );
}
