import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Flip, Flipr } from '../../../components/Flip';
import {
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from '../../../service/casino';

import CardDrawer from '../../../components/CardDrawer/CardDrawer';
import { ASSETS_URL } from '../../../utils/constants';
import { useMemo } from 'react';
import Iframe from 'react-iframe';
import IframeComponent from '../../../components/Iframe';
import CasinoVideoRules from '../../../components/CasinoVideoRules';
import LastResults from '../../../components/LastResults';
import { setGameKey } from '../../../redux/features/gameSlice';

export default function Pokerr6Players() {
  const odds_ = useSelector((state) => state.gameData.poker9Odds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [activeTab, setActiveTab] = useState('hands');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [isLocked, setIsLocked] = useState(false);
  const [plExposure, setPlExposure] = useState(null);
  const authDetails = useSelector((state) => state.account.authDetails);
  const toast = useToast();
  const dispatch = useDispatch();
  const gameName = 'POKER_9';
  const CustomButton = ({ text }) => (
    <Button size='sm' my='2' className='casinoButton'>
      {isLocked ? <FaLock /> : text}
    </Button>
  );
  const CustomButtonPink = ({ text, item, size, ...rest }) => (
    <Button
      size={size ? size : 'sm'}
      my='2'
      display='flex'
      justifyContent='center'
      alignItems='center'
      className='casinoButton-pink'
      onClick={isLocked ? null : rest.onClick}
    >
      {isLocked ? <FaLock /> : item ? item : text}
    </Button>
  );
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };

  useEffect(() => {
    dispatch(setGameKey('poker9'));
  }, [dispatch]);

  const setEvent = (id) => {
    try {
      let selectedEventForBet = getData(id);
      let payload = {};
      payload.amount = parseInt(selectedAmount);
      payload.casinoGames = gameName;
      payload.profit = getProfit(selectedEventForBet);
      payload.loss = parseInt(selectedAmount);
      payload.selectedOdd = selectedEventForBet.back1;
      payload.selectedId = selectedEventForBet.selectionId;
      payload.marketId = odds.marketId;
      payload.runnerName = selectedEventForBet.runnerName;
      payload.type = 'back';

      dispatch({
        type: 'gameData/setSelectedEventForBet',
        payload,
      });
    } catch (error) {
      console.log(error.message, 'error');
    }
  };
  useEffect(() => {
    if (odds.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds]);
  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(id, authDetails?.uid);
    if (res && res?.code == 200) {
      setPlExposure(res.response);
    }
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data = plExposure?.find((item) =>
        item?.casinoPLExposure?.find(
          (exposure) => exposure.selectionName == selectionName
        )
      );
      return data ? data : null;
    }
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);

  return (
    <Box
      display='flex'
      flexDirection={{ base: 'column', sm: 'column', lg: 'row' }}
    >
      <Box
        className=''
        mt='1'
        mr='1'
        w={{ base: '100%', sm: '100%', lg: '70%' }}
      >
        <Box overflow='hidden' pos='relative'>
          {odds?.cards && (
            <CardDrawer multiLevel={true} cards={[odds?.cards[6]]} />
          )}

          <div className='market-title'>Poker 6 Players</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3050`}
          />
          <Box bottom='0' p='2' pos='absolute' right='2' display='flex'>
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules image={`${ASSETS_URL}poker-rules.jpeg`} />
        </Box>

        <div id='poker-test-box'>
          <ul className='nav nav-tabs m-b-5 m-t-5'>
            <li className={`nav-item ${activeTab === 'hands' ? 'active' : ''}`}>
              <a
                data-toggle='tab'
                href='#hands'
                className={`nav-link ${activeTab === 'hands' ? 'active' : ''}`}
                onClick={() => handleTabClick('hands')}
              >
                Hands
              </a>
            </li>
            <li
              className={`nav-item ${activeTab === 'patterns' ? 'active' : ''}`}
            >
              <a
                data-toggle='tab'
                href='#patterns'
                className={`nav-link ${
                  activeTab === 'patterns' ? 'active' : ''
                }`}
                onClick={() => handleTabClick('patterns')}
              >
                Patterns
              </a>
            </li>
          </ul>
          <div className='tab-content '>
            {activeTab === 'hands' && (
              <div id='hands' className='tab-pane active hands'>
                <div className='card-content m-t-0'>
                  <div className='table-responsive m-b-10'>
                    <table className='table table-bordered '>
                      <tbody>
                        <tr>
                          <td>
                            <button
                              className={`bt-action btn-theme ${
                                isLocked ? 'suspended' : ''
                              }`}
                              onClick={() => setEvent('Player 1')}
                            >
                              <span className='patern-name'>
                                Player 1
                                <span className='card-icon m-l-20'>
                                  <span className='card-red'>
                                    {odds && odds.cards && (
                                      <Box
                                        top='8'
                                        p='2'
                                        className='player-card'
                                        display='flex'
                                      >
                                        <img
                                          style={{
                                            marginRight: '5px',
                                          }}
                                          src={require(`../../../assets/img/casino/${odds?.cards[0][0]}.png`)}
                                        />
                                      </Box>
                                    )}
                                  </span>
                                </span>{' '}
                                <span className='card-icon m-l-5'>
                                  <span className='card-red'>
                                    {odds && odds.cards && (
                                      <Box
                                        top='8'
                                        p='2'
                                        className='player-card'
                                        display='flex'
                                      >
                                        <img
                                          style={{
                                            marginRight: '5px',
                                          }}
                                          src={require(`../../../assets/img/casino/${odds?.cards[0][1]}.png`)}
                                        />
                                      </Box>
                                    )}
                                  </span>
                                </span>
                              </span>{' '}
                              <span className='point'>
                                {getData('Player 1')?.back1}
                              </span>
                            </button>
                            <span className='ubook float-left'>
                              {getExposureForEntity('Player 1')?.exposure}
                            </span>
                            <p className='m-b-0 m-t-5 text-right min-max'>
                              <span>
                                <b>Min:</b>100
                              </span>{' '}
                              <span className='m-l-5'>
                                <b>Max:</b>100000
                              </span>
                            </p>
                          </td>
                          <td>
                            <button
                              className={`bt-action btn-theme ${
                                isLocked ? 'suspended' : ''
                              }`}
                              onClick={() => setEvent('Player 2')}
                            >
                              <span className='patern-name'>
                                Player 2
                                <span className='card-icon m-l-20'>
                                  <span className='card-black'>
                                    {' '}
                                    {odds && odds.cards && (
                                      <Box
                                        top='8'
                                        p='2'
                                        className='player-card'
                                        display='flex'
                                      >
                                        <img
                                          style={{
                                            marginRight: '5px',
                                          }}
                                          src={require(`../../../assets/img/casino/${odds?.cards[1][0]}.png`)}
                                        />
                                      </Box>
                                    )}
                                  </span>
                                </span>{' '}
                                <span className='card-icon m-l-5'>
                                  <span className='card-red'>
                                    {' '}
                                    {odds && odds.cards && (
                                      <Box
                                        top='8'
                                        p='2'
                                        className='player-card'
                                        display='flex'
                                      >
                                        <img
                                          style={{
                                            marginRight: '5px',
                                          }}
                                          src={require(`../../../assets/img/casino/${odds?.cards[1][1]}.png`)}
                                        />
                                      </Box>
                                    )}
                                  </span>
                                </span>
                              </span>{' '}
                              <span className='point'>
                                {getData('Player 2')?.back1}
                              </span>
                            </button>
                            <span className='ubook float-left'>
                              {getExposureForEntity('Player 2')?.exposure}
                            </span>
                            <p className='m-b-0 m-t-5 text-right min-max'>
                              <span>
                                <b>Min:</b>100
                              </span>{' '}
                              <span className='m-l-5'>
                                <b>Max:</b>100000
                              </span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <button
                              className={`bt-action btn-theme ${
                                isLocked ? 'suspended' : ''
                              }`}
                              onClick={() => setEvent('Player 3')}
                            >
                              <span className='patern-name'>
                                Player 3
                                <span className='card-icon m-l-20'>
                                  <span className='card-red'>
                                    {' '}
                                    {odds && odds.cards && (
                                      <Box
                                        top='8'
                                        p='2'
                                        className='player-card'
                                        display='flex'
                                      >
                                        <img
                                          style={{
                                            marginRight: '5px',
                                          }}
                                          src={require(`../../../assets/img/casino/${odds?.cards[2][0]}.png`)}
                                        />
                                      </Box>
                                    )}
                                  </span>
                                </span>{' '}
                                <span className='card-icon m-l-5'>
                                  <span className='card-black'>
                                    {' '}
                                    {odds && odds.cards && (
                                      <Box
                                        top='8'
                                        p='2'
                                        className='player-card'
                                        display='flex'
                                      >
                                        <img
                                          style={{
                                            marginRight: '5px',
                                          }}
                                          src={require(`../../../assets/img/casino/${odds?.cards[2][1]}.png`)}
                                        />
                                      </Box>
                                    )}
                                  </span>
                                </span>
                              </span>{' '}
                              <span className='point'>
                                {getData('Player 3')?.back1}
                              </span>
                            </button>
                            <span className='ubook float-left'>
                              {getExposureForEntity('Player 3')?.exposure}
                            </span>
                            <p className='m-b-0 m-t-5 text-right min-max'>
                              <span>
                                <b>Min:</b>100
                              </span>{' '}
                              <span className='m-l-5'>
                                <b>Max:</b>100000
                              </span>
                            </p>
                          </td>
                          <td>
                            <button
                              className={`bt-action btn-theme ${
                                isLocked ? 'suspended' : ''
                              }`}
                              onClick={() => setEvent('Player 4')}
                            >
                              <span className='patern-name'>
                                Player 4
                                <span className='card-icon m-l-20'>
                                  <span className='card-red'>
                                    {' '}
                                    {odds && odds.cards && (
                                      <Box
                                        top='8'
                                        p='2'
                                        className='player-card'
                                        display='flex'
                                      >
                                        <img
                                          style={{
                                            marginRight: '5px',
                                          }}
                                          src={require(`../../../assets/img/casino/${odds?.cards[3][0]}.png`)}
                                        />
                                      </Box>
                                    )}
                                  </span>
                                </span>{' '}
                                <span className='card-icon m-l-5'>
                                  <span className='card-black'>
                                    {' '}
                                    {odds && odds.cards && (
                                      <Box
                                        top='8'
                                        p='2'
                                        className='player-card'
                                        display='flex'
                                      >
                                        <img
                                          style={{
                                            marginRight: '5px',
                                          }}
                                          src={require(`../../../assets/img/casino/${odds?.cards[3][1]}.png`)}
                                        />
                                      </Box>
                                    )}
                                  </span>
                                </span>
                              </span>{' '}
                              <span className='point'>
                                {getData('Player 4')?.back1}
                              </span>
                            </button>
                            <span className='ubook float-left'>
                              {getExposureForEntity('Player 4')?.exposure}
                            </span>
                            <p className='m-b-0 m-t-5 text-right min-max'>
                              <span>
                                <b>Min:</b>100
                              </span>{' '}
                              <span className='m-l-5'>
                                <b>Max:</b>100000
                              </span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <button
                              className={`bt-action btn-theme ${
                                isLocked ? 'suspended' : ''
                              }`}
                              onClick={() => setEvent('Player 5')}
                            >
                              <span className='patern-name'>
                                Player 5
                                <span className='card-icon m-l-20'>
                                  <span className='card-red'>
                                    {' '}
                                    {odds && odds.cards && (
                                      <Box
                                        top='8'
                                        p='2'
                                        className='player-card'
                                        display='flex'
                                      >
                                        <img
                                          style={{
                                            marginRight: '5px',
                                          }}
                                          src={require(`../../../assets/img/casino/${odds?.cards[4][0]}.png`)}
                                        />
                                      </Box>
                                    )}
                                  </span>
                                </span>{' '}
                                <span className='card-icon m-l-5'>
                                  <span className='card-black'>
                                    {' '}
                                    {odds && odds.cards && (
                                      <Box
                                        top='8'
                                        p='2'
                                        className='player-card'
                                        display='flex'
                                      >
                                        <img
                                          style={{
                                            marginRight: '5px',
                                          }}
                                          src={require(`../../../assets/img/casino/${odds?.cards[4][1]}.png`)}
                                        />
                                      </Box>
                                    )}
                                  </span>
                                </span>
                              </span>{' '}
                              <span className='point'>
                                {getData('Player 5')?.back1}
                              </span>
                            </button>
                            <span className='ubook float-left'>
                              {getExposureForEntity('Player 5')?.exposure}
                            </span>
                            <p className='m-b-0 m-t-5 text-right min-max'>
                              <span>
                                <b>Min:</b>100
                              </span>{' '}
                              <span className='m-l-5'>
                                <b>Max:</b>100000
                              </span>
                            </p>
                          </td>
                          <td>
                            <button
                              className={`bt-action btn-theme ${
                                isLocked ? 'suspended' : ''
                              }`}
                              onClick={() => setEvent('Player 6')}
                            >
                              <span className='patern-name'>
                                Player 6
                                <span className='card-icon m-l-20'>
                                  <span className='card-black'>
                                    {' '}
                                    {odds && odds.cards && (
                                      <Box
                                        top='8'
                                        p='2'
                                        className='player-card'
                                        display='flex'
                                      >
                                        <img
                                          style={{
                                            marginRight: '5px',
                                          }}
                                          src={require(`../../../assets/img/casino/${odds?.cards[5][0]}.png`)}
                                        />
                                      </Box>
                                    )}
                                  </span>
                                </span>{' '}
                                <span className='card-icon m-l-5'>
                                  <span className='card-red'>
                                    {' '}
                                    {odds && odds.cards && (
                                      <Box
                                        top='8'
                                        p='2'
                                        className='player-card'
                                        display='flex'
                                      >
                                        <img
                                          style={{
                                            marginRight: '5px',
                                          }}
                                          src={require(`../../../assets/img/casino/${odds?.cards[5][1]}.png`)}
                                        />
                                      </Box>
                                    )}
                                  </span>
                                </span>
                              </span>{' '}
                              <span className='point'>
                                {getData('Player 6')?.back1}
                              </span>
                            </button>
                            <span className='ubook float-left'>
                              {getExposureForEntity('Player 6')?.exposure}
                            </span>
                            <p className='m-b-0 m-t-5 text-right min-max'>
                              <span>
                                <b>Min:</b>100
                              </span>{' '}
                              <span className='m-l-5'>
                                <b>Max:</b>100000
                              </span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {activeTab === 'patterns' && (
              <div id='patterns' className='tab-pane active patterns'>
                <div className='card-content m-t-0'>
                  <div className='table-responsive m-b-10'>
                    <table className='table table-bordered '>
                      <tbody>
                        <tr>
                          <td>
                            <button
                              className={`bt-action btn-theme ${
                                isLocked ? 'suspended' : ''
                              }`}
                              onClick={() => setEvent('High Card')}
                            >
                              <span className='patern-name'>High Card</span>{' '}
                              <span className='point'>
                                {getData('High Card')?.back1}
                              </span>
                            </button>{' '}
                            <span className='ubook float-left'>
                              {getExposureForEntity('High Card')?.exposure}
                            </span>
                            <p className='m-b-0 m-t-5 text-right min-max'>
                              <span>
                                <b>Min:</b>100
                              </span>{' '}
                              <span className='m-l-5'>
                                <b>Max:</b>100000
                              </span>
                            </p>
                          </td>
                          <td>
                            <button
                              className={`bt-action btn-theme ${
                                isLocked ? 'suspended' : ''
                              }`}
                              onClick={() => setEvent('Pair')}
                            >
                              <span className='patern-name'>Pair</span>{' '}
                              <span className='point'>
                                {getData('Pair')?.back1}
                              </span>
                            </button>{' '}
                            <span className='ubook float-left'>
                              {getExposureForEntity('Pair')?.exposure}
                            </span>
                            <p className='m-b-0 m-t-5 text-right min-max'>
                              <span>
                                <b>Min:</b>100
                              </span>{' '}
                              <span className='m-l-5'>
                                <b>Max:</b>100000
                              </span>
                            </p>
                          </td>
                          <td>
                            <button
                              className={`bt-action btn-theme ${
                                isLocked ? 'suspended' : ''
                              }`}
                              onClick={() => setEvent('Two Pair')}
                            >
                              <span className='patern-name'>Two Pair</span>{' '}
                              <span className='point'>
                                {getData('Two Pair')?.back1}
                              </span>
                            </button>{' '}
                            <span className='ubook float-left'>
                              {getExposureForEntity('Two Pair')?.exposure}
                            </span>
                            <p className='m-b-0 m-t-5 text-right min-max'>
                              <span>
                                <b>Min:</b>100
                              </span>{' '}
                              <span className='m-l-5'>
                                <b>Max:</b>100000
                              </span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <button
                              className={`bt-action btn-theme ${
                                isLocked ? 'suspended' : ''
                              }`}
                              onClick={() => setEvent('Three of a Kind')}
                            >
                              <span className='patern-name'>
                                Three of a Kind
                              </span>{' '}
                              <span className='point'>
                                {getData('Three of a Kind')?.back1}
                              </span>
                            </button>{' '}
                            <span className='ubook float-left'>
                              {
                                getExposureForEntity('Three of a Kind')
                                  ?.exposure
                              }
                            </span>
                            <p className='m-b-0 m-t-5 text-right min-max'>
                              <span>
                                <b>Min:</b>100
                              </span>{' '}
                              <span className='m-l-5'>
                                <b>Max:</b>100000
                              </span>
                            </p>
                          </td>
                          <td>
                            <button
                              className={`bt-action btn-theme ${
                                isLocked ? 'suspended' : ''
                              }`}
                              onClick={() => setEvent('Straight')}
                            >
                              <span className='patern-name'>Straight</span>{' '}
                              <span className='point'>
                                {getData('Straight')?.back1}
                              </span>
                            </button>{' '}
                            <span className='ubook float-left'>
                              {getExposureForEntity('Straight')?.exposure}
                            </span>
                            <p className='m-b-0 m-t-5 text-right min-max'>
                              <span>
                                <b>Min:</b>100
                              </span>{' '}
                              <span className='m-l-5'>
                                <b>Max:</b>100000
                              </span>
                            </p>
                          </td>
                          <td>
                            <button
                              className={`bt-action btn-theme ${
                                isLocked ? 'suspended' : ''
                              }`}
                              onClick={() => setEvent('Flush')}
                            >
                              <span className='patern-name'>Flush</span>{' '}
                              <span className='point'>
                                {getData('Flush')?.back1}
                              </span>
                            </button>{' '}
                            <span className='ubook float-left'>
                              {getExposureForEntity('Flush')?.exposure}
                            </span>
                            <p className='m-b-0 m-t-5 text-right min-max'>
                              <span>
                                <b>Min:</b>100
                              </span>{' '}
                              <span className='m-l-5'>
                                <b>Max:</b>100000
                              </span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <button
                              className={`bt-action btn-theme ${
                                isLocked ? 'suspended' : ''
                              }`}
                              onClick={() => setEvent('Full House')}
                            >
                              <span className='patern-name'>Full House</span>{' '}
                              <span className='point'>
                                {getData('Full House')?.back1}
                              </span>
                            </button>{' '}
                            <span className='ubook float-left'>
                              {getExposureForEntity('Full House')?.exposure}
                            </span>
                            <p className='m-b-0 m-t-5 text-right min-max'>
                              <span>
                                <b>Min:</b>100
                              </span>{' '}
                              <span className='m-l-5'>
                                <b>Max:</b>100000
                              </span>
                            </p>
                          </td>
                          <td>
                            <button
                              className={`bt-action btn-theme ${
                                isLocked ? 'suspended' : ''
                              }`}
                              onClick={() => setEvent('Four of a Kind')}
                            >
                              <span className='patern-name'>
                                Four of a Kind
                              </span>{' '}
                              <span className='point'>
                                {getData('Four of a Kind')?.back1}
                              </span>
                            </button>{' '}
                            <span className='ubook float-left'>
                              {getExposureForEntity('Four of a Kind')?.exposure}
                            </span>
                            <p className='m-b-0 m-t-5 text-right min-max'>
                              <span>
                                <b>Min:</b>100
                              </span>{' '}
                              <span className='m-l-5'>
                                <b>Max:</b>100000
                              </span>
                            </p>
                          </td>
                          <td>
                            <button
                              className={`bt-action btn-theme ${
                                isLocked ? 'suspended' : ''
                              }`}
                              onClick={() => setEvent('Straight Flush')}
                            >
                              <span className='patern-name'>
                                Straight Flush
                              </span>{' '}
                              <span className='point'>
                                {getData('Straight Flush')?.back1}
                              </span>
                            </button>{' '}
                            <span className='ubook float-left'>
                              {getExposureForEntity('Straight Flush')?.exposure}
                            </span>
                            <p className='m-b-0 m-t-5 text-right min-max'>
                              <span>
                                <b>Min:</b>100
                              </span>{' '}
                              <span className='m-l-5'>
                                <b>Max:</b>100000
                              </span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <Grid display={{ base: "block", md: "none" }} bg="#fff">
          <Grid padding="10px 10px 10px 10px">
            <Text textColor="#fdcf13" paddingBottom="10px">
              Hands
            </Text>
            <Grid templateColumns="repeat(3,2fr)" gap={2}>
              <Grid>
                <Text>Player 1</Text>
                <Grid
                  h="50px"
                  onClick={() => setEvent("Player 1")}
                  className={`poker6-box ${isLocked ? "suspended" : ""}`}
                >
                  {odds?.cards && (
                    <Grid alignContent="start">
                      <Box className="player-card" p="2" display="flex">
                        <img
                          style={{
                            marginRight: "5px",
                          }}
                          src={ASSETS_URL + odds?.cards[0][0] + ".png"}
                        />
                        <img
                          style={{
                            marginRight: "5px",
                          }}
                          src={ASSETS_URL + odds?.cards[0][1] + `.png`}
                        />
                      </Box>
                    </Grid>
                  )}
                  <Text className="poker6-text-right">
                    {getData("Player 1")?.back1}
                  </Text>
                  {getExposureForEntity("Player 1")?.exposure}
                </Grid>
              </Grid>

              <Grid>
                <Text>Player 2</Text>
                <Grid
                  h="50px"
                  onClick={() => setEvent("Player 2")}
                  className={`poker6-box  ${isLocked ? "suspended" : ""}`}
                >
                  <Grid alignContent="start">
                    {odds && odds.cards && (
                      <Box top="8" p="2" className="player-card" display="flex">
                        <img
                          style={{
                            marginRight: "5px",
                          }}
                          src={ASSETS_URL + odds?.cards[1][0] + ".png"}
                        />
                        <img
                          style={{
                            marginRight: "5px",
                          }}
                          src={ASSETS_URL + odds?.cards[1][1] + ".png"}
                        />
                      </Box>
                    )}
                  </Grid>
                  <Text className="poker6-text-right">
                    {getData("Player 2")?.back1}
                  </Text>
                  {getExposureForEntity("Player 2")?.exposure}
                </Grid>
              </Grid>

              <Grid>
                <Text>Player 3</Text>
                <Grid
                  h="50px"
                  onClick={() => setEvent("Player 3")}
                  className={`poker6-box  ${isLocked ? "suspended" : ""}`}
                >
                  <Grid alignContent="start">
                    {odds && odds.cards && (
                      <Box top="8" p="2" className="player-card" display="flex">
                        <img
                          style={{
                            marginRight: "5px",
                          }}
                          src={ASSETS_URL + odds?.cards[2][0] + ".png"}
                        />
                        <img
                          style={{
                            marginRight: "5px",
                          }}
                          src={ASSETS_URL + odds?.cards[2][1] + ".png"}
                        />
                      </Box>
                    )}
                  </Grid>
                  <Text className="poker6-text-right">
                    {getData("Player 3")?.back1}
                  </Text>
                  {getExposureForEntity("Player 3")?.exposure}
                </Grid>
              </Grid>

              <Grid>
                <Text>Player 4</Text>
                <Grid
                  h="50px"
                  onClick={() => setEvent("Player 4")}
                  className={`poker6-box  ${isLocked ? "suspended" : ""}`}
                >
                  <Grid alignContent="start">
                    {odds && odds.cards && (
                      <Box p="2" display="flex" className="player-card">
                        <img
                          style={{
                            marginRight: "5px",
                          }}
                          src={ASSETS_URL + odds?.cards[3][0] + ".png"}
                        />
                        <img
                          style={{
                            marginRight: "5px",
                          }}
                          src={ASSETS_URL + odds?.cards[3][1] + ".png"}
                        />
                      </Box>
                    )}
                  </Grid>
                  <Text className="poker6-text-right">
                    {getData("Player 4")?.back1}
                  </Text>
                  {getExposureForEntity("Player 4")?.exposure}
                </Grid>
              </Grid>

              <Grid>
                <Text>Player 5</Text>
                <Grid
                  h="50px"
                  onClick={() => setEvent("Player 5")}
                  className={`poker6-box  ${isLocked ? "suspended" : ""}`}
                >
                  <Grid alignContent="start">
                    {odds && odds.cards && (
                      <Box p="2" display="flex" className="player-card">
                        <img
                          style={{
                            marginRight: "5px",
                          }}
                          src={ASSETS_URL + odds?.cards[4][0] + ".png"}
                        />
                        <img
                          style={{
                            marginRight: "5px",
                          }}
                          src={ASSETS_URL + odds?.cards[4][1] + ".png"}
                        />
                      </Box>
                    )}
                  </Grid>
                  <Text className="poker6-text-right">
                    {getData("Player 5")?.back1}
                  </Text>
                  {getExposureForEntity("Player 5")?.exposure}
                </Grid>
              </Grid>

              <Grid>
                <Text>Player 6</Text>
                <Grid
                  h="50px"
                  onClick={() => setEvent("Player 6")}
                  className={`poker6-box  ${isLocked ? "suspended" : ""}`}
                >
                  <Grid alignContent="start">
                    {odds && odds.cards && (
                      <Box p="2" display="flex" className="player-card">
                        <img
                          style={{
                            marginRight: "5px",
                          }}
                          src={ASSETS_URL + odds?.cards[5][0] + ".png"}
                        />
                        <img
                          style={{
                            marginRight: "5px",
                          }}
                          src={ASSETS_URL + odds?.cards[5][1] + ".png"}
                        />
                      </Box>
                    )}
                  </Grid>
                  <Text className="poker6-text-right">
                    {getData("Player 6")?.back1}
                  </Text>
                  {getExposureForEntity("Player 6")?.exposure}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            display={{
              base: "block",
              md: "none",
            }}
            marginTop="20px"
            padding="10px 10px 10px 10px"
          >
            <Text
              textDecoration="underline"
              textColor="#fdcf13"
              paddingBottom="10px"
            >
              Pattern
            </Text>

            <Grid templateColumns="repeat(3,3fr)" gap={2}>
              <Grid
                onClick={() => setEvent("High Card")}
                className={`poker6-box  ${isLocked ? "suspended" : ""}`}
              >
                <Text className="poker6-text-left">High Card</Text>
                <Text className="poker6-text-right">
                  {getData("High Card")?.back1}
                </Text>
                {getExposureForEntity("High Card")?.exposure}
              </Grid>

              <Grid
                onClick={() => setEvent("Pair")}
                className={`poker6-box ${isLocked ? "suspended" : ""}`}
              >
                <Text className="poker6-text-left">Pair</Text>
                <Text className="poker6-text-right">
                  {getData("Pair")?.back1}
                </Text>
                {getExposureForEntity("Pair")?.exposure}
              </Grid>

              <Grid
                onClick={() => setEvent("Two Pair")}
                className={`poker6-box  ${isLocked ? "suspended" : ""}`}
              >
                <Text className="poker6-text-left">Two Pair</Text>
                <Text className="poker6-text-right">
                  {getData("Two Pair")?.back1}
                </Text>
                {getExposureForEntity("Two Pair")?.exposure}
              </Grid>

              <Grid
                onClick={() => setEvent("Three of a Kind")}
                className={`poker6-box  ${isLocked ? "suspended" : ""}`}
              >
                <Text className="poker6-text-left">Three of a Kind</Text>
                <Text className="poker6-text-right">
                  {getData("Three of a Kind")?.back1}
                </Text>
                {getExposureForEntity("Three of a Kind")?.exposure}
              </Grid>

              <Grid
                onClick={() => setEvent("Straight")}
                className={`poker6-box  ${isLocked ? "suspended" : ""}`}
              >
                <Text className="poker6-text-left">Straight</Text>
                <Text className="poker6-text-right">
                  {getData("Straight")?.back1}
                </Text>
                {getExposureForEntity("Straight")?.exposure}
              </Grid>

              <Grid
                onClick={() => setEvent("Flush")}
                className={`poker6-box  ${isLocked ? "suspended" : ""}`}
              >
                <Text className="poker6-text-left">Flush</Text>
                <Text className="poker6-text-right">
                  {getData("Flush")?.back1}
                </Text>
                {getExposureForEntity("Flush")?.exposure}
              </Grid>

              <Grid
                onClick={() => setEvent("Full House")}
                className={`poker6-box  ${isLocked ? "suspended" : ""}`}
              >
                <Text className="poker6-text-left">Full House</Text>
                <Text className="poker6-text-right">
                  {getData("Full House")?.back1}
                </Text>
                {getExposureForEntity("Full House")?.exposure}
              </Grid>

              <Grid
                onClick={() => setEvent("Four of a Kind")}
                className={`poker6-box  ${isLocked ? "suspended" : ""}`}
              >
                <Text className="poker6-text-left">Four of a Kind</Text>
                <Text className="poker6-text-right">
                  {getData("Four of a Kind")?.back1}
                </Text>
                {getExposureForEntity("Four of a Kind")?.exposure}
              </Grid>

              <Grid
                onClick={() => setEvent("Straight Flush")}
                className={`poker6-box  ${isLocked ? "suspended" : ""}`}
              >
                <Text className="poker6-text-left">Straight Flush</Text>
                <Text className="poker6-text-right">
                  {getData("Straight Flush")?.back1}
                </Text>
                {getExposureForEntity("Straight Flush")?.exposure}
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
        <LastResults eventId={gameName} />
      </Box>
    </Box>
  );
}
